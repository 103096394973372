import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ArrowRight, ArrowLeft, RotateCcw, Info, X, Shuffle, Download, Eye, EyeOff } from 'lucide-react';

// Europeana API configuration
const EUROPEANA_API_KEY = "etholderr"; // Replace with your real API key in production

// ------------------- API Service -------------------
const EuropeanaService = {
  fetchArtworks: async (page = 1) => {
    try {
      // Real Europeana API call
      const url = `https://api.europeana.eu/record/v2/search.json?wskey=${EUROPEANA_API_KEY}&query=painting&media=true&qf=TYPE:IMAGE&reusability=open&rows=10&start=${(page-1)*10+1}`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Europeana API error: ${response.status}`);
      }

      const data = await response.json();

      if (!data.items || data.items.length === 0) {
        throw new Error('No items found in Europeana API response');
      }

      // Transform Europeana data to our format
      return data.items.map(item => transformEuropeanaItem(item));
    } catch (error) {
      console.error("Error fetching from Europeana API:", error);
      // Fallback to sample data only if API request fails
      return SAMPLE_ARTWORKS;
    }
  }
};

// Transform Europeana API response to our app's format
const transformEuropeanaItem = (item) => {
  // Extract the image URL
  const imageUrl = item.edmPreview ? item.edmPreview[0] : "/api/placeholder/600/450";

  // Extract creator (artist)
  const artist = item.dcCreator ? item.dcCreator[0] : (item.dcCreator ? item.dcCreator.join(', ') : 'Unknown Artist');

  // Extract year
  let year = 'Unknown';
  if (item.year) {
    year = item.year;
  } else if (item.edmTimespanLabel) {
    year = item.edmTimespanLabel[0]?.def || 'Unknown';
  } else if (item.dcDate) {
    year = item.dcDate[0] || 'Unknown';
  }

  // Extract title
  const title = item.title ? item.title[0] : (item.dcTitle ? item.dcTitle[0] : 'Untitled Artwork');

  // Extract museum/provider info
  const museumName = item.dataProvider ? item.dataProvider[0] : 'Unknown Museum';
  const museumDetails = item.dcDescription ? item.dcDescription[0] : 'No additional information available.';

  // For now, use a default palette (we'll extract real colors asynchronously later)
  const defaultColors = [
    "#1A237E", "#3F51B5", "#FFEB3B",
    "#FFF59D", "#5D4037"
  ];

  return {
    id: item.id || `artwork-${Math.random().toString(36).substr(2, 9)}`,
    title: title,
    artist: artist,
    museum: {
      name: museumName,
      details: museumDetails
    },
    image_url: imageUrl,
    colors: defaultColors, // We'll update this with real extracted colors
    year: year
  };
};

// Extract colors from an image using k-means clustering
const extractColorsFromImage = async (imageUrl) => {
  // Create a promise that resolves with the extracted colors
  return new Promise((resolve) => {
    // Default palette in case extraction fails (reduced to 5 colors)
    const defaultPalette = [
      "#1A237E", "#3F51B5", "#FFEB3B",
      "#FFF59D", "#5D4037"
    ];

    try {
      // Create a new image object
      const img = new Image();
      // Set crossOrigin to anonymous to avoid CORS issues with external images
      img.crossOrigin = "Anonymous";

      // Handle errors by returning the default palette
      img.onerror = () => {
        console.error("Error loading image for color extraction");
        resolve(defaultPalette);
      };

      // When the image loads, extract colors
      img.onload = () => {
        try {
          // Create a canvas to draw the image
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          // Set canvas dimensions (smaller size for performance)
          const maxSize = 100;
          const scaleFactor = Math.min(maxSize / img.width, maxSize / img.height);
          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;

          // Draw image to canvas
          context.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Get image data
          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const pixels = imageData.data;

          // Collect pixel data for k-means clustering
          const pixelArray = [];
          const totalPixels = pixels.length / 4;
          const sampleSize = Math.max(1, Math.floor(totalPixels / 3000)); // Adjust sample rate for performance

          for (let i = 0; i < totalPixels; i += sampleSize) {
            const offset = i * 4;
            const r = pixels[offset];
            const g = pixels[offset + 1];
            const b = pixels[offset + 2];
            const a = pixels[offset + 3];

            // Skip transparent or near-transparent pixels
            if (a < 128) continue;

            // Skip near-white and near-black pixels (often backgrounds or borders)
            const isNearWhite = r > 240 && g > 240 && b > 240;
            const isNearBlack = r < 15 && g < 15 && b < 15;
            if (isNearWhite || isNearBlack) continue;

            // Add pixel to array for clustering
            pixelArray.push([r, g, b]);
          }

          // If not enough pixels, return default
          if (pixelArray.length < 10) {
            console.warn("Not enough valid pixels for clustering");
            resolve(defaultPalette);
            return;
          }

          // Implement k-means clustering algorithm
          const k = 5; // Number of clusters (colors) we want

          // Function to calculate Euclidean distance in RGB space
          const distance = (a, b) => {
            return Math.sqrt(
              Math.pow(a[0] - b[0], 2) +
              Math.pow(a[1] - b[1], 2) +
              Math.pow(a[2] - b[2], 2)
            );
          };

          // Function to calculate perceptual distance between colors
          const perceptualDistance = (a, b) => {
            // Weighted RGB distance (human eyes are more sensitive to green)
            return Math.sqrt(
              (2 + (a[0]/256)) * Math.pow(a[0] - b[0], 2) +
              (4 + (a[1]/256)) * Math.pow(a[1] - b[1], 2) +
              (2 + (a[2]/256)) * Math.pow(a[2] - b[2], 2)
            );
          };

          // Initialize centroids randomly from the pixel data
          let centroids = [];
          // Choose initial centroids far apart from each other for better results
          const initialCentroidIndices = [];

          // First centroid is random
          const firstIndex = Math.floor(Math.random() * pixelArray.length);
          initialCentroidIndices.push(firstIndex);
          centroids.push(pixelArray[firstIndex]);

          // Choose remaining centroids with maximal distance from existing ones (k-means++)
          for (let i = 1; i < k; i++) {
            let maxDistance = -1;
            let farthestIndex = -1;

            for (let j = 0; j < pixelArray.length; j++) {
              if (initialCentroidIndices.includes(j)) continue;

              // Calculate minimum distance to any existing centroid
              let minDist = Infinity;
              for (const centroid of centroids) {
                const dist = perceptualDistance(pixelArray[j], centroid);
                minDist = Math.min(minDist, dist);
              }

              if (minDist > maxDistance) {
                maxDistance = minDist;
                farthestIndex = j;
              }
            }

            initialCentroidIndices.push(farthestIndex);
            centroids.push(pixelArray[farthestIndex]);
          }

          // Run k-means algorithm
          const MAX_ITERATIONS = 10;
          for (let iteration = 0; iteration < MAX_ITERATIONS; iteration++) {
            // Assign each pixel to nearest centroid
            const clusters = Array(k).fill().map(() => []);

            for (let i = 0; i < pixelArray.length; i++) {
              let minDistance = Infinity;
              let closestCentroid = 0;

              for (let j = 0; j < k; j++) {
                const dist = distance(pixelArray[i], centroids[j]);
                if (dist < minDistance) {
                  minDistance = dist;
                  closestCentroid = j;
                }
              }

              clusters[closestCentroid].push(pixelArray[i]);
            }

            // Update centroids
            const newCentroids = [];
            for (let i = 0; i < k; i++) {
              // If cluster is empty, keep old centroid
              if (clusters[i].length === 0) {
                newCentroids.push(centroids[i]);
                continue;
              }

              // Calculate mean of cluster
              const clusterSize = clusters[i].length;
              const sums = [0, 0, 0];

              for (const pixel of clusters[i]) {
                sums[0] += pixel[0];
                sums[1] += pixel[1];
                sums[2] += pixel[2];
              }

              newCentroids.push([
                Math.round(sums[0] / clusterSize),
                Math.round(sums[1] / clusterSize),
                Math.round(sums[2] / clusterSize)
              ]);
            }

            // Check for convergence
            let centroidsChanged = false;
            for (let i = 0; i < k; i++) {
              if (distance(centroids[i], newCentroids[i]) > 1) {
                centroidsChanged = true;
                break;
              }
            }

            centroids = newCentroids;

            if (!centroidsChanged) {
              break; // Centroids have stabilized
            }
          }

          // Convert centroids to hex colors and sort by cluster size
          const results = centroids.map((centroid, i) => {
            const hex = `#${((1 << 24) + (centroid[0] << 16) + (centroid[1] << 8) + centroid[2]).toString(16).slice(1).toUpperCase()}`;
            return hex;
          });

          // Ensure we have 5 distinct colors
          const finalPalette = [];

          // Add colors to final palette, ensuring they're visually different
          for (const color of results) {
            let isDuplicate = false;
            for (const existingColor of finalPalette) {
              // Convert hex to RGB for comparison
              const r1 = parseInt(color.slice(1, 3), 16);
              const g1 = parseInt(color.slice(3, 5), 16);
              const b1 = parseInt(color.slice(5, 7), 16);

              const r2 = parseInt(existingColor.slice(1, 3), 16);
              const g2 = parseInt(existingColor.slice(3, 5), 16);
              const b2 = parseInt(existingColor.slice(5, 7), 16);

              // Calculate perceptual distance
              const colorDist = Math.sqrt(
                (2 + (r1/256)) * Math.pow(r1 - r2, 2) +
                (4 + (g1/256)) * Math.pow(g1 - g2, 2) +
                (2 + (b1/256)) * Math.pow(b1 - b2, 2)
              );

              // If too similar, mark as duplicate
              if (colorDist < 60) {
                isDuplicate = true;
                break;
              }
            }

            if (!isDuplicate) {
              finalPalette.push(color);
              if (finalPalette.length >= 5) break;
            }
          }

          // If we don't have 5 colors yet (unlikely with k-means), generate more
          while (finalPalette.length < 5) {
            const randomColor = `#${Math.floor(Math.random()*16777215).toString(16).padStart(6, '0')}`;
            finalPalette.push(randomColor);
          }

          resolve(finalPalette);
        } catch (e) {
          console.error("Error during color extraction:", e);
          resolve(defaultPalette);
        }
      };

      // Set the source to trigger loading
      img.src = imageUrl;
    } catch (e) {
      console.error("Error setting up color extraction:", e);
      resolve(defaultPalette);
    }
  });
};

// Sample artworks as fallback if API fails
const SAMPLE_ARTWORKS = [
  {
    id: "artwork-1",
    title: "The Starry Night",
    artist: "Vincent van Gogh",
    museum: {
      name: "Museum of Modern Art",
      details: "Painted in June 1889, it depicts the view from the east-facing window of his asylum room at Saint-Rémy-de-Provence."
    },
    image_url: "/api/placeholder/600/450",
    colors: ["#1A237E", "#303F9F", "#3F51B5", "#7986CB", "#C5CAE9", "#FFEB3B", "#FFF176", "#FFF59D", "#FFFDE7", "#5D4037"],
    year: "1889"
  },
  {
    id: "artwork-2",
    title: "Girl with a Pearl Earring",
    artist: "Johannes Vermeer",
    museum: {
      name: "Mauritshuis",
      details: "Created in 1665, this Dutch Golden Age painting is one of Vermeer's most famous works."
    },
    image_url: "/api/placeholder/600/450",
    colors: ["#263238", "#455A64", "#607D8B", "#90A4AE", "#CFD8DC", "#FFECB3", "#FFE082", "#FFD54F", "#FFCA28", "#FFC107"],
    year: "1665"
  },
  {
    id: "artwork-3",
    title: "The Night Watch",
    artist: "Rembrandt van Rijn",
    museum: {
      name: "Rijksmuseum",
      details: "Completed in 1642, this masterpiece is renowned for its effective use of light and shadow."
    },
    image_url: "/api/placeholder/600/450",
    colors: ["#3E2723", "#5D4037", "#795548", "#A1887F", "#D7CCC8", "#FFF8E1", "#FFECB3", "#FFE082", "#FFD54F", "#FFCA28"],
    year: "1642"
  }
];

// ------------------- Onboarding Steps -------------------
const onboardingSteps = [
  {
    title: "Welcome to Project Palette",
    content: "Discover the colors of famous artworks from Europeana and test your color perception.",
    image: "/api/placeholder/400/200"
  },
  {
    title: "Play the Color Guessing Game",
    content: "Can you identify the missing color from each artwork's palette?",
    image: "/api/placeholder/400/200"
  },
  {
    title: "Explore Art Details",
    content: "Flip the card to learn more about each artwork and its origin.",
    image: "/api/placeholder/400/200"
  },
  {
    title: "Save Your Favorite Palettes",
    content: "Download color palettes from your favorite artworks for inspiration.",
    image: "/api/placeholder/400/200"
  }
];

// Function to download palette as a CSV file
const downloadPalette = (colors, title) => {
  const colorData = colors.map(color => color).join(',');
  const csvContent = 'data:text/csv;charset=utf-8,' + colorData;

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${title.replace(/\s+/g, '-').toLowerCase()}-palette.csv`);
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};

// ------------------- Main Application -------------------
const ProjectPalette = () => {
  const [artworks, setArtworks] = useState([]);
  const [currentArtworkIndex, setCurrentArtworkIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [gameState, setGameState] = useState({
    missingColorIndex: null,
    options: [],
    selectedOption: null,
    isCorrect: null
  });
  const [showHex, setShowHex] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [currentOnboardingStep, setCurrentOnboardingStep] = useState(0);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasError, setHasError] = useState(false);

  const currentArtwork = artworks[currentArtworkIndex];
  const polaroidRef = useRef(null);

  // Handle window resize for responsive design
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  // Fetch artworks when component mounts
  useEffect(() => {
    const loadArtworks = async () => {
      setIsLoading(true);
      setHasError(false);

      try {
        // Fetch artworks from API
        const data = await EuropeanaService.fetchArtworks(currentPage);

        // Process each artwork to extract colors from its image
        const processedData = await Promise.all(
          data.map(async (artwork) => {
            try {
              // Extract actual colors from the artwork image
              const extractedColors = await extractColorsFromImage(artwork.image_url);
              return {
                ...artwork,
                colors: extractedColors
              };
            } catch (error) {
              console.error(`Error extracting colors for ${artwork.title}:`, error);
              // Keep the default colors if extraction fails
              return artwork;
            }
          })
        );

        setArtworks(processedData);
        if (processedData.length > 0) {
          setupGame(processedData[0]);
        }
      } catch (error) {
        console.error("Error fetching artworks:", error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    loadArtworks();

    // Check if user has seen onboarding before
    const hasSeenOnboarding = localStorage.getItem('hasSeenOnboarding');
    if (hasSeenOnboarding) {
      setShowOnboarding(false);
    }
  }, [currentPage]);

  // Load more artworks
  const loadMoreArtworks = async () => {
    try {
      const nextPage = currentPage + 1;
      const newArtworks = await EuropeanaService.fetchArtworks(nextPage);

      // Process each new artwork to extract colors from its image
      const processedNewArtworks = await Promise.all(
        newArtworks.map(async (artwork) => {
          try {
            // Extract actual colors from the artwork image
            const extractedColors = await extractColorsFromImage(artwork.image_url);
            return {
              ...artwork,
              colors: extractedColors
            };
          } catch (error) {
            console.error(`Error extracting colors for ${artwork.title}:`, error);
            // Keep the default colors if extraction fails
            return artwork;
          }
        })
      );

      setArtworks(prev => [...prev, ...processedNewArtworks]);
      setCurrentPage(nextPage);
    } catch (error) {
      console.error("Error loading more artworks:", error);
    }
  };

  // Set up the game for a specific artwork
  const setupGame = useCallback((artwork) => {
    if (!artwork) return;

    // Pick a random color to omit
    const missingIndex = Math.floor(Math.random() * artwork.colors.length);

    // Create options including the correct answer and distractors
    const correctColor = artwork.colors[missingIndex];

    // Generate realistic distractor colors that are visually similar but distinct
    const generateDistractorColor = (baseColor) => {
      // Parse the hex color
      const r = parseInt(baseColor.slice(1, 3), 16);
      const g = parseInt(baseColor.slice(3, 5), 16);
      const b = parseInt(baseColor.slice(5, 7), 16);

      // Create a variation (ensuring it stays within 0-255 range)
      const variation = 25; // How much to vary the color
      const newR = Math.max(0, Math.min(255, r + (Math.random() * variation * 2 - variation)));
      const newG = Math.max(0, Math.min(255, g + (Math.random() * variation * 2 - variation)));
      const newB = Math.max(0, Math.min(255, b + (Math.random() * variation * 2 - variation)));

      // Convert back to hex
      return `#${Math.round(newR).toString(16).padStart(2, '0')}${Math.round(newG).toString(16).padStart(2, '0')}${Math.round(newB).toString(16).padStart(2, '0')}`;
    };

    // Create 3 distractors
    const distractors = Array(3).fill(null).map(() => generateDistractorColor(correctColor));

    // Combine and shuffle options
    const options = [correctColor, ...distractors].sort(() => Math.random() - 0.5);

    setGameState({
      missingColorIndex: missingIndex,
      options,
      correctColor,
      selectedOption: null,
      isCorrect: null
    });

    // Reset polaroid flip
    setIsFlipped(false);
  }, []);

  // Handle color selection
  const handleColorSelect = (selectedColor) => {
    const isCorrect = selectedColor === gameState.correctColor;

    setGameState(prev => ({
      ...prev,
      selectedOption: selectedColor,
      isCorrect
    }));
  };

  // Move to next/previous artwork
  const navigateArtwork = (direction) => {
    // Check if we need to load more artworks when approaching the end
    if (direction === 'next' && currentArtworkIndex >= artworks.length - 3) {
      loadMoreArtworks();
    }

    const newIndex = direction === 'next'
      ? (currentArtworkIndex + 1) % artworks.length
      : (currentArtworkIndex - 1 + artworks.length) % artworks.length;

    setCurrentArtworkIndex(newIndex);
    setupGame(artworks[newIndex]);
  };

  // Retry the current artwork
  const handleRetry = () => {
    setupGame(currentArtwork);
  };

  // Toggle polaroid flip
  const handleFlip = () => {
    if (polaroidRef.current) {
      polaroidRef.current.classList.toggle('rotate-y-180');
      setTimeout(() => {
        setIsFlipped(!isFlipped);
      }, 150); // Half of the transition time
    } else {
      setIsFlipped(!isFlipped);
    }
  };

  // Handle onboarding navigation
  const handleOnboardingNav = (direction) => {
    if (direction === 'next') {
      if (currentOnboardingStep < onboardingSteps.length - 1) {
        setCurrentOnboardingStep(prev => prev + 1);
      } else {
        completeOnboarding();
      }
    } else {
      setCurrentOnboardingStep(prev => Math.max(0, prev - 1));
    }
  };

  // Complete onboarding
  const completeOnboarding = () => {
    setShowOnboarding(false);
    localStorage.setItem('hasSeenOnboarding', 'true');
  };

  // Render color palette
  const renderColorPalette = () => {
    if (!currentArtwork) return null;

    return (
      <div className="flex flex-wrap justify-center mt-4 gap-2">
        {currentArtwork.colors.map((color, index) => (
          <div
            key={index}
            className="w-8 h-8 rounded-sm border border-stone-300 flex flex-col items-center justify-center relative"
            style={{ backgroundColor: index === gameState.missingColorIndex ? 'transparent' : color }}
          >
            {index === gameState.missingColorIndex && <span className="text-2xl text-stone-800">?</span>}
            {showHex && index !== gameState.missingColorIndex && (
              <div className="absolute -bottom-6 left-1/2 transform -translate-x-1/2 bg-stone-800 text-stone-100 text-xs px-1 py-0.5 rounded whitespace-nowrap">
                {color}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  // If loading, show loading state
  if (isLoading && artworks.length === 0) {
    return (
      <div className="flex items-center justify-center h-full bg-stone-100 p-6">
        <div className="flex flex-col items-center">
          <div className="w-16 h-1 bg-stone-800 mb-6"></div>
          <p className="text-stone-800 font-light">Loading artworks from Europeana...</p>
        </div>
      </div>
    );
  }

  // If error and no artworks, show error state
  if (hasError && artworks.length === 0) {
    return (
      <div className="flex items-center justify-center h-full bg-stone-100 p-6">
        <div className="flex flex-col items-center text-center max-w-md">
          <div className="w-16 h-1 bg-stone-800 mb-6"></div>
          <h2 className="text-xl font-light text-stone-800 mb-4">Unable to Connect to Europeana</h2>
          <p className="text-stone-600 mb-6">We couldn't retrieve artwork data from Europeana. This could be due to an invalid API key or connection issues.</p>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 border border-stone-800 text-stone-800 text-sm tracking-wider transition-colors hover:bg-stone-800 hover:text-stone-50"
          >
            RETRY
          </button>
        </div>
      </div>
    );
  }

  // If no artworks after loading, show empty state
  if (!currentArtwork) {
    return (
      <div className="flex items-center justify-center h-full bg-stone-100 p-6">
        <div className="flex flex-col items-center">
          <div className="w-16 h-1 bg-stone-800 mb-6"></div>
          <p className="text-stone-800 font-light">No artworks found. Please try again later.</p>
        </div>
      </div>
    );
  }

  // Render onboarding overlay
  if (showOnboarding) {
    const currentStep = onboardingSteps[currentOnboardingStep];

    return (
      <div className="fixed inset-0 bg-stone-900 bg-opacity-90 z-50 flex items-center justify-center p-4">
        <div className="bg-stone-100 rounded-sm max-w-md w-full p-6 relative">
          <button
            onClick={completeOnboarding}
            className="absolute top-4 right-4 text-stone-600 hover:text-stone-800 transition-colors"
          >
            <X size={20} />
          </button>

          <div className="text-center mb-6">
            <div className="w-16 h-1 bg-stone-800 mx-auto mb-6"></div>
            <h2 className="text-2xl font-light text-stone-800 mb-2">{currentStep.title}</h2>
            <p className="text-stone-600">{currentStep.content}</p>
          </div>

          <div className="mb-6">
            <img
              src={currentStep.image}
              alt={`Onboarding step ${currentOnboardingStep + 1}`}
              className="w-full h-48 object-cover object-center rounded-sm"
            />
          </div>

          <div className="flex justify-between items-center">
            <div className="flex space-x-2">
              {onboardingSteps.map((_, index) => (
                <div
                  key={index}
                  className={`w-2 h-2 rounded-full ${index === currentOnboardingStep ? 'bg-stone-800' : 'bg-stone-300'}`}
                />
              ))}
            </div>

            <div className="flex space-x-4">
              {currentOnboardingStep > 0 && (
                <button
                  onClick={() => handleOnboardingNav('prev')}
                  className="px-4 py-2 text-stone-800 border border-stone-800 text-sm tracking-wider hover:bg-stone-800 hover:text-stone-100 transition-colors"
                >
                  BACK
                </button>
              )}
              <button
                onClick={() => handleOnboardingNav('next')}
                className="px-4 py-2 bg-stone-800 text-stone-100 text-sm tracking-wider hover:bg-stone-700 transition-colors"
              >
                {currentOnboardingStep < onboardingSteps.length - 1 ? 'NEXT' : 'START PLAYING'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const isMobile = windowWidth < 768;

  return (
    <div className="h-full bg-stone-100 font-light p-4 md:p-6 flex flex-col">
      <div className="max-w-4xl mx-auto w-full flex-grow flex flex-col">
        {/* Project title and controls moved to body */}
        <div className="mb-8">
          <div className="flex flex-col items-center mb-4">
            <div className="w-24 h-1 bg-stone-800 mb-4"></div>
            <h2 className="text-2xl font-light text-stone-800">PROJECT PALETTE</h2>
            <p className="text-center text-stone-600 mt-2">Discover the colors of fine art from Europeana</p>
          </div>

          <div className="flex flex-wrap justify-center gap-4 mt-6">
            <button
              onClick={() => setShowHex(!showHex)}
              className="flex items-center text-stone-800 text-sm tracking-wider transition-colors hover:text-stone-600 border border-stone-300 px-3 py-1.5 rounded-sm"
            >
              {showHex ? <EyeOff size={16} className="mr-1" /> : <Eye size={16} className="mr-1" />}
              {showHex ? 'HIDE HEX' : 'SHOW HEX'}
            </button>

            <button
              onClick={() => downloadPalette(currentArtwork.colors, currentArtwork.title)}
              className="flex items-center text-stone-800 text-sm tracking-wider transition-colors hover:text-stone-600 border border-stone-300 px-3 py-1.5 rounded-sm"
            >
              <Download size={16} className="mr-1" />
              DOWNLOAD PALETTE
            </button>

            <button
              onClick={() => {
                setShowOnboarding(true);
                setCurrentOnboardingStep(0);
              }}
              className="flex items-center text-stone-800 text-sm tracking-wider transition-colors hover:text-stone-600 border border-stone-300 px-3 py-1.5 rounded-sm"
            >
              <Info size={16} className="mr-1" />
              HOW TO PLAY
            </button>
          </div>
        </div>

        {/* Polaroid Card */}
        <div
          ref={polaroidRef}
          className="relative mx-auto w-full max-w-md transition-transform duration-300 perspective-1000 mb-8"
        >
          {/* Polaroid Front */}
          <div className={`relative bg-stone-50 p-4 rounded-sm shadow-md border border-stone-200 ${isFlipped ? 'hidden' : 'block'}`}>
            <div className="relative pb-4">
              <img
                src={currentArtwork.image_url}
                alt={currentArtwork.title}
                className="w-full h-48 md:h-64 object-cover object-center"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/api/placeholder/600/450";
                }}
              />

              <div className="mt-6">
                {renderColorPalette()}
              </div>

              <button
                onClick={handleFlip}
                className="absolute top-2 right-2 bg-stone-50 bg-opacity-70 p-1 transition-opacity duration-300 hover:opacity-80"
                aria-label="Show artwork information"
              >
                <Info size={18} className="text-stone-800" />
              </button>
            </div>

            <div className="mt-8 text-center">
              <p className="font-light text-stone-800">Which color is missing at position {gameState.missingColorIndex + 1}?</p>

              <div className="flex flex-wrap justify-center mt-6 gap-3">
                {gameState.options.map((color, index) => (
                  <button
                    key={index}
                    onClick={() => handleColorSelect(color)}
                    className={`
                      w-16 h-8 rounded-sm border
                      ${gameState.selectedOption === color
                        ? gameState.isCorrect
                          ? 'border-green-500'
                          : 'border-red-500'
                        : 'border-stone-300 hover:border-stone-600'
                      }
                      transition-all duration-300 relative
                    `}
                    style={{ backgroundColor: color }}
                    disabled={gameState.selectedOption !== null}
                    aria-label={`Color option ${color}`}
                  >
                    {showHex && (
                      <div className="absolute -bottom-6 left-1/2 transform -translate-x-1/2 bg-stone-800 text-stone-100 text-xs px-1 py-0.5 rounded whitespace-nowrap">
                        {color}
                      </div>
                    )}
                  </button>
                ))}
              </div>

              {gameState.selectedOption !== null && (
                <div className="mt-8">
                  {gameState.isCorrect ? (
                    <div className="text-stone-800 font-light flex flex-col items-center">
                      <span>Correct!</span>
                      <button
                        onClick={() => navigateArtwork('next')}
                        className="mt-4 inline-flex items-center text-stone-800 border-b border-stone-800 pb-1 transition-colors hover:text-stone-600 hover:border-stone-600"
                      >
                        Next Artwork <ArrowRight size={16} className="ml-1" />
                      </button>
                    </div>
                  ) : (
                    <div className="text-stone-800 font-light flex flex-col items-center">
                      <div>
                        Incorrect. The correct color was{' '}
                        <span
                          className="inline-block w-6 h-4 align-middle border border-stone-300"
                          style={{ backgroundColor: gameState.correctColor }}
                        />
                        {showHex && <span className="ml-1 text-sm">({gameState.correctColor})</span>}
                      </div>
                      <button
                        onClick={handleRetry}
                        className="mt-4 inline-flex items-center text-stone-800 border-b border-stone-800 pb-1 transition-colors hover:text-stone-600 hover:border-stone-600"
                      >
                        Try Again <RotateCcw size={16} className="ml-1" />
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Polaroid Back */}
          <div className={`relative bg-stone-100 p-6 rounded-sm shadow-md border border-stone-200 ${isFlipped ? 'block' : 'hidden'}`}>
            <button
              onClick={handleFlip}
              className="absolute top-4 right-4 text-stone-600 hover:text-stone-800 transition-colors"
              aria-label="Close information"
            >
              <X size={18} />
            </button>

            <div className="h-full flex flex-col justify-between">
              <div>
                <div className="w-16 h-px bg-stone-400 mb-4"></div>
                <h2 className="text-xl font-light text-stone-800">{currentArtwork.title}</h2>
                <p className="text-stone-600 mt-1">{currentArtwork.artist}, {currentArtwork.year}</p>

                <div className="mt-8">
                  <h3 className="text-stone-800">MUSEUM</h3>
                  <p className="text-stone-700 mt-1">{currentArtwork.museum.name}</p>
                  <p className="text-stone-600 text-sm mt-4 leading-relaxed">{currentArtwork.museum.details}</p>
                </div>
              </div>

              <div className="text-sm text-stone-500 italic mt-8 text-center">
                Click anywhere to return to the game
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Controls - grouped together */}
        <div className="flex justify-center gap-4 mx-auto mt-6">
          <button
            onClick={() => navigateArtwork('prev')}
            className="px-4 py-2 border border-stone-800 text-stone-800 text-sm tracking-wider transition-colors hover:bg-stone-800 hover:text-stone-50 flex items-center"
          >
            <ArrowLeft size={16} className="mr-1" /> PREVIOUS
          </button>

          <button
            onClick={() => {
              const randomIndex = Math.floor(Math.random() * artworks.length);
              setCurrentArtworkIndex(randomIndex);
              setupGame(artworks[randomIndex]);
            }}
            className="px-4 py-2 border border-stone-800 text-stone-800 text-sm tracking-wider transition-colors hover:bg-stone-800 hover:text-stone-50 flex items-center"
          >
            RANDOM <Shuffle size={16} className="ml-1" />
          </button>

          <button
            onClick={() => navigateArtwork('next')}
            className="px-4 py-2 border border-stone-800 text-stone-800 text-sm tracking-wider transition-colors hover:bg-stone-800 hover:text-stone-50 flex items-center"
          >
            NEXT <ArrowRight size={16} className="ml-1" />
          </button>
        </div>

        {/* Footer info */}
        <div className="text-center mt-auto pt-8 pb-4">
          <div className="w-12 h-px bg-stone-400 mx-auto mb-4"></div>
          <p className="text-sm text-stone-500">Art data provided by Europeana API</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectPalette;