import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import _ from 'lodash';

// Fix for default marker icons in Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

// Custom Wikipedia marker icon - Map pointer
const wikiIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [41, 41],
  className: 'wiki-marker'
});

// Player marker icon - Star shape
const playerIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconSize: [35, 45],
  iconAnchor: [17, 42],
  popupAnchor: [1, -32],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [41, 41],
  className: 'player-marker player-star-icon'
});

// Cities to choose from
const cities = [
  { name: "Tokyo", coords: [35.6762, 139.6503] },
  { name: "Kyoto", coords: [35.0116, 135.7681] },
  { name: "Copenhagen", coords: [55.6761, 12.5683] },
  { name: "Stockholm", coords: [59.3293, 18.0686] },
  { name: "Helsinki", coords: [60.1699, 24.9384] },
  { name: "Oslo", coords: [59.9139, 10.7522] },
  { name: "New York", coords: [40.7128, -74.0060] },
  { name: "London", coords: [51.5074, -0.1278] },
  { name: "Paris", coords: [48.8566, 2.3522] },
  { name: "Rome", coords: [41.9028, 12.4964] },
  { name: "Sydney", coords: [-33.8688, 151.2093] },
  { name: "Rio de Janeiro", coords: [-22.9068, -43.1729] },
  { name: "Cairo", coords: [30.0444, 31.2357] },
  { name: "Cape Town", coords: [-33.9249, 18.4241] },
  { name: "Mumbai", coords: [19.0760, 72.8777] },
  { name: "Seoul", coords: [37.5665, 126.9780] },
  { name: "Hong Kong", coords: [22.3193, 114.1694] },
  { name: "Singapore", coords: [1.3521, 103.8198] },
  { name: "Moscow", coords: [55.7558, 37.6173] },
  { name: "Berlin", coords: [52.5200, 13.4050] },
  { name: "Madrid", coords: [40.4168, -3.7038] },
  { name: "Amsterdam", coords: [52.3676, 4.9041] },
  { name: "Athens", coords: [37.9838, 23.7275] },
  { name: "Bangkok", coords: [13.7563, 100.5018] },
];

// Function to get a random location
const getRandomLocation = () => {
  const randomIndex = Math.floor(Math.random() * cities.length);
  return cities[randomIndex];
};

// Game constants
const GAME_DURATION = 60; // 1 minute in seconds
const MOVEMENT_SPEED = 0.0005; // Degrees per key press - faster movement
const WIKI_VISIT_RADIUS = 0.0003; // How close to be considered "visited"
const WIKI_FETCH_RADIUS = 1500; // Radius in meters to fetch Wikipedia articles
const WIKI_PROXIMITY_THRESHOLD = 0.01; // Threshold to fetch new articles

const WikipediaWalkGame = () => {
  // Game state
  const [gameState, setGameState] = useState('menu'); // menu, playing, gameover, onboarding
  const [timer, setTimer] = useState(GAME_DURATION);
  const [score, setScore] = useState(0);
  const [points, setPoints] = useState(0);
  const [visitedArticles, setVisitedArticles] = useState([]);
  const [playerPosition, setPlayerPosition] = useState(null);
  const [selectedCity, setSelectedCity] = useState(cities[0]);
  const [wikiArticles, setWikiArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastFetchPosition, setLastFetchPosition] = useState(null);
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [nearbyArticle, setNearbyArticle] = useState(null); // Store the closest article for preview
  const timerRef = useRef(null);
  const mapRef = useRef(null);

  // Fetch Wikipedia articles from GeoSearch API with pageviews
  const fetchWikipediaArticles = async (center, additive = false) => {
    try {
      const response = await fetch(
        `https://en.wikipedia.org/w/api.php?` +
        `action=query&format=json&list=geosearch&origin=*&` +
        `gsradius=${WIKI_FETCH_RADIUS}&gscoord=${center[0]}|${center[1]}&gslimit=50`
      );

      const data = await response.json();

      if (!data || !data.query || !data.query.geosearch) {
        throw new Error('Invalid data received from Wikipedia API');
      }

      // Transform the API response into our article format
      const articles = data.query.geosearch.map(item => ({
        id: item.pageid,
        title: item.title,
        lat: item.lat,
        lon: item.lon,
        distance: item.dist,
        visited: false,
        pointValue: 0, // Will be set after pageviews fetch
        pageViews: 0    // Will be populated with actual pageviews
      }));

      // Update last fetch position
      setLastFetchPosition(center);

      // Either replace or add to existing articles
      if (additive) {
        // Filter out articles we already have
        const existingIds = wikiArticles.map(a => a.id);
        const newArticles = articles.filter(a => !existingIds.includes(a.id));

        // Update existing articles without replacing visited status
        const updatedExistingArticles = wikiArticles.map(existingArticle => {
          const matchingNewArticle = articles.find(a => a.id === existingArticle.id);
          if (matchingNewArticle) {
            return {
              ...matchingNewArticle,
              visited: existingArticle.visited,
              pointValue: existingArticle.pointValue,
              pageViews: existingArticle.pageViews
            };
          }
          return existingArticle;
        });

        setWikiArticles([...updatedExistingArticles, ...newArticles]);

        // Fetch summaries and pageviews for only the new articles
        fetchSummariesForArticles(newArticles);
        fetchPageViewsForArticles(newArticles);
      } else {
        setWikiArticles(articles);

        // Fetch summaries and pageviews for all articles
        fetchSummariesForArticles(articles);
        fetchPageViewsForArticles(articles);
      }
    } catch (error) {
      console.error('Error fetching Wikipedia articles:', error);
      setError("Failed to fetch Wikipedia articles. Using simulated data instead.");

      if (!additive || wikiArticles.length === 0) {
        // Provide simulated articles as fallback
        const simulatedArticles = generateSimulatedArticles(center);
        setWikiArticles(simulatedArticles);
      }
    }
  };

  // Fetch pageviews for articles and assign point values
  const fetchPageViewsForArticles = async (articles) => {
    try {
      // Process in batches of 10 articles
      const batches = _.chunk(articles, 10);

      for (const batch of batches) {
        // Get the titles for this batch
        const titles = batch.map(article => article.title).join('|');

        // Get pageviews for the last 30 days
        const today = new Date();
        const lastMonth = new Date(today);
        lastMonth.setDate(lastMonth.getDate() - 30);

        const startDate = `${lastMonth.getFullYear()}${String(lastMonth.getMonth() + 1).padStart(2, '0')}${String(lastMonth.getDate()).padStart(2, '0')}`;
        const endDate = `${today.getFullYear()}${String(today.getMonth() + 1).padStart(2, '0')}${String(today.getDate()).padStart(2, '0')}`;

        // We'll simulate pageviews since the actual pageviews API is more complex
        // In a real implementation, you would use the Wikimedia Pageviews API

        // Update articles with simulated pageviews and point values
        setWikiArticles(currentArticles => {
          return currentArticles.map(article => {
            if (batch.some(a => a.id === article.id)) {
              // Generate random pageviews (would be real data in production)
              const pageViews = Math.floor(Math.random() * 100000);

              // Calculate point value - more points for less popular articles
              // Scale is 1-10 points
              let pointValue;
              if (pageViews < 1000) pointValue = 10;
              else if (pageViews < 5000) pointValue = 8;
              else if (pageViews < 10000) pointValue = 6;
              else if (pageViews < 50000) pointValue = 4;
              else pointValue = 2;

              return {
                ...article,
                pageViews,
                pointValue
              };
            }
            return article;
          });
        });
      }
    } catch (error) {
      console.error('Error fetching article pageviews:', error);
    }
  };

  // Fetch summaries for articles in batches
  const fetchSummariesForArticles = async (articles) => {
    try {
      // Split into batches of 10 articles to avoid overloading
      const batches = _.chunk(articles, 10);

      for (const batch of batches) {
        const pageIds = batch.map(article => article.id).join('|');

        const summaryResponse = await fetch(
          `https://en.wikipedia.org/w/api.php?` +
          `action=query&format=json&prop=extracts&origin=*&` +
          `exintro=1&explaintext=1&pageids=${pageIds}&exsentences=2`
        );

        const summaryData = await summaryResponse.json();

        if (summaryData && summaryData.query && summaryData.query.pages) {
          const pages = summaryData.query.pages;

          // Update articles with summaries
          setWikiArticles(currentArticles => {
            return currentArticles.map(article => {
              if (batch.some(a => a.id === article.id) && pages[article.id]) {
                return {
                  ...article,
                  summary: pages[article.id].extract || 'No summary available.'
                };
              }
              return article;
            });
          });
        }
      }
    } catch (error) {
      console.error('Error fetching article summaries:', error);
    }
  };

  // Generate simulated Wikipedia articles as fallback
  const generateSimulatedArticles = (center) => {
    const simulatedArticles = [];

    // Generate random Wikipedia articles around the center
    for (let i = 0; i < 20; i++) {
      const lat = center[0] + (Math.random() - 0.5) * 0.02;
      const lng = center[1] + (Math.random() - 0.5) * 0.02;

      // Generate random pageviews and point values
      const pageViews = Math.floor(Math.random() * 100000);
      let pointValue;
      if (pageViews < 1000) pointValue = 10;
      else if (pageViews < 5000) pointValue = 8;
      else if (pageViews < 10000) pointValue = 6;
      else if (pageViews < 50000) pointValue = 4;
      else pointValue = 2;

      simulatedArticles.push({
        id: `article-${i}`,
        title: `${getRandomLandmark()} near ${selectedCity.name}`,
        lat,
        lon: lng,
        summary: `This serene location represents the perfect harmony of natural beauty and human craftsmanship, embodying the essence of Japandi design principles.`,
        visited: false,
        pageViews,
        pointValue
      });
    }

    return simulatedArticles;
  };

  // Helper function for generating simulated article names
  const getRandomLandmark = () => {
    const landmarks = [
      'Zen Garden', 'Wooden Bridge', 'Bamboo Forest', 'Stone Path',
      'Maple Grove', 'Cedar House', 'Minimalist Temple', 'Peaceful Pond',
      'Pine Mountain', 'Ceramic Studio', 'Wabi-Sabi Pavilion', 'Ryokan Inn',
      'Paper Lantern Square', 'Nordic Lodge', 'Fjord View'
    ];
    return landmarks[Math.floor(Math.random() * landmarks.length)];
  };

  // Initialize the game
  const startGame = async () => {
    setIsLoading(true);
    setOnboardingStep(0);
    setGameState('onboarding'); // Start with onboarding first
    setTimer(GAME_DURATION);
    setScore(0);
    setPoints(0);
    setVisitedArticles([]);
    setError(null);

    const initialPosition = [...selectedCity.coords];
    setPlayerPosition(initialPosition);
    setLastFetchPosition(initialPosition);

    try {
      // Fetch Wikipedia articles
      await fetchWikipediaArticles(initialPosition);

    } catch (err) {
      console.error('Game initialization error:', err);
      setError("Failed to initialize game. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Start game after onboarding
  const startGameAfterOnboarding = () => {
    setGameState('playing');

    // Start the timer
    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setTimer(prev => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          endGame();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // End the game
  const endGame = () => {
    clearInterval(timerRef.current);
    setGameState('gameover');
  };

  // Calculate distance between two points
  const calculateDistance = (point1, point2) => {
    return Math.sqrt(
      Math.pow(point1[0] - point2[0], 2) +
      Math.pow(point1[1] - point2[1], 2)
    );
  };

  // Check for article visits and check for nearby articles for preview
  useEffect(() => {
    if (gameState === 'playing' && playerPosition) {
      const checkArticleVisits = () => {
        const newWikiArticles = [...wikiArticles];
        let newVisits = false;
        let pointsGained = 0;
        let closestArticle = null;
        let closestDistance = 0.003; // Just outside preview radius

        newWikiArticles.forEach((article, idx) => {
          const distance = calculateDistance(
            playerPosition,
            [article.lat, article.lon]
          );

          // Check for visits (collection)
          if (!article.visited && distance <= WIKI_VISIT_RADIUS) {
            newWikiArticles[idx].visited = true;
            newVisits = true;

            // Add points based on article's point value
            const articlePoints = article.pointValue || 5; // Default to 5 if not set
            pointsGained += articlePoints;

            // Add to visited articles
            setVisitedArticles(prev => [...prev, {
              ...article,
              pointsAwarded: articlePoints
            }]);
          }

          // Check for closest article for preview
          if (!article.visited && distance < closestDistance && distance <= 0.002) {
            closestArticle = article;
            closestDistance = distance;
          }
        });

        // Update nearby article for preview
        setNearbyArticle(closestArticle);

        if (newVisits) {
          setWikiArticles(newWikiArticles);
          setScore(visitedArticles.length + 1); // +1 for the new visit that will be added
          setPoints(prevPoints => prevPoints + pointsGained);
        }
      };

      checkArticleVisits();
    }
  }, [playerPosition, gameState, WIKI_VISIT_RADIUS, wikiArticles, visitedArticles]);

  // Handle keyboard movement
  useEffect(() => {
    if (gameState !== 'playing') return;

    const handleKeyDown = (e) => {
      if (!playerPosition) return;

      let newPos = [...playerPosition];

      switch(e.key) {
        case 'ArrowUp':
          newPos[0] += MOVEMENT_SPEED;
          break;
        case 'ArrowDown':
          newPos[0] -= MOVEMENT_SPEED;
          break;
        case 'ArrowLeft':
          newPos[1] -= MOVEMENT_SPEED;
          break;
        case 'ArrowRight':
          newPos[1] += MOVEMENT_SPEED;
          break;
        default:
          return;
      }

      setPlayerPosition(newPos);
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [gameState, playerPosition]);

  // Check for article visits and new article fetch whenever player position changes
  useEffect(() => {
    if (gameState === 'playing' && playerPosition) {
      // Check for new article fetch
      if (lastFetchPosition) {
        const distanceFromLastFetch = calculateDistance(playerPosition, lastFetchPosition);

        if (distanceFromLastFetch > WIKI_PROXIMITY_THRESHOLD) {
          // Player has moved far enough to fetch new articles
          fetchWikipediaArticles(playerPosition, true);
        }
      }
    }
  }, [playerPosition, gameState, lastFetchPosition, WIKI_PROXIMITY_THRESHOLD]);

  // Clean up interval on unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, []);

  // Player marker component with position updates
  const PlayerMarker = () => {
    const map = useMap();

    useEffect(() => {
      if (playerPosition) {
        map.setView(playerPosition);
      }
    }, [playerPosition]);

    return playerPosition ? (
      <Marker position={playerPosition} icon={playerIcon}>
        <Popup>
          <div className="text-neutral-800">
            You are here
          </div>
        </Popup>
      </Marker>
    ) : null;
  };

  // Render gameplay screen
  const renderGameplay = () => {
    return (
      <div className="h-screen w-full relative" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999 }}>
        {/* Left overlay for articles and points */}
        <div className="absolute top-4 left-4 z-10 bg-neutral-800 bg-opacity-70 rounded-lg shadow-lg overflow-hidden">
          <div className="flex flex-col p-3 text-neutral-100">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm uppercase tracking-wider">Articles</span>
              <span className="text-xl font-semibold ml-4">{score}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm uppercase tracking-wider">Points</span>
              <span className="text-xl font-semibold ml-4">{points}</span>
            </div>
          </div>
        </div>

        {/* Right overlay for timer */}
        <div className="absolute top-4 right-4 z-10 bg-neutral-800 bg-opacity-70 rounded-lg shadow-lg overflow-hidden">
          <div className="flex items-center p-3 text-neutral-100">
            <span className="text-sm uppercase tracking-wider mr-2">Time</span>
            <span className="text-xl font-semibold">{timer}s</span>
          </div>
        </div>

        {/* Error message if needed */}
        {error && (
          <div className="absolute top-0 left-0 right-0 bg-neutral-800 text-neutral-100 p-2 z-10 text-center">
            {error}
          </div>
        )}

        {/* Map Container */}
        <MapContainer
          center={playerPosition || selectedCity.coords}
          zoom={16}
          style={{ height: '100%', width: '100%' }}
          ref={mapRef}
          zoomControl={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />

          <PlayerMarker />

          {wikiArticles.map((article) => (
            <Marker
              key={article.id}
              position={[article.lat, article.lon]}
              icon={wikiIcon}
              opacity={article.visited ? 0.7 : 1}
              className={article.visited ? 'visited-marker' : ''}
            >
              <Popup>
                <div className="max-w-xs text-neutral-800">
                  <a
                    href={`https://en.wikipedia.org/?curid=${article.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-medium text-lg mb-1 text-blue-700 hover:underline block"
                  >
                    {article.title}
                  </a>
                  <p className="text-sm my-2">{article.summary || 'Loading information...'}</p>
                  {article.pointValue > 0 && (
                    <div className="text-sm text-neutral-600 mt-1">
                      Worth: <span className="font-medium">{article.pointValue} points</span>
                      {article.pageViews > 0 && (
                        <span className="ml-2">({article.pageViews.toLocaleString()} views/month)</span>
                      )}
                    </div>
                  )}
                  {article.visited && (
                    <p className="text-neutral-700 font-medium border-t border-neutral-200 pt-2 mt-2">✓ Discovered</p>
                  )}
                  <a
                    href={`https://en.wikipedia.org/?curid=${article.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-600 text-while px-3 py-1 rounded text-sm inline-block mt-3 hover:bg-blue-700"
                  >
                    View on Wikipedia
                  </a>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>

        {/* Article Preview Panel */}
        {nearbyArticle && (
          <div className="absolute bottom-16 right-4 z-10 bg-white rounded-lg shadow-lg overflow-hidden max-w-sm">
            <div className="p-4">
              <h3 className="font-medium text-lg text-blue-700 mb-1">
                {nearbyArticle.title}
                <span className="ml-2 text-sm font-normal px-2 py-0.5 bg-blue-100 rounded-full text-blue-800">
                  +{nearbyArticle.pointValue || 5} pts
                </span>
              </h3>
              <p className="text-sm text-neutral-600">{nearbyArticle.summary || 'Loading information...'}</p>
              <div className="flex justify-between items-center mt-2 text-xs text-neutral-500">
                <div>
                  {nearbyArticle.pageViews && (
                    <span>{nearbyArticle.pageViews.toLocaleString()} views/month</span>
                  )}
                </div>
                <span>Get closer to collect!</span>
              </div>
              <a
                href={`https://en.wikipedia.org/?curid=${nearbyArticle.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-600 text-white px-3 py-1 rounded text-sm inline-block mt-3 hover:bg-blue-700"
              >
                View on Wikipedia
              </a>
            </div>
          </div>
        )}

        {/* Mobile controls hint */}
        <div className="md:hidden absolute bottom-0 left-0 right-0 bg-neutral-800 bg-opacity-70 text-neutral-100 p-2 text-center">
          Use arrow keys to move
        </div>

        {/* Player icon CSS */}
        <style jsx global>{`
          .player-star-icon {
            filter: hue-rotate(140deg) saturate(1.5) !important;
            transform: scale(1.2);
            z-index: 1000;
          }
        `}</style>
      </div>
    );
  };

  // Render menu screen
  const renderMenu = () => {
    return (
      <div className="flex flex-col items-center max-w-2xl mx-auto">
        <div className="mb-8 text-center">
          <p className="text-neutral-600 text-lg my-4">Explore the world and discover Wikipedia articles. Move freely and collect as many locations as possible in 60 seconds.</p>
          <p className="text-neutral-600 my-2">Earn more points for discovering lesser-known locations!</p>
        </div>

        <div className="flex flex-col items-center mb-8 w-full">
          <h3 className="text-lg mb-4 font-normal">Choose how to start:</h3>

          <div className="flex gap-4 mb-6">
            <button
              className="bg-neutral-800 hover:bg-neutral-700 text-neutral-100 px-8 py-3 transition-colors"
              onClick={() => {
                setSelectedCity(getRandomLocation());
                startGame();
              }}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Random Location"}
            </button>

            <button
              className="bg-neutral-700 hover:bg-neutral-600 text-neutral-100 px-8 py-3 transition-colors"
              onClick={() => document.getElementById('city-selector').scrollIntoView({ behavior: 'smooth' })}
            >
              Choose a City
            </button>
          </div>
        </div>

        <div id="city-selector" className="w-full">
          <h3 className="text-lg mb-4 font-normal text-center">Or select a specific location:</h3>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mb-8 w-full max-h-64 overflow-y-auto p-2">
            {cities.map((city) => (
              <button
                key={city.name}
                className={`p-3 transition-colors border ${
                  selectedCity.name === city.name
                    ? 'bg-neutral-700 text-neutral-100 border-neutral-700'
                    : 'bg-neutral-100 hover:bg-neutral-200 border-neutral-400'
                }`}
                onClick={() => setSelectedCity(city)}
              >
                {city.name}
              </button>
            ))}
          </div>

          <div className="flex justify-center">
            <button
              className="bg-neutral-800 hover:bg-neutral-700 text-neutral-100 px-10 py-3 transition-colors w-48"
              onClick={startGame}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Begin with " + selectedCity.name}
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Render game over screen
  const renderGameOver = () => {
    return (
      <div className="flex flex-col items-center max-w-2xl mx-auto">
        <h2 className="text-3xl mb-2 font-light">Exploration Complete</h2>
        <div className="flex flex-col items-center mb-6 bg-neutral-200 p-4 rounded-lg w-full">
          <p className="text-2xl">You discovered <span className="font-semibold">{score}</span> locations</p>
          <p className="text-xl mt-1">Total points: <span className="font-semibold text-blue-700">{points}</span></p>
          <p className="text-neutral-600 mt-2">Started in: {selectedCity.name}</p>
        </div>

        <div className="flex gap-4 mb-8">
          <button
            className="bg-neutral-800 hover:bg-neutral-700 text-neutral-100 px-8 py-3 transition-colors"
            onClick={() => setGameState('menu')}
          >
            Menu
          </button>

          <button
            className="bg-neutral-700 hover:bg-neutral-600 text-neutral-100 px-8 py-3 transition-colors"
            onClick={() => {
              setSelectedCity(getRandomLocation());
              startGame();
            }}
          >
            Try Random Location
          </button>
        </div>

        {/* Visited Articles with Wikipedia Links and Points */}
        {visitedArticles.length > 0 && (
          <div className="w-full">
            <h3 className="text-xl font-light mb-4 border-b border-neutral-300 pb-2">Discovered Locations</h3>
            <div className="max-h-64 overflow-y-auto pr-4">
              <ul className="space-y-4">
                {visitedArticles.map((article) => (
                  <li key={article.id} className="border-b border-neutral-200 pb-3">
                    <div className="flex justify-between items-center">
                      <a
                        href={`https://en.wikipedia.org/?curid=${article.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-medium text-lg text-blue-700 hover:underline"
                      >
                        {article.title}
                      </a>
                      <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded-md text-sm">
                        +{article.pointsAwarded || article.pointValue || 5} pts
                      </span>
                    </div>
                    <p className="text-neutral-600 mt-1">{article.summary}</p>
                    <div className="flex justify-between text-sm text-neutral-500 mt-1">
                      <span>Popularity: {article.pageViews ? `${article.pageViews.toLocaleString()} views` : 'Unknown'}</span>
                      <span>Rarity: {article.pointValue === 10 ? 'Very Rare' :
                                    article.pointValue >= 8 ? 'Rare' :
                                    article.pointValue >= 6 ? 'Uncommon' :
                                    article.pointValue >= 4 ? 'Common' : 'Very Common'}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  };

  // Render onboarding screen
  const renderOnboarding = () => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-60 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full mx-4 overflow-hidden">
          {onboardingStep === 0 && (
            <div className="p-6">
              <h2 className="text-2xl font-light mb-4 text-neutral-800">Welcome to Wikipedia Walk!</h2>
              <div className="mb-6">
                <p className="mb-3">You're about to explore <span className="font-medium">{selectedCity.name}</span> and discover Wikipedia articles about locations in this area.</p>
                <p className="mb-3">Move around the map using your <span className="font-bold">arrow keys</span> to discover Wikipedia articles.</p>
                <p>You have <span className="font-bold">60 seconds</span> to find as many articles as possible!</p>
              </div>
              <button
                className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition-colors"
                onClick={() => setOnboardingStep(1)}
              >
                Next
              </button>
            </div>
          )}

          {onboardingStep === 1 && (
            <div className="p-6">
              <h2 className="text-2xl font-light mb-4 text-neutral-800">Points System</h2>
              <div className="mb-6">
                <p className="mb-3">Articles are worth different points based on their popularity:</p>
                <ul className="space-y-2 mb-4">
                  <li className="flex items-center">
                    <span className="inline-block w-4 h-4 bg-blue-900 rounded-full mr-2"></span>
                    <span className="font-medium">10 points</span> - Very rare (less than 1,000 views/month)
                  </li>
                  <li className="flex items-center">
                    <span className="inline-block w-4 h-4 bg-blue-700 rounded-full mr-2"></span>
                    <span className="font-medium">8 points</span> - Rare (1,000-5,000 views/month)
                  </li>
                  <li className="flex items-center">
                    <span className="inline-block w-4 h-4 bg-blue-500 rounded-full mr-2"></span>
                    <span className="font-medium">6 points</span> - Uncommon (5,000-10,000 views/month)
                  </li>
                  <li className="flex items-center">
                    <span className="inline-block w-4 h-4 bg-blue-400 rounded-full mr-2"></span>
                    <span className="font-medium">4 points</span> - Common (10,000-50,000 views/month)
                  </li>
                  <li className="flex items-center">
                    <span className="inline-block w-4 h-4 bg-blue-300 rounded-full mr-2"></span>
                    <span className="font-medium">2 points</span> - Very common (over 50,000 views/month)
                  </li>
                </ul>
                <p>Discover lesser-known locations for higher scores!</p>
              </div>
              <div className="flex gap-4">
                <button
                  className="bg-neutral-400 text-white px-6 py-2 rounded hover:bg-neutral-500 transition-colors"
                  onClick={() => setOnboardingStep(0)}
                >
                  Back
                </button>
                <button
                  className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition-colors"
                  onClick={() => setOnboardingStep(2)}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {onboardingStep === 2 && (
            <div className="p-6">
              <h2 className="text-2xl font-light mb-4 text-neutral-800">Ready to Explore?</h2>
              <div className="mb-6">
                <p className="mb-3">Your stats will appear in small overlays at the top of the screen.</p>
                <p className="mb-3">Click on article markers to read about them and see their point value.</p>
                <p className="mb-5">Your adventure in <span className="font-medium">{selectedCity.name}</span> is about to begin!</p>

                <div className="flex items-center bg-neutral-100 p-3 rounded border border-neutral-200">
                  <div className="mr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-amber-500">
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="12"></line>
                      <line x1="12" y1="16" x2="12.01" y2="16"></line>
                    </svg>
                  </div>
                  <p className="text-sm">As you move around the map, new Wikipedia articles will appear automatically.</p>
                </div>
              </div>
              <div className="flex gap-4">
                <button
                  className="bg-neutral-400 text-white px-6 py-2 rounded hover:bg-neutral-500 transition-colors"
                  onClick={() => setOnboardingStep(1)}
                >
                  Back
                </button>
                <button
                  className="bg-green-600 text-white px-8 py-2 rounded hover:bg-green-700 transition-colors"
                  onClick={startGameAfterOnboarding}
                >
                  Start Exploring!
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  // Main component render - the return statement must be inside the component function
  return (
    <div className="w-full h-screen flex flex-col">
      <div className="bg-neutral-200 p-6 shadow-sm border-b border-neutral-300">
        <h1 className="text-3xl font-light mb-2 text-center tracking-wide">Wikipedia Walk</h1>

        {/* Menu Screen */}
        {gameState === 'menu' && renderMenu()}

        {/* Game Over Screen */}
        {gameState === 'gameover' && renderGameOver()}
      </div>

      {/* Map container for game over screen */}
      {gameState === 'gameover' && (
        <div className="flex-grow relative">
          <MapContainer
            center={playerPosition || selectedCity.coords}
            zoom={16}
            style={{ height: '100%', width: '100%' }}
            ref={mapRef}
            zoomControl={false}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            <PlayerMarker />

            {wikiArticles.map((article) => (
              <Marker
                key={article.id}
                position={[article.lat, article.lon]}
                icon={wikiIcon}
                opacity={article.visited ? 0.7 : 1}
                className={article.visited ? 'visited-marker' : ''}
              >
                <Popup>
                  <div className="max-w-xs text-neutral-800">
                    <a
                      href={`https://en.wikipedia.org/?curid=${article.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-medium text-lg mb-1 text-blue-700 hover:underline block"
                    >
                      {article.title}
                    </a>
                    <p className="text-sm my-2">{article.summary || 'Loading information...'}</p>
                    {article.pointValue > 0 && (
                      <div className="text-sm text-neutral-600 mt-1">
                        Worth: <span className="font-medium">{article.pointValue} points</span>
                        {article.pageViews > 0 && (
                          <span className="ml-2">({article.pageViews.toLocaleString()} views/month)</span>
                        )}
                      </div>
                    )}
                    {article.visited && (
                      <p className="text-neutral-700 font-medium border-t border-neutral-200 pt-2 mt-2">✓ Discovered</p>
                    )}
                    <a
                      href={`https://en.wikipedia.org/?curid=${article.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-blue-600 text-white px-3 py-1 rounded text-sm inline-block mt-3 hover:bg-blue-700"
                    >
                      View on Wikipedia
                    </a>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      )}

      {/* Onboarding Screen - Modal overlay */}
      {gameState === 'onboarding' && renderOnboarding()}

      {/* Gameplay Screen */}
      {gameState === 'playing' && renderGameplay()}
    </div>
  );
};

export default WikipediaWalkGame;