import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const CrosswordPage = () => {
  const { puzzleId } = useParams();
  const navigate = useNavigate();

  const getPuzzleDetails = (id) => {
    const puzzles = {
      "679a3d70": {
        id: "679a3d70",
        set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
        title: "Snuggles"
      },
      "d780e350": {
        id: "d780e350",
        set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
        title: "Magic Wand"
      },
      "754741ca" :{
      id: "754741ca",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Sunset"
      },
      "9e7fb966" :{
      id: "9e7fb966",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Appetite"
    },
    "2b478e05" :{
      id: "2b478e05",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Masses"
    },
    "45d80eb7" :{
      id: "45d80eb7",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Lapses"
    },
    "031f2986" :{
      id: "031f2986",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Red"
    },
     "4b14bbf4" :{
      id: "4b14bbf4",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Culture"
    },
    "23ce84b5" :{
      id: "23ce84b5",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Rains"
    },
    "12cc190e" :{
      id: "12cc190e",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Again"
    },
    "aa66bff2" :{
      id: "aa66bff2",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Challenge"
    },
    "96c2f426" :{
      id: "96c2f426",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Bits"
    },
    "9ea77a67" :{
      id: "9ea77a67",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Vampire"
    },
    "63cee7c5" :{
      id: "63cee7c5",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "pH levels"
    }
    };
    return puzzles[id];
  };

  const puzzle = getPuzzleDetails(puzzleId);

  if (!puzzle) {
    return (
      <div className="w-full min-h-screen bg-[#F6F5F1]">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6 lg:py-8">
          <h1 className="font-serif text-2xl sm:text-3xl mb-4">Puzzle not found</h1>
          <button
            onClick={() => navigate('/crossword')}
            className="text-xs sm:text-sm uppercase tracking-widest hover:text-gray-600 transition-colors
                       border-b border-black hover:border-gray-600 pb-1"
          >
            ← Return to Crossword
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-[#F6F5F1]">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6 lg:py-8">
        {/* Header */}
        <div className="mb-6 sm:mb-8 flex flex-col items-center border-b-2 border-black pb-4 sm:pb-6 lg:pb-8">
          <button
            onClick={() => navigate('/crossword')}
            className="self-start text-xs sm:text-sm uppercase tracking-widest hover:text-gray-600 transition-colors
                       border-b border-transparent hover:border-gray-600 pb-1 mb-6 sm:mb-8"
          >
            ← Return to Crossword
          </button>

          <h1 className="font-serif text-2xl sm:text-3xl lg:text-4xl mb-2 sm:mb-4 text-center">
            {puzzle.title}
          </h1>
          <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4
                         text-xs sm:text-sm uppercase tracking-widest text-gray-600">


          </div>
        </div>

        {/* Puzzle Frame */}
        <div className="bg-white border border-black/10">
          <iframe
            src={`https://amuselabs.com/pmm/crossword?id=${puzzle.id}&set=${puzzle.set}`}
            className="w-full h-[500px] sm:h-[600px] lg:h-[700px] border-none bg-white"
            title={puzzle.title}
            loading="lazy"
          />
        </div>

        {/* Footer */}
        <div className="mt-6 sm:mt-8 text-center">
          <div className="w-12 sm:w-16 h-px bg-black mx-auto mb-3 sm:mb-4"></div>
          <p className="text-xs uppercase tracking-widest text-gray-600">
            © 2024 Azoodle • Daily Crosswords
          </p>
        </div>
      </div>
    </div>
  );
};

export default CrosswordPage;