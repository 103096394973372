import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, Tooltip } from 'react-leaflet';
import { Icon, divIcon } from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Create custom satellite icons
const satelliteIcon = divIcon({
  html: `<div style="font-size: 24px;">🛰️</div>`,
  className: 'custom-icon',
  iconSize: [30, 30],
  iconAnchor: [15, 15]
});

const userGuessIcon = divIcon({
  html: `<div style="font-size: 24px;">📍</div>`,
  className: 'custom-icon',
  iconSize: [30, 30],
  iconAnchor: [15, 30]
});

// Satellite database - now with default values that will be updated via APIs
const SATELLITES = [
  {
    id: 1,
    name: "International Space Station (ISS)",
    altitude: "408 km",
    velocity: "7.66 km/s",
    orbitType: "LEO",
    lastKnownPosition: { lat: 0, lng: 0 },
    difficulty: "Medium",
    noradId: 25544, // ISS NORAD ID for N2YO API
    description: "The ISS is a modular space station in low Earth orbit. It's a multinational collaborative project involving NASA, Roscosmos, JAXA, ESA, and CSA.",
    apiAvailable: true
  },
  {
    id: 2,
    name: "Hubble Space Telescope",
    altitude: "547 km",
    velocity: "7.5 km/s",
    orbitType: "LEO",
    lastKnownPosition: { lat: 0, lng: 0 },
    difficulty: "Medium",
    noradId: 20580,
    description: "The Hubble Space Telescope is a space telescope that was launched into low Earth orbit in 1990 and remains in operation.",
    apiAvailable: false
  },
  {
    id: 3,
    name: "Tiangong Space Station",
    altitude: "390 km",
    velocity: "7.7 km/s",
    orbitType: "LEO",
    lastKnownPosition: { lat: 0, lng: 0 },
    difficulty: "Medium",
    noradId: 48274,
    description: "China's space station in low Earth orbit. First module launched in 2021, it's designed for long-term habitation and scientific research.",
    apiAvailable: false
  },
  {
    id: 4,
    name: "GOES-16",
    altitude: "35,786 km",
    velocity: "3.07 km/s",
    orbitType: "GEO",
    lastKnownPosition: { lat: 0, lng: -75.2 },
    difficulty: "Easy",
    noradId: 41866,
    description: "GOES-16 is a geostationary operational environmental satellite operated by NOAA, providing continuous monitoring of Earth's Western Hemisphere.",
    apiAvailable: false
  },
  {
    id: 5,
    name: "NOAA-20",
    altitude: "829 km",
    velocity: "7.4 km/s",
    orbitType: "LEO",
    lastKnownPosition: { lat: 0, lng: 0 },
    difficulty: "Medium",
    noradId: 43013,
    description: "NOAA-20 is the first satellite in NOAA's Joint Polar Satellite System series, providing global environmental data for weather forecasting.",
    apiAvailable: false
  },
  {
    id: 6,
    name: "Landsat 9",
    altitude: "705 km",
    velocity: "7.5 km/s",
    orbitType: "LEO",
    lastKnownPosition: { lat: 0, lng: 0 },
    difficulty: "Medium",
    noradId: 49260,
    description: "Landsat 9 is an Earth observation satellite that continues the Landsat program's mission of monitoring Earth's land surface.",
    apiAvailable: false
  },
  {
    id: 7,
    name: "Sentinel-2B",
    altitude: "786 km",
    velocity: "7.4 km/s",
    orbitType: "LEO",
    lastKnownPosition: { lat: 0, lng: 0 },
    difficulty: "Medium",
    noradId: 42063,
    description: "Part of the European Copernicus program, Sentinel-2B provides high-resolution optical imagery for land monitoring.",
    apiAvailable: false
  },
  {
    id: 8,
    name: "GPS IIF-10",
    altitude: "20,200 km",
    velocity: "3.9 km/s",
    orbitType: "MEO",
    lastKnownPosition: { lat: 0, lng: 0 },
    difficulty: "Hard",
    noradId: 40534,
    description: "A satellite in the Global Positioning System constellation, providing navigation signals to users worldwide.",
    apiAvailable: false
  }
];

// Distance calculation using Haversine formula
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  const d = R * c; // Distance in km
  return d;
};

// Fetch satellite position from API
const fetchSatellitePosition = async (noradId, satellite) => {
  try {
    // For ISS, use the ISS-specific API which doesn't require API keys
    if (noradId === 25544) { // ISS NORAD ID
      const response = await fetch('https://api.wheretheiss.at/v1/satellites/25544');
      const data = await response.json();

      if (data && data.latitude && data.longitude) {
        return {
          lat: parseFloat(data.latitude),
          lng: parseFloat(data.longitude)
        };
      }
    }

    // Alternative ISS API if the first one fails
    if (noradId === 25544) {
      const response = await fetch('http://api.open-notify.org/iss-now.json');
      const data = await response.json();

      if (data.message === "success") {
        return {
          lat: parseFloat(data.iss_position.latitude),
          lng: parseFloat(data.iss_position.longitude)
        };
      }
    }

    // For other satellites, we would use N2YO API with an API key in a production environment
    // For this prototype, we'll use simulated positions for all non-ISS satellites
    return simulatePosition(noradId);
  } catch (error) {
    console.error("Error fetching satellite position:", error);
    // Fallback to simulation in case of API failure
    return simulatePosition(noradId);
  }
};

// Fallback simulation for when APIs are unavailable or for non-ISS satellites
const simulatePosition = (noradId) => {
  const satellite = SATELLITES.find(sat => sat.noradId === noradId);

  if (!satellite) return { lat: 0, lng: 0 };

  // For GEO satellites, position remains relatively fixed
  if (satellite.orbitType === "GEO") {
    return {
      lat: Math.random() * 2 - 1, // Slight variation around the equator
      lng: satellite.lastKnownPosition.lng || (Math.random() * 360) // Use last known longitude or random
    };
  }
  // For LEO satellites, position changes more significantly
  else {
    // Create more realistic orbital path simulation
    const time = Date.now() / 10000;
    const orbitRadius = 55; // Degrees from equator
    const orbitPeriod = 90; // Minutes for one orbit (approx 90 mins for ISS)

    // Calculate position based on orbit
    const lat = orbitRadius * Math.sin(time * (2 * Math.PI / orbitPeriod));
    const lng = (time * 360 / orbitPeriod) % 360 - 180;

    return { lat, lng };
  }
};

const MapClickHandler = ({ onMapClick }) => {
  useMapEvents({
    click: (e) => {
      onMapClick(e.latlng);
    }
  });
  return null;
};

const FindSatelliteGame = () => {
  const [satellites, setSatellites] = useState(SATELLITES);
  const [currentSatellite, setCurrentSatellite] = useState(null);
  const [userGuess, setUserGuess] = useState(null);
  const [result, setResult] = useState(null);
  const [gamePhase, setGamePhase] = useState('onboarding'); // 'onboarding', 'selection', 'guessing', 'result'
  const [difficulty, setDifficulty] = useState('all');
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [streak, setStreak] = useState(0);
  const [stats, setStats] = useState({ wins: 0, totalGuesses: 0 });

  // Initialize satellite positions
  useEffect(() => {
    const initializeSatellites = async () => {
      try {
        // Process all satellites in parallel for efficiency
        const updatedSatellites = await Promise.all(
          satellites.map(async (sat) => {
            if (sat.apiAvailable) {
              try {
                const position = await fetchSatellitePosition(sat.noradId, sat);
                return { ...sat, lastKnownPosition: position };
              } catch (error) {
                console.error(`Error fetching position for ${sat.name}:`, error);
                return { ...sat, lastKnownPosition: simulatePosition(sat.noradId) };
              }
            } else {
              return { ...sat, lastKnownPosition: simulatePosition(sat.noradId) };
            }
          })
        );

        setSatellites(updatedSatellites);
      } catch (error) {
        console.error("Error initializing satellites:", error);
      }
    };

    initializeSatellites();
  }, []);

  // Live updates for the current satellite's position
  useEffect(() => {
    if (!currentSatellite || gamePhase !== 'guessing') return;

    // Immediately update the position when a satellite is selected
    const updateCurrentPosition = async () => {
      setLoading(true);
      try {
        const position = await fetchSatellitePosition(currentSatellite.noradId, currentSatellite);
        setCurrentSatellite(prev => ({
          ...prev,
          lastKnownPosition: position
        }));
      } catch (error) {
        console.error("Error updating position:", error);
      } finally {
        setLoading(false);
      }
    };

    updateCurrentPosition();

    // Set up interval for real-time updates
    const interval = setInterval(() => {
      // Don't show loading indicator for automatic updates
      const updateWithoutLoading = async () => {
        try {
          const position = await fetchSatellitePosition(currentSatellite.noradId, currentSatellite);
          setCurrentSatellite(prev => ({
            ...prev,
            lastKnownPosition: position
          }));
        } catch (error) {
          console.error("Error updating position:", error);
        }
      };

      updateWithoutLoading();
    }, 10000); // Update every 10 seconds

    return () => clearInterval(interval);
  }, [gamePhase, currentSatellite?.id]);

  const handleSelectSatellite = async (satellite) => {
    setLoading(true);
    try {
      // Get real-time position before starting the game
      const position = await fetchSatellitePosition(satellite.noradId, satellite);

      setCurrentSatellite({
        ...satellite,
        lastKnownPosition: position
      });

      setGamePhase('guessing');
      setUserGuess(null);
      setResult(null);
    } catch (error) {
      console.error("Error selecting satellite:", error);
      // Fallback to the last known position
      setCurrentSatellite(satellite);
      setGamePhase('guessing');
    } finally {
      setLoading(false);
    }
  };

  const handleMapClick = (latlng) => {
    if (gamePhase !== 'guessing') return;

    setUserGuess(latlng);

    // Calculate distance
    const distance = calculateDistance(
      latlng.lat,
      latlng.lng,
      currentSatellite.lastKnownPosition.lat,
      currentSatellite.lastKnownPosition.lng
    );

    // Convert to miles (1 km = 0.621371 miles)
    const distanceMiles = distance * 0.621371;

    // Win condition: within 100 miles
    const isWin = distanceMiles <= 100;

    // Direction calculation
    const latDiff = currentSatellite.lastKnownPosition.lat - latlng.lat;
    const lngDiff = currentSatellite.lastKnownPosition.lng - latlng.lng;

    let directions = [];
    if (Math.abs(latDiff) > 0.5) {
      directions.push(latDiff > 0 ? "North" : "South");
    }
    if (Math.abs(lngDiff) > 0.5) {
      directions.push(lngDiff > 0 ? "East" : "West");
    }

    const direction = directions.join('-');

    setResult({
      distance: distanceMiles.toFixed(2),
      isWin,
      direction: direction || "Very Close!"
    });

    // Update stats
    setStats(prev => ({
      wins: prev.wins + (isWin ? 1 : 0),
      totalGuesses: prev.totalGuesses + 1
    }));

    // Update streak
    if (isWin) {
      setStreak(prev => prev + 1);
    } else {
      setStreak(0);
    }

    setGamePhase('result');
  };

  const resetGame = () => {
    setGamePhase('selection');
    setCurrentSatellite(null);
    setUserGuess(null);
    setResult(null);
  };

  const tryAgain = async () => {
    setLoading(true);
    try {
      // Get updated position before trying again
      const position = await fetchSatellitePosition(currentSatellite.noradId, currentSatellite);

      setCurrentSatellite(prev => ({
        ...prev,
        lastKnownPosition: position
      }));

      setGamePhase('guessing');
      setUserGuess(null);
      setResult(null);
    } catch (error) {
      console.error("Error updating position:", error);
      // Proceed anyway with the last known position
      setGamePhase('guessing');
      setUserGuess(null);
      setResult(null);
    } finally {
      setLoading(false);
    }
  };

  const nextOnboardingStep = () => {
    if (onboardingStep < 3) {
      setOnboardingStep(prev => prev + 1);
    } else {
      // Finish onboarding
      setGamePhase('selection');
    }
  };

  const skipOnboarding = () => {
    setGamePhase('selection');
  };

  const filteredSatellites = difficulty === 'all'
    ? satellites
    : satellites.filter(sat => sat.difficulty.toLowerCase() === difficulty.toLowerCase());

  // Onboarding steps content
  const onboardingSteps = [
    {
      title: "Welcome to Find the Satellite! 🛰️",
      content: "This game tests your knowledge of satellite positions in real-time. You'll be guessing where satellites are orbiting Earth right now!"
    },
    {
      title: "Select a Satellite",
      content: "Choose from different satellites with varying orbits. The International Space Station (ISS) is a great place to start!"
    },
    {
      title: "Make Your Guess",
      content: "Click on the world map where you think the satellite is located. You win if your guess is within 100 miles of the actual position."
    },
    {
      title: "Track Your Progress",
      content: "See how accurate your guesses are and try to build up a winning streak. Can you master satellite tracking?"
    }
  ];

  return (
    <div className="flex flex-col h-screen max-w-full bg-stone-50">
      {/* Loading overlay */}
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div className="bg-stone-50 p-6 rounded shadow-md border border-stone-200">
            <p className="text-lg font-normal text-stone-800">Tracking satellite...</p>
          </div>
        </div>
      )}

      <header className="bg-stone-800 text-stone-100 p-6">
        <div className="flex justify-between items-center max-w-6xl mx-auto">
          <h1 className="text-2xl font-light tracking-wide">Find the Satellite <span className="opacity-70">🛰️</span></h1>
          {gamePhase !== 'onboarding' && (
            <div className="text-sm font-light tracking-wide">
              <span className="mr-6">Streak: {streak} <span className="opacity-70">✦</span></span>
              <span>Accuracy: {stats.totalGuesses > 0 ? Math.round((stats.wins / stats.totalGuesses) * 100) : 0}%</span>
            </div>
          )}
        </div>
      </header>

      {/* Onboarding */}
      {gamePhase === 'onboarding' && (
        <div className="flex flex-col items-center justify-center flex-grow p-8 bg-stone-100">
          <div className="bg-white border border-stone-200 max-w-lg w-full p-8">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-light tracking-wide text-stone-800">{onboardingSteps[onboardingStep].title}</h2>
              <span className="text-stone-500 text-sm font-light">Step {onboardingStep + 1} of {onboardingSteps.length}</span>
            </div>

            <div className="flex items-center justify-center h-48 bg-stone-50 border border-stone-100 mb-6">
              <span className="text-6xl opacity-60">
                {onboardingStep === 0 ? "🛰️" :
                 onboardingStep === 1 ? "📡" :
                 onboardingStep === 2 ? "🌍" : "✨"}
              </span>
            </div>

            <p className="mb-8 text-stone-700 leading-relaxed">{onboardingSteps[onboardingStep].content}</p>

            <div className="flex justify-between">
              <button
                onClick={skipOnboarding}
                className="px-4 py-2 text-stone-600 hover:text-stone-800 font-light"
              >
                Skip Tutorial
              </button>
              <button
                onClick={nextOnboardingStep}
                className="px-6 py-2 bg-stone-800 text-stone-100 hover:bg-stone-700 border border-stone-800"
              >
                {onboardingStep < 3 ? "Next" : "Start Game"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Main game content */}
      {gamePhase !== 'onboarding' && (
        <main className="flex flex-col md:flex-row flex-grow overflow-hidden">
          {/* Left panel - Game controls */}
          <div className="w-full md:w-1/3 p-6 bg-stone-50 overflow-y-auto border-r border-stone-200">
            {gamePhase === 'selection' && (
              <>
                <h2 className="text-xl font-light tracking-wide text-stone-800 mb-6">Select a Satellite</h2>
                <div className="mb-6">
                  <label className="block mb-2 text-stone-700">Difficulty:</label>
                  <select
                    className="w-full p-2 border border-stone-300 bg-white text-stone-800"
                    value={difficulty}
                    onChange={(e) => setDifficulty(e.target.value)}
                  >
                    <option value="all">All Difficulties</option>
                    <option value="easy">Easy (GEO)</option>
                    <option value="medium">Medium (LEO)</option>
                  </select>
                </div>
                <ul className="space-y-4">
                  {filteredSatellites.map(satellite => (
                    <li
                      key={satellite.id}
                      className="border border-stone-200 p-4 hover:border-stone-400 cursor-pointer transition duration-200"
                      onClick={() => handleSelectSatellite(satellite)}
                    >
                      <div className="flex justify-between items-start">
                        <h3 className="font-normal text-stone-800">{satellite.name}</h3>
                        {satellite.apiAvailable && (
                          <span className="text-xs bg-stone-100 text-stone-600 px-2 py-1 border border-stone-200">
                            Live Data
                          </span>
                        )}
                      </div>
                      <p className="text-xs text-stone-600 mt-2 line-clamp-2">{satellite.description}</p>
                      <div className="text-sm grid grid-cols-2 gap-2 mt-3 text-stone-700">
                        <span>Altitude: {satellite.altitude}</span>
                        <span>Velocity: {satellite.velocity}</span>
                        <span>Orbit: {satellite.orbitType}</span>
                        <span>Difficulty: {satellite.difficulty}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}

            {gamePhase === 'guessing' && currentSatellite && (
              <>
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-xl font-light tracking-wide text-stone-800">Find This Satellite</h2>
                  <button
                    onClick={resetGame}
                    className="px-3 py-1 text-sm border border-stone-300 hover:border-stone-500 text-stone-700"
                  >
                    Select Different
                  </button>
                </div>
                <div className="border border-stone-200 p-5 bg-white mb-6">
                  <h3 className="font-normal text-lg text-stone-800">{currentSatellite.name}</h3>
                  <p className="text-sm text-stone-600 mt-2 leading-relaxed">{currentSatellite.description}</p>
                  <div className="text-sm grid grid-cols-2 gap-3 mt-4 text-stone-700">
                    <span>Altitude: {currentSatellite.altitude}</span>
                    <span>Velocity: {currentSatellite.velocity}</span>
                    <span>Orbit: {currentSatellite.orbitType}</span>
                    <span>Difficulty: {currentSatellite.difficulty}</span>
                  </div>
                </div>
                <div className="bg-stone-100 border border-stone-200 p-5">
                  <h3 className="font-normal text-stone-800">Instructions</h3>
                  <p className="text-sm mt-2 leading-relaxed text-stone-700">Click on the map to guess where this satellite is right now. You win if your guess is within 100 miles of the actual position.</p>
                  <p className="text-xs mt-3 text-stone-500">The satellite's position updates every 10 seconds.</p>
                </div>
              </>
            )}

            {gamePhase === 'result' && result && (
              <>
                <div className="mb-6">
                  <h2 className="text-xl font-light tracking-wide text-stone-800 mb-4">Result</h2>
                  <div className={`p-6 border ${result.isWin ? 'border-stone-300 bg-stone-50' : 'border-stone-300 bg-stone-50'}`}>
                    <h3 className={`text-lg font-normal ${result.isWin ? 'text-stone-800' : 'text-stone-800'}`}>
                      {result.isWin ? '✧ You found the satellite' : '— Your guess missed'}
                    </h3>
                    <p className="mt-3 text-stone-700">Your guess was <span className="font-medium">{result.distance} miles</span> away from the satellite.</p>
                    {!result.isWin && result.direction && (
                      <p className="mt-2 text-stone-700">The satellite is to the <span className="font-medium">{result.direction}</span> of your guess.</p>
                    )}
                    {result.isWin && (
                      <p className="mt-2 text-stone-700">Current streak: <span className="font-medium">{streak}</span> ✦</p>
                    )}
                  </div>

                  <div className="mt-6 bg-stone-100 p-5 border border-stone-200">
                    <h4 className="font-normal text-stone-800">Did you know?</h4>
                    <p className="text-sm mt-2 leading-relaxed text-stone-700">
                      {currentSatellite.name === "International Space Station (ISS)" ?
                        "The ISS travels at about 17,500 mph (28,000 km/h) and completes an orbit around Earth every 90 minutes!" :
                        `${currentSatellite.name} orbits at an altitude of ${currentSatellite.altitude}, which is why it follows the path you see on the map.`
                      }
                    </p>
                  </div>
                </div>
                <div className="flex space-x-4">
                  <button
                    onClick={tryAgain}
                    className="flex-1 py-2 bg-stone-800 text-stone-100 border border-stone-800 hover:bg-stone-700"
                  >
                    Try Again
                  </button>
                  <button
                    onClick={resetGame}
                    className="flex-1 py-2 bg-white text-stone-800 border border-stone-300 hover:border-stone-500"
                  >
                    New Satellite
                  </button>
                </div>
              </>
            )}
          </div>

          {/* Right panel - Map */}
          <div className="w-full md:w-2/3 h-96 md:h-auto relative">
            <MapContainer
              center={[0, 0]}
              zoom={2}
              style={{ height: "100%", width: "100%" }}
              className="border-l border-stone-200"
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />

              <MapClickHandler onMapClick={handleMapClick} />

              {userGuess && (
                <Marker
                  position={[userGuess.lat, userGuess.lng]}
                  icon={userGuessIcon}
                >
                  <Tooltip permanent={gamePhase === 'result'} direction="top" className="font-light">
                    Your guess
                  </Tooltip>
                </Marker>
              )}

              {gamePhase === 'result' && currentSatellite && (
                <Marker
                  position={[
                    currentSatellite.lastKnownPosition.lat,
                    currentSatellite.lastKnownPosition.lng
                  ]}
                  icon={satelliteIcon}
                >
                  <Tooltip permanent direction="top" className="font-light">
                    {currentSatellite.name}
                  </Tooltip>
                </Marker>
              )}
            </MapContainer>
            {gamePhase === 'guessing' && (
              <div className="absolute bottom-4 right-4 bg-white bg-opacity-80 border border-stone-200 p-3 text-sm text-stone-700">
                Click on the map to make your guess
              </div>
            )}
          </div>
        </main>
      )}

      <footer className="bg-stone-100 p-3 text-center text-sm text-stone-600 border-t border-stone-200">
        Find the Satellite | Educational Space Exploration | Using real-time satellite position data
      </footer>
    </div>
  );
};

export default FindSatelliteGame;