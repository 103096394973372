import React, { useState, useEffect } from 'react';
import { AlertCircle, Award, BarChart, Clock, Info, RefreshCw, TrendingUp, HelpCircle } from 'lucide-react';

const ArticlePopularityBattle = () => {
  // Game state
  const [gameStarted, setGameStarted] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [articles, setArticles] = useState([]);
  const [currentPair, setCurrentPair] = useState([]);
  const [score, setScore] = useState(0);
  const [roundsPlayed, setRoundsPlayed] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [onboardingStep, setOnboardingStep] = useState(1);
  const [errorState, setErrorState] = useState(false);
  const [usedArticleTitles, setUsedArticleTitles] = useState([]); // Track used articles

  // Function to get random articles from Wikipedia
  const fetchRandomArticles = async (count = 10) => {
    try {
      setIsLoading(true);

      // Get completely random articles
      const randomUrl = `https://en.wikipedia.org/w/api.php?action=query&list=random&rnnamespace=0&rnlimit=${count}&format=json&origin=*`;

      const randomResponse = await fetch(randomUrl);
      const randomData = await randomResponse.json();

      if (!randomData.query || !randomData.query.random) {
        throw new Error("Failed to get random articles");
      }

      const articles = randomData.query.random;
      const articleTitles = articles.map(article => article.title);

      // Get page info (extracts) for each article
      const extractsUrl = `https://en.wikipedia.org/w/api.php?action=query&prop=extracts&exintro=true&explaintext=true&titles=${encodeURIComponent(articleTitles.join('|'))}&format=json&origin=*`;

      const extractsResponse = await fetch(extractsUrl);
      const extractsData = await extractsResponse.json();

      // Get view counts for each article for last month
      const today = new Date();
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

      const startDate = `${lastMonth.getFullYear()}${String(lastMonth.getMonth() + 1).padStart(2, '0')}${String(lastMonth.getDate()).padStart(2, '0')}`;
      const endDate = `${endOfLastMonth.getFullYear()}${String(endOfLastMonth.getMonth() + 1).padStart(2, '0')}${String(endOfLastMonth.getDate()).padStart(2, '0')}`;

      const articleDataPromises = articleTitles.map(async (title) => {
        try {
          const viewsUrl = `https://wikimedia.org/api/rest_v1/metrics/pageviews/per-article/en.wikipedia/all-access/all-agents/${encodeURIComponent(title.replace(/ /g, '_'))}/monthly/${startDate}/${endDate}`;
          const viewsResponse = await fetch(viewsUrl);
          const viewsData = await viewsResponse.json();

          // Get extract for this specific article
          const pages = extractsData.query.pages;
          let extract = null;

          // Find the correct page in the pages object
          for (const pageId in pages) {
            if (pages[pageId].title === title) {
              extract = pages[pageId].extract || null;
              break;
            }
          }

          // Skip articles with no description
          if (!extract || extract.trim() === "") {
            return null;
          }

          return {
            title: title,
            views: viewsData.items[0]?.views || 0,
            extract: extract.substring(0, 250) + (extract.length > 250 ? '...' : '')
          };
        } catch (error) {
          console.error(`Error fetching data for ${title}:`, error);
          return null;
        }
      });

      const articlesData = (await Promise.all(articleDataPromises)).filter(article => article !== null);

      setArticles(articlesData);
      setIsLoading(false);

      return articlesData;
    } catch (error) {
      console.error("Error fetching Wikipedia articles:", error);
      setIsLoading(false);
      setErrorState(true);
      // Return a few fallback articles in case the API fails
      return [
        {
          title: "United States",
          views: 2475890,
          extract: "The United States of America is a country primarily located in North America. It consists of 50 states, a federal district, five major unincorporated territories, nine Minor Outlying Islands, and 326 Indian reservations."
        },
        {
          title: "World War II",
          views: 1358972,
          extract: "World War II was a global conflict that lasted from 1939 to 1945. The vast majority of the world's countries formed two opposing military alliances: the Allies and the Axis powers."
        }
      ];
    }
  };

  // Initialize with random Wikipedia articles
  useEffect(() => {
    fetchRandomArticles();
  }, []);

  // Complete onboarding
  const completeOnboarding = () => {
    setShowOnboarding(false);
  };

  // Start new game
  const startGame = async () => {
    setScore(0);
    setRoundsPlayed(0);
    setCorrectAnswers(0);
    setGameOver(false);
    setGameStarted(true);
    setUsedArticleTitles([]); // Reset used articles for new game
    await nextRound();
  };

  // Get two random articles
  const getRandomArticlePair = async () => {
    setIsLoading(true);

    try {
      // Use existing articles if we have enough, or fetch new ones
      if (articles.length < 2) {
        const newArticles = await fetchRandomArticles(20); // Get more articles at once
        if (newArticles.length >= 2) {
          // Filter out recently used articles
          const availableArticles = newArticles.filter(article => !usedArticleTitles.includes(article.title));

          // If we have enough fresh articles, use them
          if (availableArticles.length >= 2) {
            const shuffled = [...availableArticles].sort(() => 0.5 - Math.random());
            // Pick the first article
            const firstArticle = shuffled[0];
            // Ensure second article is different from the first one
            const secondArticle = shuffled.slice(1).find(article => article.title !== firstArticle.title);

            if (firstArticle && secondArticle) {
              const selectedPair = [firstArticle, secondArticle];
              setCurrentPair(selectedPair);

              // Add these to our used titles
              setUsedArticleTitles(prev => [...prev, ...selectedPair.map(a => a.title)]);
            } else {
              // Fallback if we somehow couldn't get two different articles
              setCurrentPair([shuffled[0], shuffled[1]]);
              setUsedArticleTitles(prev => [...prev, shuffled[0].title, shuffled[1].title]);
            }
          } else {
            // Not enough fresh articles, use what we have
            const shuffled = [...newArticles].sort(() => 0.5 - Math.random());
            // Ensure we don't have duplicate articles in the pair
            if (shuffled.length >= 2 && shuffled[0].title !== shuffled[1].title) {
              setCurrentPair([shuffled[0], shuffled[1]]);
            } else if (shuffled.length >= 3) {
              // If first two are the same, use the third one
              setCurrentPair([shuffled[0], shuffled[2]]);
            } else {
              // Last resort fallback
              setCurrentPair([shuffled[0], shuffled[shuffled.length - 1]]);
            }
          }
        } else {
          // Fallback in case API fails
          console.error("Could not fetch enough articles");
          setIsLoading(false);
        }
      } else {
        // Filter out recently used articles from our existing pool
        const availableArticles = articles.filter(article => !usedArticleTitles.includes(article.title));

        if (availableArticles.length >= 2) {
          // We have enough unused articles
          const shuffled = [...availableArticles].sort(() => 0.5 - Math.random());
          // Ensure we pick two different articles
          const firstArticle = shuffled[0];
          const secondArticle = shuffled.slice(1).find(article => article.title !== firstArticle.title) || shuffled[1];

          const selectedPair = [firstArticle, secondArticle];
          setCurrentPair(selectedPair);

          // Add these to our used titles
          setUsedArticleTitles(prev => [...prev, ...selectedPair.map(a => a.title)]);
        } else {
          // Not enough unused articles, fetch more
          const newArticles = await fetchRandomArticles(20);
          setArticles(prev => [...prev, ...newArticles]);

          // Try again with new articles
          const allAvailable = [...availableArticles, ...newArticles.filter(article => !usedArticleTitles.includes(article.title))];
          const shuffled = [...allAvailable].sort(() => 0.5 - Math.random());

          // Ensure we pick two different articles
          const firstArticle = shuffled[0];
          const secondArticle = shuffled.slice(1).find(article => article.title !== firstArticle.title) || shuffled[1];

          const selectedPair = [firstArticle, secondArticle];
          setCurrentPair(selectedPair);

          // Add these to our used titles
          setUsedArticleTitles(prev => [...prev, ...selectedPair.map(a => a.title)]);
        }

        // Prefetch next batch if running low
        if (articles.length <= 6) {
          fetchRandomArticles(20);
        }
      }

      // Limit the number of used article titles we track
      // This allows articles to be reused after a certain number of rounds
      if (usedArticleTitles.length > 30) {
        setUsedArticleTitles(prev => prev.slice(prev.length - 20));
      }
    } catch (error) {
      console.error("Error getting article pair:", error);
    }

    setIsLoading(false);
  };

  // Setup next round
  const nextRound = async () => {
    setSelectedArticle(null);
    setShowResult(false);
    await getRandomArticlePair();
    setStartTime(Date.now());
  };

  // Handle player selection
  const handleSelection = (index) => {
    if (selectedArticle !== null || showResult || isLoading) return;

    const responseTime = (Date.now() - startTime) / 1000;
    setSelectedArticle(index);
    setShowResult(true);

    const article1 = currentPair[0];
    const article2 = currentPair[1];
    const correctIndex = article1.views > article2.views ? 0 : 1;

    // Check if answer is correct
    if (index === correctIndex) {
      // Award points
      let pointsEarned = 10;
      if (responseTime <= 3) {
        pointsEarned += 5; // Bonus for fast response
      }

      setScore(prevScore => prevScore + pointsEarned);
      setCorrectAnswers(prev => prev + 1);
    } else {
      // Wrong answer
      setScore(prevScore => Math.max(0, prevScore - 3));
    }

    setRoundsPlayed(prev => prev + 1);
  };

  // Format large numbers
  const formatNumber = (num) => {
    return new Intl.NumberFormat().format(num);
  };

  // Calculate accuracy percentage
  const calculateAccuracy = () => {
    if (roundsPlayed === 0) return 0;
    return ((correctAnswers / roundsPlayed) * 100).toFixed(1);
  };

  // Render onboarding screens
  const renderOnboarding = () => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
        <div className="bg-stone-50 p-8 rounded-lg max-w-lg w-full">
          {onboardingStep === 1 && (
            <>
              <h2 className="text-2xl font-light mb-6 text-stone-800">Welcome to Article Battle</h2>
              <p className="mb-6 text-stone-700">
                This game tests your knowledge of Wikipedia popularity. Can you guess which articles
                people read more?
              </p>
              <button
                className="w-full py-3 bg-stone-800 text-stone-100 rounded-md hover:bg-stone-700 transition"
                onClick={() => setOnboardingStep(2)}
              >
                Next
              </button>
            </>
          )}

          {onboardingStep === 2 && (
            <>
              <h2 className="text-2xl font-light mb-6 text-stone-800">How To Play</h2>
              <div className="space-y-4 mb-6">
                <div className="flex items-start">
                  <div className="bg-stone-200 rounded-full p-2 mr-3">
                    <span className="font-medium">1</span>
                  </div>
                  <p className="text-stone-700">You'll see two random Wikipedia articles side by side</p>
                </div>
                <div className="flex items-start">
                  <div className="bg-stone-200 rounded-full p-2 mr-3">
                    <span className="font-medium">2</span>
                  </div>
                  <p className="text-stone-700">Tap the one you think had more page views</p>
                </div>
                <div className="flex items-start">
                  <div className="bg-stone-200 rounded-full p-2 mr-3">
                    <span className="font-medium">3</span>
                  </div>
                  <p className="text-stone-700">Score points for correct answers (+5 bonus if you're fast!)</p>
                </div>
              </div>
              <div className="flex space-x-3">
                <button
                  className="flex-1 py-3 bg-stone-300 text-stone-800 rounded-md hover:bg-stone-400 transition"
                  onClick={() => setOnboardingStep(1)}
                >
                  Back
                </button>
                <button
                  className="flex-1 py-3 bg-stone-800 text-stone-100 rounded-md hover:bg-stone-700 transition"
                  onClick={() => setOnboardingStep(3)}
                >
                  Next
                </button>
              </div>
            </>
          )}

          {onboardingStep === 3 && (
            <>
              <h2 className="text-2xl font-light mb-6 text-stone-800">Game Rules</h2>
              <div className="space-y-4 mb-6">
                <div className="p-4 border border-stone-200 rounded-lg">
                  <h3 className="font-medium text-stone-800 mb-2">Scoring</h3>
                  <ul className="space-y-2 text-sm text-stone-600">
                    <li className="flex items-center">
                      <span className="w-6 h-6 bg-green-100 rounded-full text-center inline-flex items-center justify-center mr-2 text-green-700">+</span>
                      <span>10 points for each correct answer</span>
                    </li>
                    <li className="flex items-center">
                      <span className="w-6 h-6 bg-green-100 rounded-full text-center inline-flex items-center justify-center mr-2 text-green-700">+</span>
                      <span>5 bonus points if you answer in under 3 seconds</span>
                    </li>
                    <li className="flex items-center">
                      <span className="w-6 h-6 bg-red-100 rounded-full text-center inline-flex items-center justify-center mr-2 text-red-700">-</span>
                      <span>3 points for each wrong answer</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex space-x-3">
                <button
                  className="flex-1 py-3 bg-stone-300 text-stone-800 rounded-md hover:bg-stone-400 transition"
                  onClick={() => setOnboardingStep(2)}
                >
                  Back
                </button>
                <button
                  className="flex-1 py-3 bg-stone-800 text-stone-100 rounded-md hover:bg-stone-700 transition"
                  onClick={completeOnboarding}
                >
                  Start Playing
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  // Render game components
  const renderGameScreen = () => {
    if (!gameStarted) {
      return renderStartScreen();
    }

    if (gameOver) {
      return renderGameOverScreen();
    }

    return (
      <div className="w-full max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-6 flex-wrap gap-2">
          <div className="flex items-center space-x-2 bg-stone-100 px-3 py-2 rounded">
            <BarChart className="w-5 h-5 text-stone-700" />
            <span className="font-light">{score}</span>
          </div>
          <div className="flex items-center space-x-2 bg-stone-100 px-3 py-2 rounded">
            <AlertCircle className="w-5 h-5 text-stone-700" />
            <span className="font-light">{calculateAccuracy()}%</span>
          </div>
        </div>

        <div className="text-center mb-8">
          <h2 className="text-xl font-light text-stone-800">Which article had more views last month?</h2>
        </div>

        {isLoading ? (
          <div className="flex flex-col items-center justify-center py-16">
            <div className="w-12 h-12 border-4 border-stone-200 border-t-stone-800 rounded-full animate-spin mb-4"></div>
            <p className="text-stone-600 font-light">Loading Wikipedia articles...</p>
            <p className="text-xs text-stone-400 mt-1">Fetching live data from Wikipedia</p>
          </div>
        ) : errorState ? (
          <div className="flex flex-col items-center justify-center py-8">
            <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-6 text-center">
              <p className="text-red-600 mb-2">Error loading Wikipedia data</p>
              <p className="text-stone-600 text-sm">Using fallback articles instead</p>
            </div>
            <button
              onClick={() => {setErrorState(false); fetchRandomArticles();}}
              className="px-4 py-2 bg-stone-800 text-stone-100 rounded hover:bg-stone-700 transition"
            >
              Try Again
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {currentPair.map((article, index) => {
              // Inline class generation logic
              const baseClasses = "p-6 rounded-lg border transition-all";
              let classNames;

              if (!showResult) {
                classNames = `${baseClasses} cursor-pointer ${
                  selectedArticle === index ? 'border-stone-800 border-2' : 'border-stone-200'
                } bg-stone-50 hover:bg-stone-100`;
              } else {
                const article1 = currentPair[0];
                const article2 = currentPair[1];
                const correctIndex = article1.views > article2.views ? 0 : 1;

                if (index === correctIndex) {
                  classNames = `${baseClasses} border-green-600 border-2 bg-green-50`;
                } else {
                  classNames = `${baseClasses} ${selectedArticle === index ? 'border-red-500 border-2 bg-red-50' : 'border-stone-200 bg-stone-50'}`;
                }
              }

              return (
                <div
                  key={index}
                  className={classNames}
                  onClick={() => handleSelection(index)}
                >
                  <h3 className="text-lg font-medium mb-3 text-stone-800">{article.title}</h3>
                  <p className="text-sm text-stone-600 mb-4 line-clamp-3">{article.extract}</p>

                  {showResult && (
                    <div className="mt-4 pt-4 border-t border-stone-200">
                      <p className="font-light text-stone-800 mb-2">
                        <span className="font-medium">{formatNumber(article.views)}</span> views
                      </p>
                      <a
                        href={`https://en.wikipedia.org/wiki/${encodeURIComponent(article.title.replace(/ /g, '_'))}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm text-blue-600 hover:underline inline-flex items-center"
                      >
                        View on Wikipedia
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg>
                      </a>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}

        <div className="mt-8 flex justify-center gap-4">
          {showResult && (
            <button
              className="px-6 py-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition flex items-center"
              onClick={nextRound}
              disabled={isLoading}
            >
              <RefreshCw className="w-5 h-5 mr-2" />
              Next Round
            </button>
          )}

          <button
            className="px-4 py-2 bg-stone-800 text-stone-100 rounded hover:bg-stone-700 transition"
            onClick={() => {
              setGameStarted(false);
              setGameOver(false);
            }}
          >
            Quit Game
          </button>
        </div>
      </div>
    );
  };

  // Render start screen
  const renderStartScreen = () => {
    return (
      <div className="w-full max-w-4xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-light text-stone-800 mb-4">Article Popularity Battle</h1>
          <p className="text-stone-600">
            Test your intuition about what articles people read on Wikipedia
          </p>
        </div>

        <div className="bg-stone-50 p-6 rounded-lg border border-stone-200 mb-8">
          <h2 className="text-xl font-medium mb-4 text-stone-800">How to Play</h2>
          <p className="mb-6 text-stone-600 text-sm">
            You'll be shown two random Wikipedia articles. Choose the one you think had more views last month.
            Earn 10 points for each correct answer, plus 5 bonus points if you answer within 3 seconds.
            Incorrect answers will cost you 3 points.
          </p>
          <button
            className="w-full py-3 bg-stone-800 text-stone-100 rounded-md hover:bg-stone-700 transition"
            onClick={() => startGame()}
          >
            Start Game
          </button>
        </div>

        <div className="text-center">
          <button
            className="inline-flex items-center text-sm text-stone-600 hover:text-stone-800 px-3 py-2 border border-stone-200 rounded-md"
            onClick={() => setShowOnboarding(true)}
          >
            <HelpCircle className="w-4 h-4 mr-2" />
            How to Play
          </button>
        </div>
      </div>
    );
  };

  // Render game over screen
  const renderGameOverScreen = () => {
    return (
      <div className="w-full max-w-md mx-auto">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-light text-stone-800 mb-2">Game Complete!</h1>
          <p className="text-stone-600">Here's how you did</p>
        </div>

        <div className="bg-stone-50 p-6 rounded-lg border border-stone-200 mb-8">
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="p-4 bg-stone-100 rounded-lg text-center">
              <BarChart className="w-6 h-6 mx-auto text-stone-800 mb-2" />
              <h3 className="text-sm font-medium text-stone-600">Final Score</h3>
              <p className="text-2xl font-light text-stone-800">{score}</p>
            </div>
            <div className="p-4 bg-stone-100 rounded-lg text-center">
              <AlertCircle className="w-6 h-6 mx-auto text-stone-800 mb-2" />
              <h3 className="text-sm font-medium text-stone-600">Accuracy</h3>
              <p className="text-2xl font-light text-stone-800">{calculateAccuracy()}%</p>
            </div>
          </div>

          <div className="text-center p-3">
            <h3 className="text-sm font-medium text-stone-600">Total Questions</h3>
            <p className="text-xl font-light text-stone-800">{roundsPlayed}</p>
          </div>
        </div>

        <div className="flex flex-col space-y-3">
          <button
            className="py-3 bg-stone-800 text-stone-100 rounded-md hover:bg-stone-700 transition flex items-center justify-center"
            onClick={() => startGame()}
          >
            <RefreshCw className="w-5 h-5 mr-2" />
            Play Again
          </button>

          <button
            className="py-3 bg-stone-200 text-stone-800 rounded-md hover:bg-stone-300 transition"
            onClick={() => {
              setGameStarted(false);
              setGameOver(false);
            }}
          >
            Back to Menu
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-stone-100 px-4 py-8 md:py-12">
      {showOnboarding && renderOnboarding()}
      {renderGameScreen()}
    </div>
  );
};

export default ArticlePopularityBattle;