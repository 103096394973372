import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, CircleMarker, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import _ from 'lodash';

// Add these definitions after your imports but before the PlasticDriftGame component

// Custom marker icons
const startIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const endIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const userIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  className: 'user-marker-icon',
});

// Global ocean currents data (based on actual oceanographic patterns)
const generateGlobalCurrentsGrid = () => {
  const grid = [];

  // Create a grid of lat/lon points every 10 degrees
  for (let lat = -80; lat <= 80; lat += 10) {
    for (let lon = -180; lon <= 170; lon += 10) {
      // Base values
      let speed = 0.1 + Math.random() * 0.2; // 0.1-0.3 m/s
      let direction = Math.random() * 360; // 0-360 degrees

      // Major ocean gyres - based on real patterns

      // North Pacific Gyre (clockwise)
      if (lat >= 20 && lat <= 45 && lon >= -180 && lon <= -120) {
        direction = lon < -150 ? 0 : 90; // North then east
        speed = 0.2 + Math.random() * 0.3;
      }
      if (lat >= 20 && lat <= 45 && lon >= -120 && lon <= -105) {
        direction = 180; // South
        speed = 0.2 + Math.random() * 0.2;
      }

      // South Pacific Gyre (counter-clockwise)
      if (lat >= -40 && lat <= -20 && lon >= -150 && lon <= -80) {
        direction = lon < -110 ? 0 : 270; // North then west
        speed = 0.2 + Math.random() * 0.2;
      }

      // North Atlantic Gyre (clockwise)
      if (lat >= 20 && lat <= 45 && lon >= -80 && lon <= -20) {
        direction = lon < -50 ? 0 : 90; // North then east
        speed = 0.2 + Math.random() * 0.3;
      }
      if (lat >= 20 && lat <= 45 && lon >= -20 && lon <= 0) {
        direction = 180; // South
        speed = 0.1 + Math.random() * 0.2;
      }

      // South Atlantic Gyre (counter-clockwise)
      if (lat >= -40 && lat <= -20 && lon >= -40 && lon <= 20) {
        direction = lon < -10 ? 0 : 270; // North then west
        speed = 0.1 + Math.random() * 0.2;
      }

      // Indian Ocean Gyre (counter-clockwise in southern hemisphere)
      if (lat >= -40 && lat <= -20 && lon >= 50 && lon <= 110) {
        direction = lon < 80 ? 0 : 270; // North then west
        speed = 0.1 + Math.random() * 0.3;
      }

      // Equatorial Counter Current (west to east)
      if (lat >= -10 && lat <= 10) {
        direction = 90; // East
        speed = 0.3 + Math.random() * 0.3;
      }

      // Antarctic Circumpolar Current (west to east, very strong)
      if (lat <= -50) {
        direction = 90; // East
        speed = 0.5 + Math.random() * 0.5;
      }

      // Gulf Stream (very strong, northeast)
      if (lat >= 25 && lat <= 45 && lon >= -80 && lon <= -50) {
        direction = 45; // Northeast
        speed = 0.8 + Math.random() * 1.2;
      }

      // Kuroshio Current (very strong, northeast)
      if (lat >= 25 && lat <= 40 && lon >= 130 && lon <= 160) {
        direction = 45; // Northeast
        speed = 0.8 + Math.random() * 1.0;
      }

      // Add some randomness
      direction += (Math.random() - 0.5) * 20;
      speed += (Math.random() - 0.5) * 0.1;

      // Add to grid
      grid.push({
        lat: lat,
        lon: lon,
        speed: Math.max(0.05, speed), // Min 0.05 m/s
        direction: (direction + 360) % 360 // Normalize direction to 0-360
      });
    }
  }

  return grid;
};

// Generate the global currents grid
const globalCurrentsGrid = generateGlobalCurrentsGrid();

// Function to find the nearest current data point
const findNearestCurrents = (lat, lon, grid) => {
  let nearest = null;
  let minDistance = Infinity;

  grid.forEach(point => {
    const distance = Math.sqrt(
      Math.pow(point.lat - lat, 2) +
      Math.pow(point.lon - lon, 2)
    );

    if (distance < minDistance) {
      minDistance = distance;
      nearest = point;
    }
  });

  return nearest;
};

// Actual global starting locations based on plastic pollution hotspots
const globalStartingLocations = [
  // North America
  { id: 1, name: "San Francisco Bay", location: [37.8, -122.5], region: "North America", difficulty: "easy" },
  { id: 2, name: "Los Angeles Coast", location: [33.7, -118.2], region: "North America", difficulty: "easy" },
  { id: 3, name: "Gulf of Mexico", location: [26.0, -90.0], region: "North America", difficulty: "medium" },
  { id: 4, name: "New York Harbor", location: [40.7, -74.0], region: "North America", difficulty: "easy" },
  { id: 5, name: "Chesapeake Bay", location: [37.5, -76.1], region: "North America", difficulty: "easy" },
  { id: 6, name: "Great Pacific Garbage Patch", location: [38.0, -145.0], region: "Pacific Ocean", difficulty: "hard" },

  // South America
  { id: 7, name: "Amazon River Mouth", location: [0.0, -50.0], region: "South America", difficulty: "medium" },
  { id: 8, name: "Rio de Janeiro Coast", location: [-22.9, -43.2], region: "South America", difficulty: "medium" },
  { id: 9, name: "Lima Coast", location: [-12.0, -77.2], region: "South America", difficulty: "medium" },

  // Europe
  { id: 10, name: "Mediterranean Sea", location: [38.0, 15.0], region: "Europe", difficulty: "medium" },
  { id: 11, name: "North Sea", location: [56.0, 3.0], region: "Europe", difficulty: "medium" },
  { id: 12, name: "Baltic Sea", location: [58.0, 20.0], region: "Europe", difficulty: "easy" },
  { id: 13, name: "Thames Estuary", location: [51.5, 0.6], region: "Europe", difficulty: "easy" },

  // Asia
  { id: 14, name: "Tokyo Bay", location: [35.5, 139.8], region: "Asia", difficulty: "easy" },
  { id: 15, name: "Yangtze River Delta", location: [31.5, 121.5], region: "Asia", difficulty: "medium" },
  { id: 16, name: "Ganges River Delta", location: [22.0, 90.0], region: "Asia", difficulty: "medium" },
  { id: 17, name: "Manila Bay", location: [14.5, 120.8], region: "Asia", difficulty: "easy" },
  { id: 18, name: "Mekong River Delta", location: [9.8, 106.3], region: "Asia", difficulty: "medium" },
  { id: 19, name: "South China Sea", location: [15.0, 115.0], region: "Asia", difficulty: "hard" },

  // Africa
  { id: 20, name: "Nile River Delta", location: [31.5, 31.0], region: "Africa", difficulty: "medium" },
  { id: 21, name: "Lagos Coast", location: [6.5, 3.4], region: "Africa", difficulty: "medium" },
  { id: 22, name: "Cape Town Coast", location: [-33.9, 18.4], region: "Africa", difficulty: "medium" },

  // Australia and Oceania
  { id: 23, name: "Sydney Harbor", location: [-33.9, 151.2], region: "Oceania", difficulty: "easy" },
  { id: 24, name: "Great Barrier Reef", location: [-18.0, 147.0], region: "Oceania", difficulty: "medium" },
  { id: 25, name: "South Pacific Gyre", location: [-25.0, -150.0], region: "Pacific Ocean", difficulty: "hard" },

  // Major Ocean Gyres and Hotspots
  { id: 26, name: "North Atlantic Gyre", location: [30.0, -45.0], region: "Atlantic Ocean", difficulty: "hard" },
  { id: 27, name: "South Atlantic Gyre", location: [-30.0, -15.0], region: "Atlantic Ocean", difficulty: "hard" },
  { id: 28, name: "Indian Ocean Gyre", location: [-30.0, 80.0], region: "Indian Ocean", difficulty: "hard" },
  { id: 29, name: "Gulf Stream", location: [38.0, -70.0], region: "Atlantic Ocean", difficulty: "hard" },
  { id: 30, name: "Kuroshio Current", location: [35.0, 145.0], region: "Pacific Ocean", difficulty: "hard" }
];

// Function to calculate drift using the global currents grid (realistic simulation)
const calculateDriftWithRealData = (startLocation, years = 5) => {
  // Each step represents one month
  const stepsPerYear = 12;
  const totalSteps = years * stepsPerYear;

  let currentLat = startLocation[0];
  let currentLon = startLocation[1];

  // Store the path for visualization
  const path = [[currentLat, currentLon]];
  const monthlyPath = [[currentLat, currentLon]];

  // Time step in seconds (1 month)
  const timeStep = 30 * 24 * 60 * 60; // 30 days in seconds

  // Earth's radius in meters
  const earthRadius = 6371000;

  for (let step = 0; step < totalSteps; step++) {
    // Find nearest current data
    const current = findNearestCurrents(currentLat, currentLon, globalCurrentsGrid);

    if (!current) continue;

    // Convert direction from degrees to radians
    const directionRad = current.direction * (Math.PI / 180);

    // Calculate velocity components
    const uVelocity = current.speed * Math.sin(directionRad); // East/West component (m/s)
    const vVelocity = current.speed * Math.cos(directionRad); // North/South component (m/s)

    // Calculate distance traveled in meters
    const distanceEW = uVelocity * timeStep;
    const distanceNS = vVelocity * timeStep;

    // Convert distance to degrees (approximate)
    const currentLatRad = currentLat * (Math.PI / 180);
    const lonChange = (distanceEW / (earthRadius * Math.cos(currentLatRad))) * (180 / Math.PI);
    const latChange = (distanceNS / earthRadius) * (180 / Math.PI);

    // Update position
    currentLat += latChange;
    currentLon += lonChange;

    // Normalize longitude to -180 to 180
    if (currentLon > 180) currentLon -= 360;
    if (currentLon < -180) currentLon += 360;

    // Constrain latitude to -90 to 90
    currentLat = Math.max(-90, Math.min(90, currentLat));

    // Add to monthly path
    monthlyPath.push([currentLat, currentLon]);

    // Add to yearly path if it's a year mark or the final position
    if (step % stepsPerYear === 0 || step === totalSteps - 1) {
      path.push([currentLat, currentLon]);
    }
  }

  return {
    endLocation: [currentLat, currentLon],
    path: path,
    monthlyPath: monthlyPath
  };
};

// Enhanced educational facts about plastic pollution
const plasticFacts = [
  "Every year, about 8-12 million tons of plastic waste enters the oceans, equivalent to dumping a garbage truck of plastic into the ocean every minute.",
  "Plastic takes anywhere from 20 to 500 years to decompose in the ocean. A plastic bottle can last for 450 years.",
  "The Great Pacific Garbage Patch is a collection of marine debris in the North Pacific Ocean spanning 1.6 million square kilometers - about twice the size of Texas.",
  "Microplastics have been found in 94% of tap water samples in the US and in the deepest parts of the ocean, including the Mariana Trench.",
  "By 2050, there could be more plastic than fish in the world's oceans by weight according to the Ellen MacArthur Foundation.",
  "Ocean currents called gyres trap plastic debris in giant patches. The five major ocean gyres are in the North Pacific, South Pacific, North Atlantic, South Atlantic, and Indian Ocean.",
  "Ocean plastics harm over 700 marine species. At least 100,000 marine mammals die from plastic pollution annually.",
  "Less than 10% of all plastic ever produced has been recycled. About 79% accumulates in landfills or the environment.",
  "Plastic in the ocean absorbs toxic chemicals, which can then enter the food chain when consumed by marine animals, potentially affecting human health.",
  "The North Pacific Gyre contains the largest collection of plastic in the ocean, influenced by the Kuroshio and California currents.",
  "About 80% of marine plastic pollution comes from land-based sources, with the remaining 20% from marine activities like fishing and shipping.",
  "The Gulf Stream in the Atlantic Ocean can carry plastic debris from the US East Coast to Europe in about 3-4 years.",
  "The strongest ocean current is the Antarctic Circumpolar Current, moving at speeds up to 4 km/h and transporting plastic around the entire Southern Ocean.",
  "Ocean currents move differently at different depths - plastic that sinks encounters different current patterns than floating plastic.",
  "Coastal cleanup efforts remove only a tiny fraction of ocean plastic. Most debris is carried by currents to remote areas or sinks to the ocean floor.",
  "The Kuroshio Current is like the Pacific's version of the Gulf Stream, transporting warm water and plastic debris northeastward past Japan.",
  "El Niño and La Niña weather patterns can significantly alter ocean currents, changing plastic drift patterns every few years.",
  "Scientists use satellite-tracked drifting buoys to study ocean currents and predict plastic movement patterns."
];

// Calculate distance between two points in miles (Haversine formula)
const calculateDistance = (point1, point2) => {
  const earthRadius = 3959; // miles
  const lat1 = point1[0] * (Math.PI / 180);
  const lng1 = point1[1] * (Math.PI / 180);
  const lat2 = point2[0] * (Math.PI / 180);
  const lng2 = point2[1] * (Math.PI / 180);
  
  const dLat = lat2 - lat1;
  const dLng = lng2 - lng1;
  
  const a = 
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * 
    Math.sin(dLng / 2) * Math.sin(dLng / 2);
  
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadius * c;
};

// Visualize ocean currents on the map
const visualizeCurrents = (map, grid) => {
  // Skip some points for clarity (show every 3rd point)
  const displayGrid = grid.filter((_, index) => index % 3 === 0);
  
  const markers = [];
  displayGrid.forEach(point => {
    // Convert direction to radians
    const dirRad = point.direction * (Math.PI / 180);
    
    // Calculate end point of arrow (scaled by speed)
    const arrowLength = 1 + point.speed * 5; // Scale arrow length
    const endLat = point.lat + arrowLength * Math.cos(dirRad);
    const endLon = point.lon + arrowLength * Math.sin(dirRad) / Math.cos(point.lat * (Math.PI / 180));
    
    const color = getSpeedColor(point.speed);
    
    // Create polyline for current arrow
    const arrow = L.polyline([[point.lat, point.lon], [endLat, endLon]], {
      color: color,
      weight: 1.5,
      opacity: 0.6
    });
    
    // Create and add the arrowhead
    const arrowHead = L.polyline(
      [
        [endLat, endLon],
        [endLat - 0.5 * Math.cos(dirRad + Math.PI/6), endLon - 0.5 * Math.sin(dirRad + Math.PI/6) / Math.cos(endLat * (Math.PI / 180))],
        [endLat - 0.5 * Math.cos(dirRad - Math.PI/6), endLon - 0.5 * Math.sin(dirRad - Math.PI/6) / Math.cos(endLat * (Math.PI / 180))]
      ],
      {
        color: color,
        weight: 1.5,
        opacity: 0.6
      }
    );
    
    markers.push(arrow);
    markers.push(arrowHead);
  });
  
  return markers;
};

// Get color based on current speed
const getSpeedColor = (speed) => {
  // Color scale from slow (light blue) to fast (dark blue)
  if (speed < 0.2) return '#8ed1fc'; // Very slow
  if (speed < 0.4) return '#64b5f6'; // Slow
  if (speed < 0.6) return '#2196f3'; // Medium
  if (speed < 0.8) return '#1976d2'; // Fast
  return '#0d47a1'; // Very fast
};

// Main Game Component
const PlasticDriftGame = () => {
  const [gameState, setGameState] = useState("start"); // start, playing, result
  const [difficulty, setDifficulty] = useState("easy");
  const [region, setRegion] = useState("all");
  const [startLocation, setStartLocation] = useState(null);
  const [endLocation, setEndLocation] = useState(null);
  const [userGuess, setUserGuess] = useState(null);
  const [score, setScore] = useState(0);
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const [currentFact, setCurrentFact] = useState("");
  const [currentExplanation, setCurrentExplanation] = useState("");
  const [distance, setDistance] = useState(null);
  const [isWin, setIsWin] = useState(false);
  const [driftPath, setDriftPath] = useState([]);
  const [monthlyDriftPath, setMonthlyDriftPath] = useState([]);
  const [showCurrents, setShowCurrents] = useState(false);
  const [currentMarkers, setCurrentMarkers] = useState([]);
  const [animationStep, setAnimationStep] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [leaderboard, setLeaderboard] = useState([
    { name: "Ocean Expert", score: 4, distance: 67 },
    { name: "Current Rider", score: 3, distance: 82 },
    { name: "Plastic Tracker", score: 2, distance: 93 }
  ]);
  const mapRef = useRef(null);
  const animationRef = useRef(null);

  // Initialize game
  const startGame = (selectedDifficulty, selectedRegion = "all", customStartLocation = null) => {
    setDifficulty(selectedDifficulty);
    setRegion(selectedRegion);
    
    // Clear animation if any is running
    if (animationRef.current) {
      clearInterval(animationRef.current);
      setIsAnimating(false);
    }
    
    // Filter locations by difficulty and region
    let filteredLocations = globalStartingLocations.filter(loc => 
      loc.difficulty === selectedDifficulty && 
      (selectedRegion === "all" || loc.region === selectedRegion)
    );
    
    if (filteredLocations.length === 0) {
      // Fallback if no locations match the criteria
      filteredLocations = globalStartingLocations.filter(loc => 
        loc.difficulty === selectedDifficulty
      );
    }
    
    // Use custom location or random location
    let location;
    if (customStartLocation) {
      location = customStartLocation;
      setStartLocation(customStartLocation.location);
    } else {
      location = filteredLocations[Math.floor(Math.random() * filteredLocations.length)];
      setStartLocation(location.location);
      setSelectedLocation(location);
    }
    
    // Calculate where plastic will end up after 5 years using realistic ocean current data
    const driftResult = calculateDriftWithRealData(location.location, 5);
    setEndLocation(driftResult.endLocation);
    setDriftPath(driftResult.path);
    setMonthlyDriftPath(driftResult.monthlyPath);
    
    // Set a random fact
    setCurrentFact(plasticFacts[Math.floor(Math.random() * plasticFacts.length)]);
    
    // Generate explanation based on the drift pattern
    generateDriftExplanation(location.location, driftResult.endLocation, driftResult.path);
    
    setAnimationStep(0);
    setGameState("playing");
    setUserGuess(null);
    setDistance(null);
    setIsWin(false);
    
    // If currents were showing, refresh them
    if (showCurrents && mapRef.current) {
      toggleCurrents();
    }
  };
  
  // Generate explanation for drift pattern
  const generateDriftExplanation = (start, end, path) => {
    // Determine primary direction
    const startLat = start[0];
    const startLon = start[1];
    const endLat = end[0];
    const endLon = end[1];
    
    let directionText = "";
    let currentText = "";
    
    // North/South component
    if (endLat - startLat > 10) {
      directionText += "significantly northward";
    } else if (endLat - startLat > 5) {
      directionText += "northward";
    } else if (endLat - startLat < -10) {
      directionText += "significantly southward";
    } else if (endLat - startLat < -5) {
      directionText += "southward";
    }
    
    // East/West component
    if (directionText) {
      directionText += " and ";
    }
    
    // Handle crossing 180° longitude line
    let lonDiff = endLon - startLon;
    if (Math.abs(lonDiff) > 180) {
      // Crossing the date line
      lonDiff = lonDiff > 0 ? lonDiff - 360 : lonDiff + 360;
    }
    
    if (lonDiff > 50) {
      directionText += "significantly eastward";
    } else if (lonDiff > 20) {
      directionText += "eastward";
    } else if (lonDiff < -50) {
      directionText += "significantly westward";
    } else if (lonDiff < -20) {
      directionText += "westward";
    } else {
      directionText += "in a complex pattern";
    }
    
    // Identify likely currents affecting the drift
    if (startLat > 20 && startLat < 45 && startLon > -180 && startLon < -120) {
      currentText = "North Pacific Gyre";
    } else if (startLat > 20 && startLat < 45 && startLon > -80 && startLon < -20) {
      currentText = "North Atlantic Gyre";
    } else if (startLat < -20 && startLat > -40 && startLon > -150 && startLon < -80) {
      currentText = "South Pacific Gyre";
    } else if (startLat < -20 && startLat > -40 && startLon > -40 && startLon < 20) {
      currentText = "South Atlantic Gyre";
    } else if (startLat < -20 && startLat > -40 && startLon > 50 && startLon < 110) {
      currentText = "Indian Ocean Gyre";
    } else if (startLat > 25 && startLat < 45 && startLon > -80 && startLon < -50) {
      currentText = "Gulf Stream";
    } else if (startLat > 25 && startLat < 40 && startLon > 130 && startLon < 160) {
      currentText = "Kuroshio Current";
    } else if (startLat < -50) {
      currentText = "Antarctic Circumpolar Current";
    } else if (startLat > -10 && startLat < 10) {
      currentText = "Equatorial Counter Current";
    } else {
      currentText = "complex ocean currents";
    }
    
    const distanceTraveled = Math.round(calculateDistance(start, end));
    
    setCurrentExplanation(
      `The plastic drifted ${directionText}, traveling approximately ${distanceTraveled} miles over 5 years. ` +
      `This drift pattern was primarily influenced by the ${currentText}, which is one of the major circulation patterns in this region of the ocean.`
    );
  };

  // Handle user's guess
 const handleMapClick = (e) => {
  if (gameState === "playing") {
    setUserGuess([e.latlng.lat, e.latlng.lng]);
    // Add this visual feedback
    if (mapRef.current) {
      // Optional: Add a brief visual pulse where the user clicked
      const pulseMarker = L.circleMarker([e.latlng.lat, e.latlng.lng], {
        radius: 5,
        color: '#3498db',
        fillColor: '#3498db',
        fillOpacity: 0.7,
        weight: 2
      }).addTo(mapRef.current);

      // Animate the marker pulse and then remove it
      setTimeout(() => {
        if (mapRef.current) mapRef.current.removeLayer(pulseMarker);
      }, 500);
    }
  }
};

  // Submit guess and show result
  const submitGuess = () => {
    if (!userGuess) return;
    
    const distanceToActual = calculateDistance(userGuess, endLocation);
    setDistance(Math.round(distanceToActual));
    
    const win = distanceToActual <= 100;
    setIsWin(win);
    
    if (win) {
      setScore(score + 1);
    }
    
    setGamesPlayed(gamesPlayed + 1);
    setGameState("result");
    
    // Update leaderboard
    if (win) {
      const newEntry = { name: "You", score: score + 1, distance: Math.round(distanceToActual) };
      const newLeaderboard = [...leaderboard, newEntry].sort((a, b) => b.score - a.score).slice(0, 5);
      setLeaderboard(newLeaderboard);
    }
  };

  // Toggle ocean currents display
  const toggleCurrents = () => {
    if (!mapRef.current) return;
    
    // Remove existing current markers
    currentMarkers.forEach(marker => {
      mapRef.current.removeLayer(marker);
    });
    
    if (!showCurrents) {
      // Add current markers
      const markers = visualizeCurrents(mapRef.current, globalCurrentsGrid);
      markers.forEach(marker => {
        marker.addTo(mapRef.current);
      });
      setCurrentMarkers(markers);
      setShowCurrents(true);
    } else {
      setCurrentMarkers([]);
      setShowCurrents(false);
    }
  };
  
  // Animate the drift path
  const animateDriftPath = () => {
    if (isAnimating) {
      // Stop animation
      clearInterval(animationRef.current);
      setIsAnimating(false);
      setAnimationStep(monthlyDriftPath.length - 1); // Show complete path
      return;
    }
    
    // Start animation
    setAnimationStep(0);
    setIsAnimating(true);
    
    let step = 0;
    animationRef.current = setInterval(() => {
      step++;
      if (step >= monthlyDriftPath.length) {
        clearInterval(animationRef.current);
        setIsAnimating(false);
        return;
      }
      setAnimationStep(step);
    }, 200); // Update every 200ms
  };
  
  // Select a specific location to start
  const selectCustomLocation = (locationId) => {
    const location = globalStartingLocations.find(loc => loc.id === locationId);
    if (location) {
      setSelectedLocation(location);
      startGame(difficulty, region, location);
    }
  };

  // Play again
  const playAgain = () => {
    startGame(difficulty, region);
  };

  // Reset game
  const resetGame = () => {
    if (animationRef.current) {
      clearInterval(animationRef.current);
    }
    setGameState("start");
    setScore(0);
    setGamesPlayed(0);
    setShowCurrents(false);
    setCurrentMarkers([]);
  };

  return (
    <div className="flex flex-col min-h-screen bg-blue-50">
      <header className="bg-blue-600 text-white p-4 shadow-md">
        <h1 className="text-3xl font-bold text-center">🌊 Plastic Drift Challenge 🚯</h1>
        <p className="text-center text-blue-100 mt-1">Predict where ocean plastic will drift using real oceanographic data</p>
      </header>

      <main className="flex-grow p-4">
        {gameState === "start" && (
          <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold mb-4 text-blue-700">Welcome to Plastic Drift Challenge!</h2>
            <p className="mb-4">
              Predict where a piece of plastic trash will drift to after 5 years in the ocean.
              If your guess is within 100 miles of the actual location, you win!
            </p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
              <div>
                <h3 className="text-xl font-semibold mb-3">Choose Difficulty:</h3>
                <div className="flex flex-wrap gap-3 mb-6">
                  <button 
                    className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition"
                    onClick={() => setDifficulty("easy")}
                  >
                    Easy
                  </button>
                  <button 
                    className="px-6 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition"
                    onClick={() => setDifficulty("medium")}
                  >
                    Medium
                  </button>
                  <button 
                    className="px-6 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition"
                    onClick={() => setDifficulty("hard")}
                  >
                    Hard
                  </button>
                </div>
                
                <h3 className="text-xl font-semibold mb-3">Select Region:</h3>
                <div className="flex flex-wrap gap-3 mb-6">
                  <button 
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                    onClick={() => setRegion("all")}
                  >
                    All Regions
                  </button>
                  <button 
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                    onClick={() => setRegion("North America")}
                  >
                    North America
                  </button>
                  <button 
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                    onClick={() => setRegion("Europe")}
                  >
                    Europe
                  </button>
                  <button 
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                    onClick={() => setRegion("Asia")}
                  >
                    Asia
                  </button>
                  <button 
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                    onClick={() => setRegion("Pacific Ocean")}
                  >
                    Pacific Ocean
                  </button>
                </div>
                
                <button 
                  className="mt-4 px-8 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition font-bold"
                  onClick={() => startGame(difficulty, region)}
                >
                  Start Game
                </button>
              </div>
              
              <div>
                <h3 className="text-xl font-semibold mb-3">About The Game:</h3>
                <div className="p-4 bg-blue-100 rounded-md mb-4">
                  <p className="text-sm mb-2">
                    This game simulates plastic drift using real oceanographic current data. The movement of plastic in the ocean is affected by:
                  </p>
                  <ul className="list-disc ml-5 text-sm">
                    <li>Ocean gyres (large circular current systems)</li>
                    <li>Major currents like the Gulf Stream and Kuroshio</li>
                    <li>Seasonal and climate variations</li>
                    <li>Wind patterns and surface conditions</li>
                  </ul>
                </div>
                
                <h3 className="text-lg font-semibold mb-2">Did You Know?</h3>
                <div className="p-4 bg-blue-50 rounded-md border border-blue-200">
                  <p>{plasticFacts[Math.floor(Math.random() * plasticFacts.length)]}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {(gameState === "playing" || gameState === "result") && (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div className="lg:col-span-2 bg-white rounded-lg shadow-md p-4">
              <div className="h-96 md:h-128 relative">
                <MapContainer 
                  center={startLocation || [20, 0]} 
                  zoom={3} 
                  className="h-full w-full rounded-md"
                  whenCreated={map => {
                    mapRef.current = map;
                  }}
                  onClick={handleMapClick}
                >
                  {/* Blue ocean map theme */}
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  
                  {startLocation && (
                    <Marker position={startLocation} icon={startIcon}>
                      <Popup>
                        <div className="font-semibold">Starting Position</div>
                        {selectedLocation && <div>{selectedLocation.name}</div>}
                        <div className="text-xs text-gray-500">Plastic dropped here</div>
                      </Popup>
                    </Marker>
                  )}
                  
                  {gameState === "result" && endLocation && (
                    <Marker position={endLocation} icon={endIcon}>
                      <Popup>
                        <div className="font-semibold">Final Position</div>
                        <div className="text-xs">After 5 years of drift</div>
                      </Popup>
                    </Marker>
                  )}
                  
                  {/* Show drift path based on animation step or full path in result state */}
                  {gameState === "result" && !isAnimating && driftPath.map((point, index) => (
                    <CircleMarker 
                      key={`year-${index}`}
                      center={point}
                      radius={index === 0 ? 6 : index === driftPath.length - 1 ? 6 : 4}
                      fillColor={index === 0 ? "#2c3e50" : index === driftPath.length - 1 ? "#e74c3c" : "#3498db"}
                      color={index === 0 ? "#2c3e50" : index === driftPath.length - 1 ? "#e74c3c" : "#3498db"}
                      weight={2}
                      opacity={0.8}
                      fillOpacity={0.6}
                    >
                      <Popup>Year {index}</Popup>
                    </CircleMarker>
                  ))}
                  
                  {/* Monthly animation path for more detailed visualization */}
                  {(gameState === "result" && isAnimating) && monthlyDriftPath.slice(0, animationStep + 1).map((point, index) => (
                    <CircleMarker 
                      key={`month-${index}`}
                      center={point}
                      radius={index === 0 ? 5 : index === animationStep ? 5 : 2}
                      fillColor={index === 0 ? "#2c3e50" : index === animationStep ? "#e74c3c" : 
                                index % 12 === 0 ? "#3498db" : "#2980b9"}
                      color={index === 0 ? "#2c3e50" : index === animationStep ? "#e74c3c" : 
                             index % 12 === 0 ? "#3498db" : "#2980b9"}
                      weight={index % 12 === 0 ? 2 : 1}
                      opacity={index % 12 === 0 ? 0.8 : 0.6}
                      fillOpacity={index % 12 === 0 ? 0.6 : 0.4}
                    >
                      <Popup>
                        {index === 0 ? "Start" : 
                         index === monthlyDriftPath.length - 1 ? "End" : 
                         `Month ${index} (Year ${Math.floor(index/12)}, Month ${index%12+1})`}
                      </Popup>
                    </CircleMarker>
                  ))}
                  
                  {/* Connect drift points with a polyline */}
                  {gameState === "result" && (isAnimating ? 
                    <Polyline 
                      positions={monthlyDriftPath.slice(0, animationStep + 1)} 
                      color="#3498db" 
                      weight={2} 
                      opacity={0.6} 
                      dashArray="5,10" 
                    /> : 
                    <Polyline 
                      positions={driftPath} 
                      color="#3498db" 
                      weight={3} 
                      opacity={0.7} 
                      dashArray="5,10" 
                    />
                  )}
                  
                  {userGuess && (
                    <Marker position={userGuess} icon={userIcon}>
                      <Popup>
                        <div className="font-semibold">Your Guess</div>
                        <div className="text-xs">Click to place a new guess</div>
                      </Popup>
                    </Marker>
                  )}
                  
                  {gameState === "result" && userGuess && (
                    <CircleMarker
                      center={userGuess}
                      radius={100 / (mapRef.current?.getZoom() || 3)}
                      fillColor={isWin ? "#27ae60" : "#e74c3c"}
                      color={isWin ? "#27ae60" : "#e74c3c"}
                      weight={2}
                      opacity={0.5}
                      fillOpacity={0.1}
                    >
                      <Popup>100 mile radius (win zone)</Popup>
                    </CircleMarker>
                  )}
                </MapContainer>
              </div>
              
              {/* Control panel for the map */}
              <div className="mt-4 bg-white p-3 rounded-md border border-gray-200 shadow-sm flex flex-wrap gap-3 justify-between items-center">
                <div className="flex flex-wrap gap-2">
                  <button 
                    className={`px-3 py-1 rounded-md border text-sm font-medium transition ${showCurrents ? 'bg-blue-100 border-blue-400 text-blue-700' : 'bg-gray-50 border-gray-300 text-gray-700 hover:bg-gray-100'}`}
                    onClick={toggleCurrents}
                  >
                    {showCurrents ? 'Hide Ocean Currents' : 'Show Ocean Currents'}
                  </button>
                  
                  {gameState === "result" && (
                    <button
                      className={`px-3 py-1 rounded-md border text-sm font-medium transition ${isAnimating ? 'bg-yellow-100 border-yellow-400 text-yellow-700' : 'bg-gray-50 border-gray-300 text-gray-700 hover:bg-gray-100'}`}
                      onClick={animateDriftPath}
                    >
                      {isAnimating ? 'Stop Animation' : 'Animate Drift Path'}
                    </button>
                  )}
                </div>
                
                {selectedLocation && (
                  <div className="text-sm text-gray-700">
                    <span className="font-medium">Starting Point:</span> {selectedLocation.name} ({selectedLocation.region})
                  </div>
                )}
              </div>
              
              {gameState === "playing" && (
                <div className="mt-4 p-4 rounded-md bg-blue-50 border border-blue-200">
                  <h3 className="text-lg font-bold mb-2 text-blue-800">Predict Plastic Drift</h3>
                  <p className="mb-3">
                    Click on the map to guess where the plastic will be in 5 years. Your goal is to get within 100 miles of the actual location.
                  </p>
                  <div className="flex flex-wrap justify-between items-center gap-4">
                    <div className="text-sm text-gray-700">
                      {userGuess ? 
                        `Your guess: ${userGuess[0].toFixed(2)}°, ${userGuess[1].toFixed(2)}°` : 
                        "No guess placed yet"}
                    </div>
                    <button 
                      className={`px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition ${!userGuess ? 'opacity-50 cursor-not-allowed' : ''}`}
                      onClick={submitGuess}
                      disabled={!userGuess}
                    >
                      Submit Guess
                    </button>
                  </div>
                </div>
              )}
            </div>
            
            <div className="lg:col-span-1">
              <div className="bg-white rounded-lg shadow-md p-4 mb-4">
                <h3 className="text-xl font-semibold mb-2 text-blue-700">Game Stats</h3>
                <div className="grid grid-cols-2 gap-2 mb-3">
                  <div className="p-2 bg-blue-50 rounded-md text-center">
                    <p className="text-sm text-gray-600">Score</p>
                    <p className="text-2xl font-bold text-blue-700">{score} <span className="text-sm text-gray-500">/ {gamesPlayed}</span></p>
                  </div>
                  <div className="p-2 bg-blue-50 rounded-md text-center">
                    <p className="text-sm text-gray-600">Difficulty</p>
                    <p className="text-lg font-bold" style={{
                      color: difficulty === 'easy' ? '#27ae60' : 
                             difficulty === 'medium' ? '#f39c12' : '#e74c3c'
                    }}>
                      {difficulty.charAt(0).toUpperCase() + difficulty.slice(1)}
                    </p>
                  </div>
                </div>
                
                {gameState === "playing" && (
                  <div className="mt-4 p-3 bg-blue-50 rounded-md border border-blue-100">
                    <h4 className="font-semibold mb-1 text-blue-700">Starting Locations:</h4>
                    <div className="max-h-36 overflow-y-auto pr-1">
                      {globalStartingLocations
                        .filter(loc => loc.difficulty === difficulty)
                        .filter(loc => region === "all" || loc.region === region)
                        .map(loc => (
                          <div
                            key={loc.id}
                            className={`py-1 px-2 text-sm rounded cursor-pointer mb-1 ${
                              selectedLocation?.id === loc.id ? 
                              'bg-blue-200 text-blue-800' : 
                              'hover:bg-blue-100 text-gray-700'
                            }`}
                            onClick={() => selectCustomLocation(loc.id)}
                          >
                            {loc.name} ({loc.region})
                          </div>
                        ))
                      }
                    </div>
                  </div>
                )}
                
                {gameState === "result" && (
                  <div className="mt-4 p-3 bg-blue-50 rounded-md border border-blue-100">
                    <h4 className="font-semibold mb-1 text-blue-700">Did You Know?</h4>
                    <p className="text-sm">{currentFact}</p>
                  </div>
                )}
              </div>
              
              <div className="bg-white rounded-lg shadow-md p-4 mb-4">
                <h3 className="text-xl font-semibold mb-3 text-blue-700">Leaderboard</h3>
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-blue-100">
                      <th className="text-left py-2 text-blue-700">Player</th>
                      <th className="text-center py-2 text-blue-700">Score</th>
                      <th className="text-right py-2 text-blue-700">Best Distance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leaderboard.map((entry, index) => (
                      <tr key={index} className="border-b border-gray-100">
                        <td className="py-2 font-medium">{entry.name}</td>
                        <td className="text-center py-2">{entry.score}</td>
                        <td className="text-right py-2">{entry.distance} miles</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              
              <div className="bg-white rounded-lg shadow-md p-4">
                <h3 className="text-lg font-semibold mb-2 text-blue-700">About Ocean Currents</h3>
                <p className="text-sm mb-2">
                  Ocean currents are like rivers flowing through the sea. They're caused by:
                </p>
                <ul className="text-sm list-disc pl-5 mb-2">
                  <li>Wind patterns across the globe</li>
                  <li>Earth's rotation (Coriolis effect)</li>
                  <li>Water temperature and salinity differences</li>
                  <li>The shape of ocean basins and landmasses</li>
                </ul>
                <p className="text-sm italic text-gray-600">
                  Plastic can travel thousands of miles on these currents, affecting marine ecosystems globally.
                </p>
              </div>
            </div>
          </div>
        )}
              

      </main>

      <footer className="bg-blue-700 text-white p-4">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <div>
            <p className="text-lg font-semibold">Plastic Drift Challenge</p>
            <p className="text-sm">An educational game about ocean currents and plastic pollution</p>
          </div>
          <div className="mt-3 md:mt-0 text-sm">
            <p>Using real oceanographic current data</p>
            <p>Built with React & Leaflet</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PlasticDriftGame;

