// WikiWordFrequencyGame.js
import React, { useState, useEffect, useRef } from 'react';

// Main component
const WikiWordFrequencyGame = () => {
  // Game state
  const [gameState, setState] = useState({
    isLoading: true,
    article: null,
    wordBank: [],
    guesses: [
      [null, null, null, null, null],
      [null, null, null, null, null],
      [null, null, null, null, null]
    ],
    results: [null, null, null],
    currentGuess: 0,
    gameOver: false,
    isWinner: false
  });

  // UI state
  const [showFullArticle, setShowFullArticle] = useState(false);
  const [showDescription, setShowDescription] = useState(true);
  const [selectedDate, setSelectedDate] = useState(getTodayDate());
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [draggedWord, setDraggedWord] = useState(null);

  // Get today's date in YYYY-MM-DD format
  function getTodayDate() {
    const date = new Date();
    return date.toISOString().slice(0, 10);
  }

  // Format date for display
  function formatDisplayDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  }

  // Initialize game on load and when date changes
  useEffect(() => {
    loadGame(selectedDate);
  }, [selectedDate]);

  // Load game data for a specific date
  function loadGame(date) {
    // Simulate loading game data
    setTimeout(() => {
      // Simulate different articles based on the date
      const dateNum = new Date(date).getDate();
      const articleIndex = dateNum % 3;

      const articles = [
        {
          title: "Climate Change",
          text: "Climate change refers to long-term shifts in temperatures and weather patterns. These shifts may be natural, but since the 1800s, human activities have been the main driver of climate change, primarily due to the burning of fossil fuels like coal, oil, and gas, which produces heat-trapping gases.",
          url: "https://en.wikipedia.org/wiki/Climate_change",
          words: ["climate", "change", "global", "warming", "human", "activities", "fossil", "fuels", "temperatures", "weather", "patterns", "natural", "emissions", "gases", "atmosphere"]
        },
        {
          title: "Artificial Intelligence",
          text: "Artificial intelligence (AI) is intelligence demonstrated by machines, as opposed to natural intelligence displayed by animals including humans. AI research has been defined as the field of study of intelligent agents, which refers to any system that perceives its environment and takes actions that maximize its chance of achieving its goals.",
          url: "https://en.wikipedia.org/wiki/Artificial_intelligence",
          words: ["intelligence", "artificial", "machines", "human", "research", "systems", "agents", "learning", "goals", "environment", "natural", "field", "study", "actions", "perceives"]
        },
        {
          title: "Renewable Energy",
          text: "Renewable energy is energy that is collected from renewable resources that are naturally replenished on a human timescale. It includes sources such as sunlight, wind, rain, tides, waves, and geothermal heat. Renewable energy stands in contrast to fossil fuels, which are being used far more quickly than they are being replenished.",
          url: "https://en.wikipedia.org/wiki/Renewable_energy",
          words: ["renewable", "energy", "sources", "sunlight", "wind", "fossil", "fuels", "resources", "replenished", "sustainable", "natural", "geothermal", "tides", "waves", "heat"]
        }
      ];

      const article = articles[articleIndex];

      // Set the top 5 words in order of frequency
      const topWords = article.words.slice(0, 5);

      // Shuffle all words for the word bank
      const shuffledWords = [...article.words].sort(() => Math.random() - 0.5);

      setState({
        isLoading: false,
        article: {
          title: article.title,
          text: article.text,
          url: article.url
        },
        wordBank: shuffledWords.map(word => ({ id: word, text: word })),
        correctOrder: topWords,
        allWords: shuffledWords,
        guesses: [
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null]
        ],
        results: [null, null, null],
        currentGuess: 0,
        gameOver: false,
        isWinner: false
      });
    }, 500);
  }

  // Drag start handler
  function handleDragStart(e, word) {
    if (gameState.gameOver) return;

    e.dataTransfer.setData("application/json", JSON.stringify(word));
    e.dataTransfer.effectAllowed = "move";
    setDraggedWord(word);

    // For better visual feedback
    setTimeout(() => {
      e.target.classList.add("dragging");
    }, 0);
  }

  // Drag end handler
  function handleDragEnd(e) {
    e.target.classList.remove("dragging");
    setDraggedWord(null);
  }

  // Drag over handler for slots
  function handleDragOver(e) {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    e.currentTarget.classList.add("drag-over");
  }

  // Drag leave handler for slots
  function handleDragLeave(e) {
    e.currentTarget.classList.remove("drag-over");
  }

  // Drop handler for slots
  function handleDrop(e, rowIndex, slotIndex) {
    e.preventDefault();
    e.currentTarget.classList.remove("drag-over");

    if (gameState.gameOver || rowIndex !== gameState.currentGuess) return;

    try {
      const droppedWord = JSON.parse(e.dataTransfer.getData("application/json"));
      if (!droppedWord) return;

      // Create deep copies to avoid mutation
      const newGuesses = JSON.parse(JSON.stringify(gameState.guesses));
      let newWordBank = [...gameState.wordBank];

      // If there's already a word in this slot, put it back in the word bank
      const existingWord = newGuesses[rowIndex][slotIndex];
      if (existingWord) {
        newWordBank.push(existingWord);
      }

      // Place the dropped word in the slot
      newGuesses[rowIndex][slotIndex] = droppedWord;

      // Remove the word from the word bank
      newWordBank = newWordBank.filter(w => w.id !== droppedWord.id);

      // Update state
      setState({
        ...gameState,
        guesses: newGuesses,
        wordBank: newWordBank
      });
    } catch (error) {
      console.error("Error processing drop:", error);
    }
  }

  // Remove a word from a slot
  function removeWord(rowIndex, slotIndex) {
    if (gameState.gameOver || rowIndex !== gameState.currentGuess) return;

    const newGuesses = JSON.parse(JSON.stringify(gameState.guesses));
    const removedWord = newGuesses[rowIndex][slotIndex];

    if (removedWord) {
      newGuesses[rowIndex][slotIndex] = null;
      setState({
        ...gameState,
        guesses: newGuesses,
        wordBank: [...gameState.wordBank, removedWord]
      });
    }
  }

  // Submit current guess
  function submitGuess() {
    const currentRow = gameState.currentGuess;
    const currentGuess = gameState.guesses[currentRow];

    // Check if all slots are filled
    if (currentGuess.includes(null)) {
      alert("Please fill all slots before submitting!");
      return;
    }

    // Calculate results
    const userGuess = currentGuess.map(item => item.id);
    const correctOrder = gameState.correctOrder;

    const results = userGuess.map((word, index) => {
      if (word === correctOrder[index]) {
        return { word, status: 'correct' };
      } else if (correctOrder.includes(word)) {
        return { word, status: 'wrong-position' };
      } else {
        return { word, status: 'incorrect' };
      }
    });

    // Check if player won
    const isWinner = results.every(r => r.status === 'correct');

    // Update results
    const newResults = [...gameState.results];
    newResults[currentRow] = results;

    // Game is over if they won or used all 3 guesses
    const gameOver = isWinner || currentRow >= 2;

    // Reset word bank for next guess if not game over
    let nextWordBank = gameState.wordBank;
    if (!gameOver) {
      nextWordBank = gameState.allWords.map(word => ({ id: word, text: word }));
    }

    // Next guess
    const nextGuess = gameOver ? currentRow : currentRow + 1;

    // Update state
    setState({
      ...gameState,
      results: newResults,
      currentGuess: nextGuess,
      gameOver: gameOver,
      isWinner: isWinner,
      wordBank: nextWordBank
    });
  }

  // Share results
  function shareResults() {
    if (!gameState.gameOver) return;

    // Create emoji grid
    let emojiGrid = '';
    for (let i = 0; i <= gameState.currentGuess; i++) {
      if (gameState.results[i]) {
        const rowEmojis = gameState.results[i].map(r => {
          if (r.status === 'correct') return '🟩';
          if (r.status === 'wrong-position') return '🟨';
          return '🟥';
        }).join('');
        emojiGrid += rowEmojis + '\n';
      }
    }

    const attempts = gameState.isWinner ? gameState.currentGuess + 1 : 'X';
    const shareText = `WikiFreq ${selectedDate} - ${attempts}/3\n${emojiGrid}\nPlay today's challenge at wikifreq.game`;

    // Copy to clipboard
    navigator.clipboard.writeText(shareText)
      .then(() => alert('Results copied to clipboard!'))
      .catch(() => alert('Failed to copy results.'));
  }

  // Loading screen
  if (gameState.isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-stone-50">
        <div className="p-8 text-lg font-light text-stone-800">Loading challenge...</div>
      </div>
    );
  }

  // Date picker component
  function DatePickerModal({ isOpen, onClose, onSelectDate }) {
    // The hook must be called unconditionally at the top level
    const [tempDate, setTempDate] = useState(selectedDate);

    // Get last 7 days
    function getDateOptions() {
      const dates = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date();
        date.setDate(date.getDate() - i);
        dates.push(date.toISOString().slice(0, 10));
      }
      return dates;
    }

    // Early return with null but after the hooks are called
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-sm shadow-lg max-w-sm w-full">
          <h3 className="text-lg font-light mb-4 text-stone-800">Select a Date</h3>
          <div className="space-y-2 mb-4">
            {getDateOptions().map(date => (
              <button
                key={date}
                className={`w-full p-3 text-left rounded-sm ${
                  date === tempDate
                    ? 'bg-stone-800 text-white'
                    : 'bg-stone-100 text-stone-800 hover:bg-stone-200'
                }`}
                onClick={() => setTempDate(date)}
              >
                {formatDisplayDate(date)}
              </button>
            ))}
          </div>
          <div className="flex space-x-3">
            <button
              className="flex-1 py-2 border border-stone-300 rounded-sm text-stone-600 hover:bg-stone-100"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="flex-1 py-2 bg-stone-800 text-white rounded-sm"
              onClick={() => {
                onSelectDate(tempDate);
                onClose();
              }}
            >
              Play
            </button>
          </div>
        </div>
      </div>
    );
  }

  // Render game
  return (
    <div className="flex flex-col min-h-screen bg-stone-50 text-stone-800 font-light">
      {/* Header */}
      <header className="p-6 bg-stone-800 text-stone-100">
        <div className="container mx-auto max-w-3xl flex justify-between items-center">
          <h1 className="text-2xl font-light tracking-widest">WIKIFREQ</h1>
          <div className="text-sm tracking-wide">Word Frequency Challenge</div>
        </div>
      </header>

      {/* Date Picker */}
      <DatePickerModal
        isOpen={isDatePickerOpen}
        onClose={() => setDatePickerOpen(false)}
        onSelectDate={date => setSelectedDate(date)}
      />

      {/* Main Content */}
      <main className="flex-1 container mx-auto p-6 max-w-3xl">
        {/* Game Info */}
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-xl tracking-wide">{gameState.article.title}</h2>
            <div className="text-sm text-stone-600 mt-1">{formatDisplayDate(selectedDate)}</div>
          </div>
          <div className="flex space-x-4">
            <button
              className="text-sm text-stone-600 hover:text-stone-900 transition-colors"
              onClick={() => setDatePickerOpen(true)}
            >
              Change Date
            </button>
            <button
              className="text-sm text-stone-600 hover:text-stone-900 transition-colors"
              onClick={() => setShowDescription(!showDescription)}
            >
              {showDescription ? "Hide Description" : "Show Description"}
            </button>
          </div>
        </div>

        {/* Article Preview */}
        {showDescription && (
          <div className="bg-white rounded-sm shadow-sm p-6 mb-8 border border-stone-200">
            <div className={`${showFullArticle ? 'max-h-screen' : 'max-h-36'} overflow-y-auto text-stone-700 leading-relaxed transition-all duration-300`}>
              {gameState.article.text}
            </div>
            <div className="text-right mt-2">
              <button
                className="text-sm text-stone-600 hover:text-stone-900 transition-colors"
                onClick={() => setShowFullArticle(!showFullArticle)}
              >
                {showFullArticle ? "Show Less" : "Show More"}
              </button>
            </div>
          </div>
        )}

        {/* Game Instructions */}
        <div className="mb-6 text-center">
          <p className="font-light tracking-wide text-stone-600">
            {gameState.gameOver
              ? (gameState.isWinner
                 ? `Congratulations! You found the correct order in ${gameState.currentGuess} ${gameState.currentGuess === 1 ? 'try' : 'tries'}.`
                 : `Game over. Better luck next time!`)
              : "Drag and drop the 5 most frequent words in order from most to least frequent"}
          </p>
        </div>

        {/* Guesses Grid */}
        <div className="space-y-6 mb-8">
          {gameState.guesses.map((guess, rowIndex) => (
            <div
              key={`row-${rowIndex}`}
              className={`grid grid-cols-5 gap-3 mb-4 ${rowIndex === gameState.currentGuess && !gameState.gameOver ? '' : 'opacity-70'}`}
            >
              {guess.map((word, slotIndex) => {
                // Determine slot style
                let slotStyle = 'border border-stone-300 bg-stone-50';
                const isActive = rowIndex === gameState.currentGuess && !gameState.gameOver;
                const result = gameState.results[rowIndex]?.[slotIndex];

                if (result) {
                  if (result.status === 'correct') {
                    slotStyle = 'border border-emerald-300 bg-emerald-50';
                  } else if (result.status === 'wrong-position') {
                    slotStyle = 'border border-amber-300 bg-amber-50';
                  } else {
                    slotStyle = 'border border-rose-300 bg-rose-50';
                  }
                } else if (!isActive) {
                  slotStyle = 'border border-stone-200 bg-stone-100';
                }

                return (
                  <div
                    key={`slot-${rowIndex}-${slotIndex}`}
                    className={`rounded-sm p-3 h-16 flex items-center justify-center relative ${slotStyle} transition-all duration-200 ${isActive ? 'cursor-pointer hover:bg-stone-100' : ''}`}
                    onDragOver={isActive ? handleDragOver : null}
                    onDragLeave={isActive ? handleDragLeave : null}
                    onDrop={isActive ? (e) => handleDrop(e, rowIndex, slotIndex) : null}
                  >
                    <div className="absolute top-0 left-0 mt-1 ml-2 text-xs font-light text-stone-500">
                      #{slotIndex + 1}
                    </div>
                    {word && (
                      <div className="relative w-full">
                        <div className="bg-stone-800 text-stone-100 px-3 py-1 rounded-sm w-full text-center font-light tracking-wide">
                          {word.text}
                          {isActive && !gameState.results[rowIndex] && (
                            <button
                              className="absolute -top-2 -right-2 bg-stone-600 text-white rounded-full w-5 h-5 flex items-center justify-center"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeWord(rowIndex, slotIndex);
                              }}
                            >
                              ×
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>

        {/* Word Bank */}
        {!gameState.gameOver && (
          <div className="bg-white rounded-sm shadow-sm p-6 border border-stone-200">
            <h3 className="text-sm font-medium mb-4 text-stone-500 tracking-wide uppercase">Word Bank</h3>
            <div className="flex flex-wrap gap-3">
              {gameState.wordBank.map((word) => (
                <div
                  key={word.id}
                  className={`px-4 py-2 rounded-sm cursor-grab transition-all duration-300
                    ${draggedWord?.id === word.id ? 'opacity-50' : 'bg-stone-200 text-stone-800'}
                    hover:bg-stone-300 font-light tracking-wide text-center border border-stone-300`}
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, word)}
                  onDragEnd={handleDragEnd}
                >
                  {word.text}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Submit Button */}
        {!gameState.gameOver && (
          <button
            onClick={submitGuess}
            className={`mt-8 w-full py-3 rounded-sm font-light tracking-wide transition-colors
              ${gameState.guesses[gameState.currentGuess].includes(null)
                ? 'bg-stone-300 text-stone-500 cursor-not-allowed'
                : 'bg-stone-800 text-stone-100 hover:bg-stone-700'}`}
            disabled={gameState.guesses[gameState.currentGuess].includes(null)}
          >
            Submit
          </button>
        )}

        {/* Results */}
        {gameState.gameOver && (
          <div className="mt-8 bg-white rounded-sm shadow-sm p-6 border border-stone-200">
            <h3 className="text-xl font-light tracking-wide mb-4 text-stone-800">Game Summary</h3>

            <div className="text-center mb-6">
              <div className="text-3xl font-light text-stone-800 mb-2">
                {gameState.isWinner ? `Solved in ${gameState.currentGuess}/3` : 'Game Over'}
              </div>
            </div>

            <div className="space-y-3 mb-6">
              <div className="flex items-center space-x-3">
                <div className="w-4 h-4 bg-emerald-500 rounded-sm"></div>
                <div>Correct position</div>
              </div>
              <div className="flex items-center space-x-3">
                <div className="w-4 h-4 bg-amber-500 rounded-sm"></div>
                <div>Correct word, wrong position</div>
              </div>
              <div className="flex items-center space-x-3">
                <div className="w-4 h-4 bg-rose-500 rounded-sm"></div>
                <div>Not in top 5</div>
              </div>
            </div>

            {/* Wikipedia Link */}
            <div className="mb-6">
              <a
                href={gameState.article.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-stone-600 hover:text-stone-900 underline transition-colors"
              >
                Read the full article on Wikipedia
              </a>
            </div>

            <button
              onClick={shareResults}
              className="w-full py-3 bg-stone-800 text-stone-100 rounded-sm mb-6 tracking-wide hover:bg-stone-700 transition-colors"
            >
              Share Results
            </button>

            <div className="pt-6 border-t border-stone-200">
              <h4 className="font-medium mb-3 text-stone-700">Correct Order:</h4>
              <div className="flex flex-wrap gap-2">
                {gameState.correctOrder.map((word, index) => (
                  <div key={index} className="bg-stone-100 border border-stone-300 px-3 py-1 rounded-sm">
                    {index + 1}. {word}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </main>

      {/* Footer */}
      <footer className="p-6 bg-stone-200 text-center text-sm text-stone-600">
        <div className="container mx-auto max-w-3xl">
          A new challenge will be available daily at 12:00 AM PST
        </div>
      </footer>

      {/* CSS for drag feedback */}
      <style jsx="true">{`
        .dragging {
          opacity: 0.4;
        }
        .drag-over {
          background-color: #f3f4f6;
          border-color: #6b7280;
        }
      `}</style>
    </div>
  );
};

export default WikiWordFrequencyGame;