import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import Crossword from './projects/crosswords/Crosswordarchive';
import CrosswordPage from './projects/crosswords/CrosswordPage';
import RareSpecies from './projects/rare/RareSpecies';
import MoviePage from './projects/rater/MoviePage.js';
import HuntPage from './projects/huddle/HuntPage.js';
import MuseumGame from './projects/museo/MuseumGame.js';
import WikiLength from './projects/wikilen/WikiLength.js';
import CityGame from './projects/cities/CityGame.js';
import TrustPage from './projects/trust/TrustPage.js';
import LinkleGames from './projects/linkle/LinkleGames.js';
import StickFigureGame from './projects/Damsel/StickFigureGame.js';
import ArticlePopularityBattle from './projects/wikifamous/ArticlePopularityBattle.js';
//import FindTheFakeGame from './projects/famousfakes/FindTheFakeGame.js';
import ProjectPalette from './projects/famouscolors/ProjectPalette.js';
//import CourtCaseGame from './projects/lawgame/CourtCaseGame.js';
import FindSatelliteGame from './projects/findingsats/FindSatelliteGame.js';
import WikiSentenceGame from './projects/wikisentence/WikiSentenceGame.js';
import PlasticDriftGame from './projects/plasticdrift/PlasticDriftGame.js';
import WikipediaWalkGame from './projects/wikiwalk/WikipediaWalkGame.js';
import WikiWordle from './projects/wiki-trendle/WikiWordle.js';
import WikiWordFrequencyGame from './projects/wikifreq/WikiWordFrequencyGame.js';
//import WikiTimelineGame from './projects/wikitime/WikiTimelineGame.js';
//import Era2048 from './projects/era2048/Era2048.js';
//import LiteraryTriviaGame from './projects/booktrivia/LiteraryTriviaGame.js';
import { ExplorePage, AboutPage } from './components/navigation-pages';
import PrivacyPolicy from './components/PrivacyPolicy';

import './styles/App.css';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header className="flex-shrink-0" />
        <main className="flex-grow overflow-y-auto" style={{ height: 'calc(100vh - 160px)' }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/crossword" element={<Crossword />} />
            <Route path="/crossword/:puzzleId" element={<CrosswordPage />} />
            <Route path="/rare" element={<RareSpecies />} />
            <Route path="/rater" element={<MoviePage />} />
            <Route path="/huddle" element={<HuntPage />} />
            <Route path="/museo" element={<MuseumGame />} />
            <Route path="/wikile" element={<WikiLength />} />
            <Route path="/cities" element={<CityGame />} />
            <Route path="/trustissues" element={<TrustPage />} />
            <Route path="/linkle" element={<LinkleGames />} />
            <Route path="/damsel" element={<StickFigureGame />} />
            <Route path="/wikifamous" element={<ArticlePopularityBattle />} />

            <Route path="/famouscolors" element={<ProjectPalette />} />
            <Route path="/findingsats" element={<FindSatelliteGame />} />
            <Route path="/wikilines" element={<WikiSentenceGame />} />

            <Route path="/plasticdrift" element={<PlasticDriftGame />} />
            <Route path="/wikiwalk" element={<WikipediaWalkGame />} />
            <Route path="/wikitrendle" element={<WikiWordle />} />
            <Route path="/wikifreq" element={<WikiWordFrequencyGame />} />
           


            <Route path="/explore" element={<ExplorePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
          </Routes>
        </main>
        <Footer className="flex-shrink-0" />
      </div>
    </Router>
  );
}

export default App;