import React, { useState, useEffect, useCallback } from 'react';

const WikiWordle = () => {
  const [secretWord, setSecretWord] = useState('');
  const [guesses, setGuesses] = useState([]);
  const [currentGuess, setCurrentGuess] = useState('');
  const [gameStatus, setGameStatus] = useState('playing'); // 'playing', 'won', 'lost'
  const [articleTitle, setArticleTitle] = useState('');
  const [articleUrl, setArticleUrl] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [message, setMessage] = useState('Loading today\'s word...');
  const [showHint, setShowHint] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateMode, setDateMode] = useState(null); // For playing previous days
  const [availableDates, setAvailableDates] = useState([]);

  const MAX_ATTEMPTS = 6;

  // List of common English words to use for fallback
  const commonEnglishWords = [
    "world", "people", "place", "music", "water", "river", "ocean", "movie",
    "actor", "story", "color", "house", "money", "phone", "paper", "party",
    "music", "state", "night", "child", "woman", "field", "power", "group",
    "earth", "sound", "water", "table", "event", "space", "plant", "light"
  ];

  // Check if a word exists in Wiktionary or Wikipedia (dictionary verification)
  const checkWordInDictionary = async (word) => {
    try {
      console.log(`[WikiWordle] Checking if "${word}" exists in dictionaries`);

      // First check for any unusual letter patterns or rare words that should be rejected
      const isCommonPattern = /^[a-zA-Z]{3,8}$/.test(word) &&
                              !/([qxzjkv]{2,})/.test(word.toLowerCase()) && // No double rare letters
                              !/([^aeiou]{5,})/.test(word.toLowerCase()); // Not too many consonants in a row

      if (!isCommonPattern) {
        console.log(`[WikiWordle] Word "${word}" has unusual pattern, rejecting`);
        return false;
      }

      // Try Wikipedia API first (for proper nouns, names, places, etc.)
      const wikiUrl = `https://en.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(word)}`;
      const wikiResponse = await fetch(wikiUrl);

      if (wikiResponse.ok) {
        const wikiData = await wikiResponse.json();
        // Check if it's a valid page and not a disambiguation
        if (wikiData.type === 'standard') {
          console.log(`[WikiWordle] Word "${word}" found as a proper noun in Wikipedia`);
          return true;
        }
      }

      // Then try Wiktionary (for dictionary words)
      const wiktUrl = `https://en.wiktionary.org/api/rest_v1/page/summary/${encodeURIComponent(word)}`;
      const wiktResponse = await fetch(wiktUrl);

      if (wiktResponse.ok) {
        console.log(`[WikiWordle] Word "${word}" found in Wiktionary`);
        return true;
      }

      console.log(`[WikiWordle] Word "${word}" not found in either dictionary`);
      return false;
    } catch (err) {
      console.error(`[WikiWordle] Error checking word in dictionaries:`, err);
      return false; // Assume false on error
    }
  };

  // Extract keywords using a local algorithm with dictionary verification
  const extractKeywords = async (text, description = "") => {
    try {
      console.log(`[WikiWordle] Analyzing title: "${text}"`);
      if (description) {
        console.log(`[WikiWordle] Description available: "${description.substring(0, 100)}..."`);
      }

      // Extract words from title and description, prioritizing whole words
      const words = [];

      // First get proper nouns from title (capitalized words)
      const properNouns = text.split(/\s+/)
        .filter(word => word.length >= 3 && word.length <= 8)
        .filter(word => word[0] === word[0].toUpperCase())
        .map(word => word.replace(/[^a-zA-Z]/g, ''))
        .filter(word => word.length >= 3 && /^[a-zA-Z]+$/.test(word));

      words.push(...properNouns);

      // Then get all other words from title
      const normalWords = text.toLowerCase().split(/\s+/)
        .filter(word => word.length >= 3 && word.length <= 8)
        .map(word => word.replace(/[^a-z]/g, ''))
        .filter(word => word.length >= 3 && /^[a-z]+$/.test(word));

      words.push(...normalWords);

      // If we have a description, extract important words from it too
      if (description && description.length > 0) {
        // Get the first sentence or up to 200 chars for key concepts
        const firstPart = description.split('.')[0] || description.substring(0, 200);

        // Extract proper nouns from description
        const descriptionProperNouns = firstPart.split(/\s+/)
          .filter(word => word.length >= 3 && word.length <= 8)
          .filter(word => word[0] === word[0].toUpperCase())
          .map(word => word.replace(/[^a-zA-Z]/g, ''))
          .filter(word => word.length >= 3 && /^[a-zA-Z]+$/.test(word));

        // Extract normal words from description
        const descriptionWords = firstPart.toLowerCase().split(/\s+/)
          .filter(word => word.length >= 3 && word.length <= 8)
          .map(word => word.replace(/[^a-z]/g, ''))
          .filter(word => word.length >= 3 && /^[a-z]+$/.test(word));

        // Add description words, but give them lower priority than title words
        words.push(...descriptionProperNouns, ...descriptionWords);
      }

      // Remove duplicates
      const uniqueWords = [...new Set(words)];

      console.log('[WikiWordle] Candidate words:', uniqueWords);

      if (uniqueWords.length === 0) {
        console.log('[WikiWordle] No candidate words found, using fallback word');
        return commonEnglishWords[Math.floor(Math.random() * commonEnglishWords.length)];
      }

      // Filter out words with strange character patterns
      const filteredWords = uniqueWords.filter(word => {
        // No double rare letters like "zz", "xx", etc.
        const hasDoubleRareLetters = /([qxzjkv])\1/.test(word.toLowerCase());

        // No more than 4 consonants in a row
        const tooManyConsonants = /[^aeiou]{5,}/i.test(word.toLowerCase());

        // No more than 1 rare letter
        const rareLetterCount = (word.toLowerCase().match(/[qxzjvk]/g) || []).length;

        return !hasDoubleRareLetters && !tooManyConsonants && rareLetterCount <= 1;
      });

      console.log('[WikiWordle] Filtered words:', filteredWords);

      if (filteredWords.length === 0) {
        console.log('[WikiWordle] No filtered words, using common English word');
        return commonEnglishWords[Math.floor(Math.random() * commonEnglishWords.length)];
      }

      // Try to verify words in dictionary
      // Check up to 5 words to avoid too many API calls
      const wordsToCheck = filteredWords.slice(0, 5);

      // First check proper nouns that are capitalized in the original text
      for (const word of wordsToCheck.filter(w =>
        properNouns.some(pn => pn.toLowerCase() === w.toLowerCase())
      )) {
        const isValid = await checkWordInDictionary(word);
        if (isValid) {
          console.log(`[WikiWordle] Selected verified proper noun: "${word}"`);
          return word;
        }
      }

      // Then check other words
      for (const word of wordsToCheck) {
        const isValid = await checkWordInDictionary(word);
        if (isValid) {
          console.log(`[WikiWordle] Selected verified word: "${word}"`);
          return word;
        }
      }

      // If no words passed verification, use a common English word
      console.log('[WikiWordle] No verified words found, using common English word');
      return commonEnglishWords[Math.floor(Math.random() * commonEnglishWords.length)];
    } catch (err) {
      console.error('[WikiWordle] Error in keyword extraction:', err);
      return commonEnglishWords[Math.floor(Math.random() * commonEnglishWords.length)];
    }
  };

  // Function to get formatted date for display and storage - FIXED VERSION
  const getFormattedDate = (date = new Date()) => {
    const options = {
      timeZone: 'America/Los_Angeles',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };

    // Get formatted date from Intl.DateTimeFormat which returns MM/DD/YYYY
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    // Split the date parts
    const parts = formattedDate.split('/');

    // Correctly reorder as YYYY-MM-DD
    return `${parts[2]}-${parts[0]}-${parts[1]}`;
  };

  // Function to fetch trending Wikipedia articles
  const fetchTrendingArticle = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Get current date in PST/PDT timezone
      const today = getFormattedDate();
      setCurrentDate(today);

      // Check if we already have today's word in localStorage
      const storedData = localStorage.getItem('wikiwordle');

      if (storedData) {
        const data = JSON.parse(storedData);
        if (data.date === today) {
          setSecretWord(data.word);
          setArticleTitle(data.title);
          setArticleUrl(data.url);
          setIsLoading(false);
          setMessage('Guess today\'s word!');
          return;
        }
      }

      // Load previous words to avoid repetition
      let usedWords = [];
      try {
        const storedUsedWords = localStorage.getItem('wikiwordle_used_words');
        if (storedUsedWords) {
          usedWords = JSON.parse(storedUsedWords);
          console.log(`[WikiWordle] Loaded ${usedWords.length} previously used words`);
        }
      } catch (err) {
        console.error('[WikiWordle] Error loading used words:', err);
        // If there's an error loading the used words, continue with an empty array
        usedWords = [];
      }

      // Log the API call for tracking purposes
      console.log(`[WikiWordle] Making API call to fetch new daily word at ${new Date().toISOString()}`);

      // Function to get article from popular list
      const getArticleFromPopular = async (daysAgo = 1) => {
        // Try to get most viewed articles for X days ago
        const date = new Date();
        date.setDate(date.getDate() - daysAgo);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        console.log(`[WikiWordle] Fetching popular articles for ${year}/${month}/${day}`);

        try {
          // Wikimedia API for top articles - this gives the truly most viewed articles
          const popularResponse = await fetch(`https://wikimedia.org/api/rest_v1/metrics/pageviews/top/en.wikipedia/all-access/${year}/${month}/${day}`);

          if (!popularResponse.ok) {
            console.log(`[WikiWordle] No data available for ${year}/${month}/${day}`);
            return null;
          }

          const data = await popularResponse.json();

          if (!data.items || !data.items[0] || !data.items[0].articles || data.items[0].articles.length === 0) {
            console.log('[WikiWordle] No articles found in response');
            return null;
          }

          console.log(`[WikiWordle] Successfully fetched ${data.items[0].articles.length} most viewed articles`);
          console.log('[WikiWordle] Top 5 articles:', data.items[0].articles.slice(0, 5).map(a => a.article));

          // Filter out inappropriate articles and technical pages
          const validArticles = data.items[0].articles.filter(item => {
            const article = item.article;
            return !article.includes('Special:') &&
                 !article.includes('Wikipedia:') &&
                 !article.includes('Main_Page') &&
                 !article.includes('File:') &&
                 !article.includes('Portal:') &&
                 !article.includes('Category:') &&
                 !article.includes('Template:') &&
                 !article.includes('Help:') &&
                 article.length > 3;
          });

          if (validArticles.length === 0) {
            console.log('[WikiWordle] No valid articles found after filtering');
            return null;
          }

          // Pick one of the top 10 most viewed valid articles
          const topArticles = validArticles.slice(0, 10);
          const randomIndex = Math.floor(Math.random() * topArticles.length);
          const selectedArticle = topArticles[randomIndex].article;

          console.log(`[WikiWordle] Selected popular article: ${selectedArticle} (ranked #${validArticles.indexOf(topArticles[randomIndex]) + 1} by views)`);

          // Get the details for this article
          const articleResponse = await fetch(`https://en.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(selectedArticle)}`);

          if (articleResponse.ok) {
            const article = await articleResponse.json();
            // Add rank property
            article.rank = validArticles.indexOf(topArticles[randomIndex]) + 1;
            return article;
          }
        } catch (err) {
          console.error(`[WikiWordle] Error fetching popular articles for ${year}/${month}/${day}:`, err);
        }

        return null;
      };

      // Try to get popular articles from recent days (today, yesterday, or 2 days ago)
      let article = null;

      for (let daysAgo = 1; daysAgo <= 3 && !article; daysAgo++) {
        article = await getArticleFromPopular(daysAgo);
      }

      // If we still don't have an article, fall back to random
      if (!article) {
        console.log('[WikiWordle] Could not get popular articles, falling back to random article');

        try {
          const randomResponse = await fetch('https://en.wikipedia.org/api/rest_v1/page/random/summary');

          if (randomResponse.ok) {
            article = await randomResponse.json();
          } else {
            throw new Error('Failed to fetch articles from Wikipedia');
          }
        } catch (err) {
          console.error('[WikiWordle] Error fetching random article:', err);
          throw new Error('Failed to fetch any article from Wikipedia');
        }
      }

      if (!article) {
        throw new Error('Failed to fetch any article from Wikipedia');
      }

      // Process and extract keyword
      console.log('[WikiWordle] Processing title for keyword extraction');
      // For titles with commas, focus on the main part (before the first comma)
      const mainTitle = article.title.includes(',') ? article.title.split(',')[0] : article.title;

      // Get description if available
      const description = article.extract || article.description || "";

      // Extract the most important word using our dictionary-verified algorithm
      let keyword = await extractKeywords(mainTitle, description);

      // Ensure the word hasn't been used before
      let attempts = 0;
      const maxAttempts = 5;

      while (usedWords.includes(keyword.toLowerCase()) && attempts < maxAttempts) {
        console.log(`[WikiWordle] Word "${keyword}" has been used before, trying another article`);
        attempts++;

        // Try another article
        try {
          const newRandomResponse = await fetch('https://en.wikipedia.org/api/rest_v1/page/random/summary');

          if (newRandomResponse.ok) {
            const newArticle = await newRandomResponse.json();
            const newMainTitle = newArticle.title.includes(',') ? newArticle.title.split(',')[0] : newArticle.title;
            keyword = await extractKeywords(newMainTitle);

            // If we find a new word, update the article info
            if (!usedWords.includes(keyword.toLowerCase())) {
              article = newArticle;
              break;
            }
          }
        } catch (err) {
          console.error('[WikiWordle] Error fetching alternative article:', err);
        }
      }

      // Add the new word to used words list and save
      usedWords.push(keyword.toLowerCase());
      // Keep only the last 100 words to prevent localStorage from growing too large
      if (usedWords.length > 100) {
        usedWords = usedWords.slice(-100);
      }

      localStorage.setItem('wikiwordle_used_words', JSON.stringify(usedWords));
      console.log(`[WikiWordle] Added "${keyword}" to used words list (total: ${usedWords.length})`);

      // Get PST/PDT timestamp for logging when the word was generated
      const pstTimeFormatter = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });

      const timestamp = pstTimeFormatter.format(new Date());

      // Store in localStorage with timestamp of when it was generated
      const wordData = {
        date: today,
        word: keyword,
        title: article.title,
        url: article.content_urls.desktop.page,
        description: article.extract || article.description || "",
        generatedAt: timestamp,
        popular: !!article.rank,
        rank: article.rank || 'random'
      };

      localStorage.setItem('wikiwordle', JSON.stringify(wordData));

      // Also store in historical record
      const historyKey = `wikiwordle_${today}`;
      localStorage.setItem(historyKey, JSON.stringify(wordData));

      // Update the list of available dates
      try {
        let datesList = [];
        const storedDates = localStorage.getItem('wikiwordle_dates');
        if (storedDates) {
          datesList = JSON.parse(storedDates);
        }
        if (!datesList.includes(today)) {
          datesList.push(today);
          // Sort dates in reverse order (newest first)
          datesList.sort().reverse();
          // Keep only the last 30 days to prevent localStorage from growing too large
          if (datesList.length > 30) {
            datesList = datesList.slice(0, 30);
          }
          localStorage.setItem('wikiwordle_dates', JSON.stringify(datesList));
        }
        setAvailableDates(datesList);
      } catch (err) {
        console.error('[WikiWordle] Error updating dates list:', err);
      }

      // Log for debugging
      console.log(`[WikiWordle] New word "${keyword}" stored for ${today} at ${timestamp} PST`);

      setSecretWord(keyword);
      setArticleTitle(article.title);
      setArticleUrl(article.content_urls.desktop.page);
      setMessage('Guess today\'s word!');
      setIsLoading(false);
    } catch (err) {
      setError('Failed to load the game. Please try again later.');
      setIsLoading(false);
      console.error(err);
    }
  }, []);

  // Check if it's time to update the word (12 AM PST)
  const checkForWordUpdate = useCallback(() => {
    const now = new Date();
    const pstOptions = { timeZone: 'America/Los_Angeles', hour12: false };
    const pstHour = parseInt(new Intl.DateTimeFormat('en-US', { ...pstOptions, hour: '2-digit' }).format(now));
    const pstMinute = parseInt(new Intl.DateTimeFormat('en-US', { ...pstOptions, minute: '2-digit' }).format(now));

    // If it's midnight in PST (00:00 to 00:05), update the daily word
    if (pstHour === 0 && pstMinute < 5) {
      localStorage.removeItem('wikiwordle'); // Force refresh of the word
      fetchTrendingArticle();
    }
  }, [fetchTrendingArticle]);

  // Function to load a game from a specific date
  const loadGameByDate = useCallback(async (date) => {
    try {
      setIsLoading(true);
      setError(null);

      // Clear current game state
      setGuesses([]);
      setCurrentGuess('');
      setGameStatus('playing');
      setShowHint(false);

      const historyKey = `wikiwordle_${date}`;
      const storedData = localStorage.getItem(historyKey);

      if (storedData) {
        const data = JSON.parse(storedData);
        setSecretWord(data.word);
        setArticleTitle(data.title);
        setArticleUrl(data.url);
        setDateMode(date);
        setCurrentDate(date);
        setMessage(`Playing the word from ${date}`);
        setIsLoading(false);
      } else {
        // If we don't have data for this date, show an error
        setError(`No game data available for ${date}`);
        setIsLoading(false);
      }
    } catch (err) {
      console.error(`[WikiWordle] Error loading game for date ${date}:`, err);
      setError(`Failed to load game for ${date}`);
      setIsLoading(false);
    }
  }, []);

  // Load available dates
  const loadAvailableDates = useCallback(() => {
    try {
      const storedDates = localStorage.getItem('wikiwordle_dates');
      if (storedDates) {
        const datesList = JSON.parse(storedDates);
        setAvailableDates(datesList);
      }
    } catch (err) {
      console.error('[WikiWordle] Error loading available dates:', err);
    }
  }, []);

  useEffect(() => {
    // Load available dates first
    loadAvailableDates();

    // Initial load of the word (today's word)
    fetchTrendingArticle();

    // Check for updates at regular intervals
    const intervalId = setInterval(() => {
      checkForWordUpdate();
    }, 5 * 60 * 1000); // Check every 5 minutes

    return () => clearInterval(intervalId);
  }, [fetchTrendingArticle, checkForWordUpdate, loadAvailableDates]);

  // Handle keyboard input
  const handleKeyPress = useCallback((key) => {
    if (gameStatus !== 'playing' || isLoading) return;

    if (key === 'Enter') {
      if (currentGuess.length < 3) {
        setMessage('Word is too short');
        return;
      }

      if (guesses.length >= MAX_ATTEMPTS) {
        return;
      }

      setGuesses(prev => [...prev, currentGuess]);
      setCurrentGuess('');

      if (currentGuess.toLowerCase() === secretWord.toLowerCase()) {
        setGameStatus('won');
        setMessage('You won! 🎉');
      } else if (guesses.length + 1 >= MAX_ATTEMPTS) {
        setGameStatus('lost');
        setMessage(`Game over. The word was "${secretWord}"`);
      } else if (guesses.length + 1 === 3) {
        setMessage('3 attempts used. Need a hint?');
      }
    } else if (key === 'Backspace') {
      setCurrentGuess(prev => prev.slice(0, -1));
    } else if (/^[a-zA-Z]$/.test(key) && currentGuess.length < secretWord.length) {
      setCurrentGuess(prev => prev + key.toLowerCase());
    }
  }, [currentGuess, guesses, gameStatus, secretWord, isLoading]);

  // Handle physical keyboard events
  useEffect(() => {
    const handleKeyDown = (e) => {
      handleKeyPress(e.key);
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyPress]);

  // Show hint after 3 incorrect attempts
  const showHintHandler = () => {
    if (guesses.length >= 3 && !showHint) {
      setShowHint(true);
      setMessage(`Hint: The word starts with "${secretWord[0].toUpperCase()}" and ends with "${secretWord[secretWord.length - 1]}"`);
    }
  };

  // Restart the game and handle errors gracefully
  const restartGame = () => {
    try {
      if (dateMode) {
        // If we're in date mode, reset to today's word
        setDateMode(null);
      } else {
        // If we're playing today's word, force a refresh
        localStorage.removeItem('wikiwordle');
      }

      setGuesses([]);
      setCurrentGuess('');
      setGameStatus('playing');
      setShowHint(false);
      setError(null); // Clear any previous errors
      fetchTrendingArticle();
    } catch (err) {
      console.error("Error restarting game:", err);
      setError("There was a problem restarting the game. Please refresh the page.");
    }
  };

  // Switch to a different date
  const switchToDate = (date) => {
    if (date === 'today') {
      // Switch back to today's word
      setDateMode(null);
      restartGame();
    } else {
      // Load the word for the selected date
      loadGameByDate(date);
    }
  };

  // Format a date for prettier display
  const formatDisplayDate = (dateStr) => {
    try {
      const [year, month, day] = dateStr.split('-');
      const date = new Date(year, month - 1, day);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    } catch (e) {
      return dateStr;
    }
  };

  // Check letter status (correct, wrong position, or incorrect)
  const getLetterStatus = (letter, index, word) => {
    const lowerLetter = letter.toLowerCase();
    const lowerWord = word.toLowerCase();

    if (lowerLetter === lowerWord[index]) {
      return 'correct';
    } else if (lowerWord.includes(lowerLetter)) {
      return 'wrong-position';
    } else {
      return 'incorrect';
    }
  };

  if (error) {
    return (
      <div className="pt-32 pb-16 px-6 max-w-6xl mx-auto"> {/* Increased top padding for header */}
        <div className="p-6 bg-stone-100 rounded-md shadow-sm text-center max-w-md mx-auto">
          <h1 className="text-2xl font-extralight mb-6 text-stone-800 tracking-wider">WikiWordle</h1>
          <p className="text-red-700 mb-8 font-light">{error}</p>
          <button
            className="px-6 py-2 bg-stone-700 text-stone-100 rounded-sm hover:bg-stone-800 transition-colors font-light tracking-wider"
            onClick={restartGame}
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="pt-32 pb-16 px-6 max-w-6xl mx-auto"> {/* Increased top padding for header */}
        <div className="p-6 bg-stone-100 rounded-md shadow-sm text-center max-w-md mx-auto">
          <h1 className="text-2xl font-extralight mb-6 text-stone-800 tracking-wider">WikiWordle</h1>
          <p className="mb-8 text-stone-600 font-light">Loading today's word...</p>
          <div className="w-12 h-12 border-2 border-stone-400 border-t-stone-100 rounded-full animate-spin mx-auto"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-32 pb-16 px-6 max-w-6xl mx-auto"> {/* Increased top padding for header */}
      <div className="p-8 bg-stone-100 rounded-md shadow-sm max-w-md mx-auto">
        <h1 className="text-2xl font-extralight text-center mb-4 text-stone-800 tracking-wider">
          WikiWordle
        </h1>

        {/* Date display */}
        <div className="text-center mb-4">
          <span className="text-sm font-light text-stone-600 tracking-wide">
            {formatDisplayDate(currentDate)}
            {dateMode && " (Historical)"}
          </span>
        </div>

        {/* Date selector dropdown */}
        {availableDates.length > 0 && (
          <div className="mb-6 flex justify-center">
            <select
              className="px-3 py-1 bg-stone-200 text-stone-700 rounded-sm font-light text-sm tracking-wide"
              value={dateMode || 'today'}
              onChange={(e) => switchToDate(e.target.value)}
            >
              <option value="today">Today's Word</option>
              {availableDates.map(date => (
                <option key={date} value={date}>
                  {formatDisplayDate(date)}
                </option>
              ))}
            </select>
          </div>
        )}

        <p className="text-center mb-6 text-stone-600 font-light tracking-wide">{message}</p>

        {/* Game grid */}
        <div className="mb-10">
          {/* Previous guesses */}
          {guesses.map((guess, guessIndex) => (
            <div key={guessIndex} className="flex justify-center mb-3">
              {Array.from(guess.padEnd(secretWord.length, ' ')).slice(0, secretWord.length).map((letter, letterIndex) => (
                <div
                  key={letterIndex}
                  className={`
                    w-10 h-10 flex items-center justify-center border mx-1 font-light
                    ${letter.trim() === '' ? 'border-stone-300 bg-stone-100' :
                      getLetterStatus(letter, letterIndex, secretWord) === 'correct' ? 'bg-green-600 border-green-700 text-white' :
                      getLetterStatus(letter, letterIndex, secretWord) === 'wrong-position' ? 'bg-yellow-500 border-yellow-600 text-white' :
                      'bg-stone-400 border-stone-500 text-white'
                    }
                    transition-colors duration-300
                  `}
                >
                  {letter.toUpperCase()}
                </div>
              ))}
            </div>
          ))}

          {/* Current guess */}
          {gameStatus === 'playing' && (
            <div className="flex justify-center mb-3">
              {Array.from(currentGuess.padEnd(secretWord.length, ' ')).slice(0, secretWord.length).map((letter, index) => (
                <div
                  key={index}
                  className={`
                    w-10 h-10 flex items-center justify-center border mx-1 font-light
                    ${letter.trim() === '' ? 'border-stone-300' : 'border-stone-400 bg-stone-200'}
                    transition-colors duration-300
                  `}
                >
                  {letter.toUpperCase()}
                </div>
              ))}
            </div>
          )}

          {/* Empty rows for remaining attempts */}
          {gameStatus === 'playing' &&
            [...Array(MAX_ATTEMPTS - guesses.length - 1)].map((_, rowIndex) => (
              <div key={rowIndex} className="flex justify-center mb-3">
                {[...Array(secretWord.length)].map((_, colIndex) => (
                  <div
                    key={colIndex}
                    className="w-10 h-10 border border-stone-200 mx-1"
                  ></div>
                ))}
              </div>
            ))
          }
          </div>

        {/* Keyboard */}
        {gameStatus === 'playing' && (
          <div className="mb-8">
            {[
              ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
              ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
              ['Enter', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'Backspace']
            ].map((row, rowIndex) => (
              <div key={rowIndex} className="flex justify-center mb-2">
                {row.map(key => (
                  <button
                    key={key}
                    className={`
                      ${key === 'Enter' || key === 'Backspace' ? 'px-2 w-auto' : 'w-8'}
                      h-10 m-1 rounded-sm bg-stone-200 font-light text-stone-700 hover:bg-stone-300 transition-colors tracking-wide
                    `}
                    onClick={() => handleKeyPress(key)}
                  >
                    {key === 'Backspace' ? '⌫' : key}
                  </button>
                ))}
              </div>
            ))}
          </div>
        )}

        {/* Game controls */}
        <div className="flex justify-center space-x-4">
          {gameStatus === 'playing' && guesses.length >= 3 && !showHint && (
            <button
              className="px-6 py-2 bg-stone-600 text-stone-100 rounded-sm hover:bg-stone-700 transition-colors font-light tracking-wider"
              onClick={showHintHandler}
            >
              Get Hint
            </button>
          )}

          {(gameStatus === 'won' || gameStatus === 'lost') && (
            <>
              <button
                className="px-6 py-2 bg-stone-600 text-stone-100 rounded-sm hover:bg-stone-700 transition-colors font-light tracking-wider"
                onClick={restartGame}
              >
                Play Again
              </button>
            </>
          )}
        </div>

        {/* Show article info after game ends */}
        {(gameStatus === 'won' || gameStatus === 'lost') && (
          <div className="mt-8 p-4 bg-stone-200 rounded-sm">
            <p className="text-center mb-2 text-stone-700 font-light">
              <strong>{dateMode ? `Article from ${formatDisplayDate(dateMode)}:` : "Today's Article:"}</strong>
            </p>
            <p className="text-center text-stone-700 font-light mb-2 tracking-wide">
              {articleTitle}
            </p>

            {/* Get article description from localStorage if available */}
            {(() => {
              try {
                const key = dateMode ? `wikiwordle_${dateMode}` : 'wikiwordle';
                const storedData = localStorage.getItem(key);
                if (storedData) {
                  const data = JSON.parse(storedData);
                  if (data.description) {
                    return (
                      <p className="text-sm text-center text-stone-600 font-light mb-4 italic tracking-wide">
                        {data.description.length > 150
                          ? data.description.substring(0, 150) + '...'
                          : data.description}
                      </p>
                    );
                  }
                }
                return <div className="mb-4"></div>;
              } catch (err) {
                return <div className="mb-4"></div>;
              }
            })()}

            <div className="flex justify-center">
              <a
                href={articleUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 bg-blue-600 text-white rounded-sm hover:bg-blue-700 transition-colors font-light text-sm tracking-wider"
              >
                Read on Wikipedia
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WikiWordle;