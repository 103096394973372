import React, { useState, useEffect, useCallback } from 'react';

const StickFigureGame = () => {
  const [isJumping, setIsJumping] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [obstacles, setObstacles] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [runFrame, setRunFrame] = useState(0);
  const [groundOffset, setGroundOffset] = useState(0);
  const [hasUmbrella, setHasUmbrella] = useState(false);
  const [isScreenFlashing, setIsScreenFlashing] = useState(false);
  const [dispersingLightning, setDispersingLightning] = useState(new Set());

  // Game constants
  const JUMP_HEIGHT = 100;
  const JUMP_DURATION = 700;
  const OBSTACLE_SPEED = 5;
  const BASE_SPAWN_RATE = 1000; // Reduced from 2000
  const GROUND_SPEED = 5;

  // Debug state to verify lightning spawning
  const [debugLastSpawn, setDebugLastSpawn] = useState('');

  // Position
  const [position, setPosition] = useState({ x: 50, y: 0 });

  const jump = useCallback(() => {
    if (!isJumping && !gameOver) {
      setIsJumping(true);
      setPosition(prev => ({ ...prev, y: JUMP_HEIGHT }));

      setTimeout(() => {
        setIsJumping(false);
        setPosition(prev => ({ ...prev, y: 0 }));
      }, JUMP_DURATION);
    }
  }, [isJumping, gameOver]);

  // Handle keyboard input
  useEffect(() => {
    const handleKeyPress = (e) => {
      if ((e.code === 'Space' || e.key === 'ArrowUp') && !gameStarted) {
        setGameStarted(true);
        return;
      }
      if ((e.code === 'Space' || e.key === 'ArrowUp') && !gameOver) {
        jump();
      }
      if (e.code === 'Space' && gameOver) {
        restartGame();
      }
      // Add 'U' key for umbrella/lightning protection
      if (e.key.toLowerCase() === 'u') {
        setHasUmbrella(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key.toLowerCase() === 'u') {
        setHasUmbrella(false);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [jump, gameOver, gameStarted]);

  // Create a new obstacle or lightning
  const createObstacle = () => {
    // Force lightning for testing
    const isLightning = Math.random() < 0.8; // 80% chance for lightning

    if (isLightning) {
      setDebugLastSpawn('Lightning created at ' + Date.now());
      return {
        type: 'lightning',
        x: position.x + 15,
        warning: true,
        warningTime: Date.now(),
        strikeDelay: 600 // Reduced to 600ms for faster strikes
      };
    } else {
      setDebugLastSpawn('Rock created at ' + Date.now());
      return {
        type: 'rock',
        x: 800
      };
    }
  };

  // Game loop
  useEffect(() => {
    if (!gameStarted || gameOver) return;

    const gameLoop = setInterval(() => {
      setScore(prev => prev + 1);

      setObstacles(prev => {
        return prev
          .map(obstacle => {
            let updatedObstacle = {
              ...obstacle,
              x: obstacle.x - OBSTACLE_SPEED
            };

            // Handle lightning warning and strike
            if (obstacle.type === 'lightning' && obstacle.warning) {
              if (Date.now() - obstacle.warningTime > obstacle.strikeDelay) {
                updatedObstacle.warning = false;
                setIsScreenFlashing(true);
                setTimeout(() => setIsScreenFlashing(false), 300);
              }
            }

            return updatedObstacle;
          })
          .filter(obstacle => obstacle.x > -20);
      });

      // Collision detection
      obstacles.forEach(obstacle => {
        if (obstacle.type === 'rock') {
          const figureRect = {
            x: position.x + 5,
            y: position.y,
            width: 30,
            height: 70
          };

          const obstacleRect = {
            x: obstacle.x,
            y: 0,
            width: 20,
            height: 40
          };

          if (
            figureRect.x < obstacleRect.x + obstacleRect.width &&
            figureRect.x + figureRect.width > obstacleRect.x &&
            figureRect.y < obstacleRect.y + obstacleRect.height &&
            figureRect.y + figureRect.height > obstacleRect.y
          ) {
            setGameOver(true);
            if (score > highScore) {
              setHighScore(score);
            }
          }
        } else if (obstacle.type === 'lightning' && !obstacle.warning) {
          // Lightning strike collision
          if (Math.abs(obstacle.x - position.x) < 20) {
            if (hasUmbrella) {
              // Add dispersing effect when lightning hits umbrella
              setDispersingLightning(prev => new Set([...prev, obstacle.x]));
              setTimeout(() => {
                setDispersingLightning(prev => {
                  const newSet = new Set(prev);
                  newSet.delete(obstacle.x);
                  return newSet;
                });
              }, 300);
            } else {
              setGameOver(true);
              if (score > highScore) {
                setHighScore(score);
              }
            }
          }
        }
      });
    }, 16);

    return () => clearInterval(gameLoop);
  }, [gameStarted, gameOver, obstacles, position, score, highScore, hasUmbrella]);

  // Spawn obstacles
  useEffect(() => {
    if (!gameStarted || gameOver) return;

    const spawnObstacle = setInterval(() => {
      setObstacles(prev => [...prev, createObstacle()]);
    }, Math.max(BASE_SPAWN_RATE - Math.floor(score / 200) * 100, 800)); // Increased spawn rate

    return () => clearInterval(spawnObstacle);
  }, [gameStarted, gameOver]);

  const StickFigure = ({ frame }) => (
    <svg width="40" height="70" viewBox="0 0 40 70" fill="currentColor" className="text-gray-800">
      {/* Original stick figure code */}
      <circle cx="20" cy="10" r="7" />
      <path d="M20 3
              L20 12
              L13 12
              Q8 12 5 15
              Q2 18 0 20
              Q3 17 6 15
              Q10 13 15 12
              Q18 11 20 12
              M20 3
              Q15 5 12 8
              Q8 11 6 15"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1" />
      <path d="M20 17 L20 21"
            strokeWidth="1.5"
            fill="none"
            stroke="currentColor" />
      <path d="M20 21
              Q15 23 13 27
              L12 45
              Q20 48 28 45
              L27 27
              Q25 23 20 21"
            strokeWidth="1.5"
            fill="currentColor" />
      {frame === 0 ? (
        <>
          <path d="M16 45 Q15 55 14 65"
                strokeWidth="2"
                fill="none"
                stroke="currentColor" />
          <path d="M24 45 Q25 55 26 65"
                strokeWidth="2"
                fill="none"
                stroke="currentColor" />
        </>
      ) : (
        <>
          <path d="M16 45 Q20 55 20 65"
                strokeWidth="2"
                fill="none"
                stroke="currentColor" />
          <path d="M24 45 Q20 55 20 65"
                strokeWidth="2"
                fill="none"
                stroke="currentColor" />
        </>
      )}

      {/* Add umbrella when active */}
      {hasUmbrella && (
        <g transform="translate(0, -45)">
          {/* Umbrella handle - longer and more substantial */}
          <line
            x1="20" y1="65"
            x2="20" y2="15"
            stroke="currentColor"
            strokeWidth="1.5"
          />

          {/* Umbrella canopy - more rounded and substantial */}
          <path
            d="M5,15
               C5,15 8,5 20,5
               C32,5 35,15 35,15
               C35,15 28,8 20,8
               C12,8 5,15 5,15"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.5"
          />

          {/* Umbrella support ribs */}
          <line x1="20" y1="5" x2="5" y2="15" stroke="currentColor" strokeWidth="0.75"/>
          <line x1="20" y1="5" x2="35" y2="15" stroke="currentColor" strokeWidth="0.75"/>
          <line x1="20" y1="5" x2="28" y2="15" stroke="currentColor" strokeWidth="0.75"/>
          <line x1="20" y1="5" x2="12" y2="15" stroke="currentColor" strokeWidth="0.75"/>

          {/* Umbrella tip */}
          <circle cx="20" cy="5" r="1" fill="currentColor"/>

          {/* Bottom curve details */}
          <path
            d="M5,15 C12,13 28,13 35,15"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.5"
          />
        </g>
      )}
    </svg>
  );

  const Lightning = ({ warning, isDispersing, targetX }) => (
    <svg width="20" height="120" viewBox="0 0 20 120" className="absolute" style={{
      left: warning ? '0' : `${targetX}px`,
      zIndex: 10
    }}>
      {warning ? (
        // Warning flash - made larger and more visible
        <circle cx="10" cy="10" r="8" fill="#FF0">
          <animate attributeName="opacity" values="0;1;0" dur="0.5s" repeatCount="indefinite" />
        </circle>
      ) : (
        // Lightning bolt - made larger and more visible
        <g>
          <path
            d="M10,0 L14,40 L18,50 L6,120 L4,60 L0,50 Z"
            fill="#000"
            stroke="#000"
            strokeWidth="2"
          >
            <animate
              attributeName="y"
              from="-120"
              to="0"
              dur="0.2s"
              fill="freeze"
            />
          </path>
        </g>
      )}
    </svg>
  );

  const Obstacle = () => (
    <svg width="20" height="40" viewBox="0 0 20 40" fill="currentColor" className="text-gray-800">
      <path d="M2 40 L5 20 L10 15 L15 20 L18 40 Z" />
    </svg>
  );

  const restartGame = () => {
    setGameOver(false);
    setScore(0);
    setObstacles([]);
    setPosition({ x: 50, y: 0 });
    setGameStarted(true);
    setHasUmbrella(false);
    setIsScreenFlashing(false);
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <div className={`relative h-80 border-b-2 border-gray-600 bg-white overflow-hidden ${
        isScreenFlashing ? 'bg-yellow-100' : ''
      }`}>
        {/* Score */}
        <div className="absolute top-4 right-4 font-mono text-gray-600">
          {String(score).padStart(5, '0')}
        </div>

        {/* High Score */}
        <div className="absolute top-4 right-32 font-mono text-gray-400">
          HI {String(highScore).padStart(5, '0')}
        </div>

        {/* Controls info */}
        {!gameStarted && !gameOver && (
          <div className="absolute top-4 left-4 font-mono text-gray-600">
            SPACE to jump | Hold U for umbrella
          </div>
        )}

        {/* Ground */}
        <div className="absolute bottom-0 w-full">
          <div className="absolute bottom-0 w-full h-0.5 bg-gray-600" />
        </div>

        {/* Stick Figure */}
        <div
          className="absolute transition-transform duration-700"
          style={{
            left: `${position.x}px`,
            bottom: '0px',
            transform: `translateY(-${position.y}px)`
          }}
        >
          <StickFigure frame={!isJumping ? runFrame : 0} />
        </div>

        {/* Obstacles and Lightning */}
        {obstacles.map((obstacle, index) => (
          <div
            key={index}
            className="absolute"
            style={{
              left: `${obstacle.x}px`,
              bottom: obstacle.type === 'lightning' ? 'auto' : '0px',
              top: obstacle.type === 'lightning' ? '-120px' : 'auto',
              transition: obstacle.type === 'lightning' ? 'top 0.2s linear' : 'none',
              ...((!obstacle.warning && obstacle.type === 'lightning') ? {
                top: '0px'
              } : {})
            }}
          >
            {obstacle.type === 'lightning' ? (
              <Lightning
                warning={obstacle.warning}
                isDispersing={dispersingLightning.has(obstacle.x)}
                targetX={obstacle.x}
              />
            ) : (
              <Obstacle />
            )}
          </div>
        ))}

        {/* Game Over Screen */}
        {gameOver && (
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-white bg-opacity-80">
            <h2 className="text-2xl font-mono mb-4">GAME OVER</h2>
            <p className="text-sm font-mono">Press SPACE to restart</p>
          </div>
        )}

        {/* Start Screen */}
        {!gameStarted && !gameOver && (
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <p className="text-sm font-mono">Press SPACE to start</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default StickFigureGame;