import React, { useState, useEffect } from 'react';
import { X, Check, Clock, Award, List, ChevronLeft, Info, Home, ExternalLink, RefreshCw, ArrowRight } from 'lucide-react';

const WikiSentenceGame = () => {
  // Game states
  const [gameActive, setGameActive] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [score, setScore] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [wrongAnswers, setWrongAnswers] = useState(0);
  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  // Track used article IDs to prevent duplicates
  const [usedArticleIds, setUsedArticleIds] = useState([]);
  // Confirmation dialog state
  const [showConfirmQuit, setShowConfirmQuit] = useState(false);

  const categories = ["All", "Geography", "History", "Science", "Entertainment", "Sports", "Technology"];

  const onboardingSteps = [
    {
      title: "Welcome to Wiki Guess",
      content: "Read the first sentence of a Wikipedia article and try to guess its subject. How well do you know your trivia?"
    },
    {
      title: "Game Rules",
      content: "Select the correct answer from four options. You'll earn 10 points for each correct answer."
    },
    {
      title: "Learn as You Play",
      content: "After answering, you'll see the full sentence and can read the Wikipedia article to learn more."
    }
  ];

  // Wikipedia API functions
  const fetchRandomArticle = async (category = '') => {
    setIsLoading(true);
    setError(null);

    try {
      let apiUrl = "https://en.wikipedia.org/api/rest_v1/page/random/summary";

      // If a category is specified and it's not "all", use that
      if (category && category.toLowerCase() !== 'all') {
        // For demonstration, we're using a different approach since direct category-random isn't available
        // In a production app, you might use a more sophisticated approach
        apiUrl = `https://en.wikipedia.org/api/rest_v1/page/random/summary?${category}`;
      }

      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error(`Wikipedia API error: ${response.status}`);
      }

      const articleData = await response.json();

      // Check if we've already used this article
      if (usedArticleIds.includes(articleData.pageid)) {
        console.log("Article already used, fetching another...");
        return fetchRandomArticle(category);
      }

      return articleData;
    } catch (error) {
      console.error("Error fetching random article:", error);
      setError("Failed to fetch article. Please try again.");
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const extractFirstSentence = (content) => {
    // Basic sentence extraction - in production would need more robust handling
    const firstSentenceMatch = content.match(/^.*?[.!?](?:\s|$)/);
    return firstSentenceMatch ? firstSentenceMatch[0].trim() : content.split('.')[0] + '.';
  };

  const generateWrongAnswers = async (correctTitle) => {
    // For simplicity in this demo, we'll generate plausible wrong answers
    // In a production app, you'd fetch related articles from Wikipedia

    // Try to get 3 more random articles for wrong answers
    try {
      const wrongAnswerPromises = Array(3).fill().map(() => fetchRandomArticle());
      const wrongArticles = await Promise.all(wrongAnswerPromises);

      // Extract titles and ensure they're different from the correct answer
      let wrongTitles = wrongArticles.map(article => article.title)
        .filter(title => title !== correctTitle);

      // If we don't have enough wrong answers, add some fallbacks
      const fallbackTitles = [
        "Jupiter", "Albert Einstein", "Pacific Ocean",
        "Leonardo da Vinci", "Photosynthesis", "Mount Everest",
        "Marie Curie", "Tokyo", "Mozart", "Oxygen"
      ];

      while (wrongTitles.length < 3) {
        const fallbackTitle = fallbackTitles[Math.floor(Math.random() * fallbackTitles.length)];
        if (!wrongTitles.includes(fallbackTitle) && fallbackTitle !== correctTitle) {
          wrongTitles.push(fallbackTitle);
        }
      }

      return wrongTitles.slice(0, 3);
    } catch (error) {
      console.error("Error generating wrong answers:", error);

      // If API fails, use fallback wrong answers
      return [
        "Mount Everest", "Albert Einstein", "Pacific Ocean",
        "Leonardo da Vinci", "Tokyo", "Mozart"
      ].filter(title => title !== correctTitle).slice(0, 3);
    }
  };

  const prepareRandomQuestion = async () => {
    try {
      // 1. Get a random article
      const article = await fetchRandomArticle(selectedCategory);

      // 2. Extract the title and first sentence
      const title = article.title;
      const extract = article.extract || "";
      const firstSentence = extractFirstSentence(extract);
      const articleId = article.pageid;

      // Check if the title appears in the first sentence
      // This is crucial for the game to work properly
      if (!firstSentence.toLowerCase().includes(title.toLowerCase())) {
        // If title doesn't appear in first sentence, try again
        console.log("Title not in first sentence, trying again...");
        return prepareRandomQuestion();
      }

      // Add this article ID to used list
      setUsedArticleIds(prev => [...prev, articleId]);

      // 3. Get wrong answers
      const wrongAnswers = await generateWrongAnswers(title);

      // 4. Prepare all options and shuffle them
      const allOptions = [title, ...wrongAnswers];
      const shuffledOptions = shuffleArray(allOptions);

      // 5. Create the question object
      const questionObject = {
        originalSentence: firstSentence,
        sentence: firstSentence.replace(new RegExp(title, 'i'), "This"),
        answer: title,
        options: shuffledOptions,
        category: article.categories ? article.categories[0] : "General Knowledge",
        wikiUrl: article.content_urls?.desktop?.page || `https://en.wikipedia.org/wiki/${encodeURIComponent(title.replace(/ /g, '_'))}`,
        articleId: articleId
      };

      return questionObject;
    } catch (error) {
      console.error("Error preparing question:", error);
      setError("Failed to prepare question. Please try again.");
      throw error;
    }
  };

  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  // Start game
  const startGame = async () => {
    setGameActive(true);
    setScore(0);
    setCorrectAnswers(0);
    setWrongAnswers(0);
    setQuestionsAnswered(0);
    setGameOver(false);
    setUsedArticleIds([]);

    try {
      setIsLoading(true);
      const newQuestion = await prepareRandomQuestion();
      setCurrentQuestion(newQuestion);
    } catch (error) {
      console.error("Error starting game:", error);
      setError("Failed to start game. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Load next question
  const loadNextQuestion = async () => {
    try {
      setIsLoading(true);
      setShowResult(false);
      const newQuestion = await prepareRandomQuestion();
      setCurrentQuestion(newQuestion);
    } catch (error) {
      console.error("Error loading next question:", error);
      setError("Failed to load next question. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle answer submission
  const submitAnswer = (answer) => {
    const isAnswerCorrect = answer.toLowerCase() === currentQuestion.answer.toLowerCase();
    setIsCorrect(isAnswerCorrect);
    setShowResult(true);

    if (isAnswerCorrect) {
      setScore(prev => prev + 10);
      setCorrectAnswers(prev => prev + 1);
    } else {
      setScore(prev => Math.max(0, prev - 3)); // Don't go below 0
      setWrongAnswers(prev => prev + 1);
    }

    setQuestionsAnswered(prev => prev + 1);
  };

  // Show game statistics and end game
  const quitGame = () => {
    setGameOver(true);
    setShowConfirmQuit(false);
  };

  // Reset game
  const resetGame = () => {
  setGameActive(false);
  setGameOver(false);
  setScore(0);
  setCorrectAnswers(0);
  setWrongAnswers(0);
  setQuestionsAnswered(0);
  setCurrentQuestion(null); // Clear the current question
  setShowResult(false);
  setIsCorrect(false);
  setUsedArticleIds([]); // Clear used article IDs when resetting the game
  setError(null);
};

  // Go back function
  const goBack = () => {
    if (gameOver) {
      resetGame();
    } else if (gameActive) {
      // Show confirmation dialog before quitting
      setShowConfirmQuit(true);
    } else if (showOnboarding && onboardingStep > 0) {
      setOnboardingStep(prev => prev - 1);
    } else if (showOnboarding) {
      setShowOnboarding(false);
    }
  };

  // Advance onboarding
  const nextOnboardingStep = () => {
    if (onboardingStep < onboardingSteps.length - 1) {
      setOnboardingStep(prev => prev + 1);
    } else {
      setShowOnboarding(false);
    }
  };

  // Reload question (in case of error or if player wants a different one)
  const reloadQuestion = () => {
    setIsLoading(true);
    setError(null);
    loadNextQuestion().finally(() => setIsLoading(false));
  };

  // Calculate accuracy percentage
  const accuracyPercentage = questionsAnswered > 0
    ? Math.round((correctAnswers / questionsAnswered) * 100)
    : 0;

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4 text-gray-800 font-sans">
      {/* Header with back button */}
      <div className="w-full max-w-md flex items-center justify-between mb-6">
        {(gameActive || gameOver || (showOnboarding && onboardingStep > 0)) && (
          <button
            onClick={goBack}
            className="flex items-center text-gray-700 hover:text-gray-900"
          >
            <ChevronLeft className="w-5 h-5 mr-1" />
            <span className="text-sm">{gameActive ? "Quit" : "Back"}</span>
          </button>
        )}
        <h1 className="text-2xl font-medium text-gray-800 mx-auto">Wiki Guess</h1>
        {!showOnboarding && !gameActive && !gameOver && (
          <button
            onClick={() => setShowOnboarding(true)}
            className="flex items-center text-gray-700 hover:text-gray-900"
          >
            <Info className="w-5 h-5" />
          </button>
        )}
        {(gameActive || gameOver) && (
          <button
            onClick={resetGame}
            className="flex items-center text-gray-700 hover:text-gray-900"
          >
            <Home className="w-5 h-5" />
          </button>
        )}
      </div>

      {/* Confirmation Dialog */}
      {showConfirmQuit && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            <h3 className="text-lg font-medium mb-3">Quit Game?</h3>
            <p className="text-gray-600 mb-6">Are you sure you want to quit? Your progress will be shown in the statistics.</p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowConfirmQuit(false)}
                className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={quitGame}
                className="px-4 py-2 bg-gray-800 hover:bg-gray-700 text-white rounded-md"
              >
                Quit & See Stats
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Onboarding */}
      {showOnboarding && (
        <div className="w-full max-w-md">
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100 mb-4">
            <h2 className="text-xl font-medium mb-3 text-center">{onboardingSteps[onboardingStep].title}</h2>
            <p className="text-center text-gray-600 mb-8">{onboardingSteps[onboardingStep].content}</p>

            <div className="flex justify-between items-center">
              <div className="flex space-x-1">
                {onboardingSteps.map((_, index) => (
                  <div
                    key={index}
                    className={`w-2 h-2 rounded-full ${index === onboardingStep ? 'bg-gray-800' : 'bg-gray-300'}`}
                  />
                ))}
              </div>
              <button
                onClick={nextOnboardingStep}
                className="px-6 py-2 bg-gray-800 text-white rounded-md text-sm hover:bg-gray-700"
              >
                {onboardingStep < onboardingSteps.length - 1 ? 'Next' : 'Start Playing'}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Game mode selection */}
      {!gameActive && !gameOver && !showOnboarding && (
        <div className="w-full max-w-md md:max-w-lg">
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100 mb-4">
            <h2 className="text-xl font-medium mb-6 text-center">Wikipedia Guessing Game</h2>

            <div className="mb-6">
              <h3 className="text-lg font-medium mb-3">Select Category</h3>
              <div className="flex flex-wrap gap-2">
                {categories.map((category) => (
                  <button
                    key={category}
                    onClick={() => setSelectedCategory(category.toLowerCase())}
                    className={`px-3 py-2 rounded-md text-sm transition-colors duration-200 ${
                      selectedCategory.toLowerCase() === category.toLowerCase()
                        ? 'bg-gray-800 text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>

            <button
              onClick={startGame}
              className="w-full p-3 bg-gray-800 hover:bg-gray-700 text-white rounded-md font-medium transition-colors duration-200"
            >
              Start Game
            </button>
          </div>
        </div>
      )}

      {/* Game in progress */}
      {gameActive && currentQuestion && !gameOver && (
        <div className="w-full max-w-md md:max-w-lg">
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100 mb-4">
            <div className="flex justify-between items-center mb-4">
              <span className="font-medium">Score: {score}</span>
              <span className="font-medium">Questions: {questionsAnswered}</span>
            </div>

            {isLoading ? (
              <div className="flex flex-col items-center justify-center py-8">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900 mb-4"></div>
                <p className="text-gray-600">Loading question...</p>
              </div>
            ) : error ? (
              <div className="p-4 bg-red-50 border border-red-100 rounded-md mb-4">
                <p className="text-red-700 mb-2">{error}</p>
                <button
                  onClick={reloadQuestion}
                  className="flex items-center text-sm text-red-600 hover:text-red-800"
                >
                  <RefreshCw className="w-4 h-4 mr-1" />
                  Try another question
                </button>
              </div>
            ) : (
              <div>
                <div className="mb-6 p-5 bg-gray-50 rounded-md border border-gray-100">
                  <p className="text-lg">{currentQuestion.sentence}</p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-4">
                  {currentQuestion.options.map((option) => (
                    <button
                      key={option}
                      onClick={() => !showResult && submitAnswer(option)}
                      className={`p-3 rounded-md font-medium text-left ${
                        showResult
                          ? option.toLowerCase() === currentQuestion.answer.toLowerCase()
                            ? 'bg-green-50 border border-green-200'
                            : option.toLowerCase() === currentQuestion.answer.toLowerCase() === false
                              ? 'bg-red-50 border border-red-200'
                              : 'bg-gray-50 border border-gray-200'
                          : 'bg-gray-50 border border-gray-200 hover:border-gray-400 transition-colors duration-200'
                      }`}
                      disabled={showResult}
                    >
                      {option}
                    </button>
                  ))}
                </div>

                {!showResult && (
                  <button
                    onClick={reloadQuestion}
                    className="flex items-center text-sm text-gray-600 hover:text-gray-800 transition-colors duration-200 mt-2"
                  >
                    <RefreshCw className="w-4 h-4 mr-1" />
                    Skip question
                  </button>
                )}

                {showResult && (
                  <div>
                    <div className={`p-4 rounded-md mt-4 ${isCorrect ? 'bg-green-50 border border-green-100' : 'bg-red-50 border border-red-100'}`}>
                      <div className="flex items-center font-medium mb-2">
                        {isCorrect ? (
                          <div className="flex items-center">
                            <Check className="w-5 h-5 text-green-500 mr-2" />
                            <span>Correct!</span>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <X className="w-5 h-5 text-red-500 mr-2" />
                            <span>The correct answer is: {currentQuestion.answer}</span>
                          </div>
                        )}
                      </div>

                      <div className="mt-3 pt-3 border-t border-gray-200">
                        <p className="mb-2 text-sm text-gray-600">Original sentence:</p>
                        <p className="mb-3 text-gray-800">{currentQuestion.originalSentence}</p>
                        <a
                          href={currentQuestion.wikiUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800 transition-colors duration-200"
                        >
                          <ExternalLink className="w-4 h-4 mr-1" />
                          Read more on Wikipedia
                        </a>
                      </div>
                    </div>

                    <div className="mt-4 flex justify-between">
                      <button
                        onClick={() => setShowConfirmQuit(true)}
                        className="flex items-center justify-center px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-md transition-colors duration-200"
                      >
                        Quit & See Stats
                      </button>

                      <button
                        onClick={loadNextQuestion}
                        className="flex items-center justify-center px-4 py-2 bg-gray-800 hover:bg-gray-700 text-white rounded-md transition-colors duration-200"
                      >
                        Next Question
                        <ArrowRight className="w-4 h-4 ml-2" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Game over */}
      {gameOver && (
        <div className="w-full max-w-md md:max-w-lg">
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100 mb-4">
            <h2 className="text-2xl font-medium mb-6 text-center">Game Statistics</h2>

            <div className="p-5 bg-gray-50 rounded-md border border-gray-100 mb-6">
              <div className="flex justify-between items-center mb-5">
                <span className="font-medium text-lg">Final Score</span>
                <span className="text-2xl font-bold">{score}</span>
              </div>

              <div className="grid grid-cols-2 gap-4 text-center">
                <div className="p-4 rounded-md bg-white border border-gray-200">
                  <Check className="w-5 h-5 mx-auto mb-1 text-green-500" />
                  <div className="text-sm font-medium text-gray-600">Correct</div>
                  <div className="text-xl font-bold">{correctAnswers}</div>
                </div>

                <div className="p-4 rounded-md bg-white border border-gray-200">
                  <X className="w-5 h-5 mx-auto mb-1 text-red-500" />
                  <div className="text-sm font-medium text-gray-600">Wrong</div>
                  <div className="text-xl font-bold">{wrongAnswers}</div>
                </div>

                <div className="p-4 rounded-md bg-white border border-gray-200">
                  <List className="w-5 h-5 mx-auto mb-1 text-gray-500" />
                  <div className="text-sm font-medium text-gray-600">Questions</div>
                  <div className="text-xl font-bold">{questionsAnswered}</div>
                </div>

                <div className="p-4 rounded-md bg-white border border-gray-200">
                  <Award className="w-5 h-5 mx-auto mb-1 text-blue-500" />
                  <div className="text-sm font-medium text-gray-600">Accuracy</div>
                  <div className="text-xl font-bold">{accuracyPercentage}%</div>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <button
                onClick={resetGame}
                className="px-8 py-3 bg-gray-800 hover:bg-gray-700 text-white rounded-md font-medium transition-colors duration-200"
              >
                Back to Main Menu
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WikiSentenceGame;